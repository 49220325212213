import { FC, useMemo } from 'react';
import toast from 'react-hot-toast';
import { Button } from '@components/index';
import { Drawer, DrawerContent } from '@components/ui/drawer';
import { MeAPI } from '@entities/me/api';
import { BoostsAPI } from '@pages/MiningBoosts/entities/api';
import { useBackButtonTelegram } from '@shared/hooks/useBackButtonTelegram';
import { DoubleGreenUp, Gem } from '@shared/ui';
import BalanceDisplay from '@shared/ui/BalanceDisplay';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import 'react-spring-bottom-sheet/dist/style.css';

import { IBoost } from '../../entities/type';

import closeImg from '/images/icons/close.svg';

interface Properties {
  boosts?: IBoost[];
  itemId?: number;
  open?: boolean;
  onClose(): void;
}

export const BottomSheetCoin: FC<Properties> = ({ boosts, itemId, onClose }) => {
  const qc = useQueryClient();

  useBackButtonTelegram(onClose);

  const item = useMemo(
    () => boosts.find((boost) => boost.id === itemId),
    [boosts, itemId],
  );

  const profile = useQuery(MeAPI.getMeQueryOptions());

  const boost = useMutation({
    mutationFn: () => BoostsAPI.buyBoosts(itemId?.toString() || ''),
    onSuccess: () => {
      toast.success('Boosts successfully received!');
      [
        MeAPI.getMeQueryOptions().queryKey,
        BoostsAPI.getBoostsQueryOptions().queryKey,
      ].map((keys) =>
        qc.invalidateQueries({
          queryKey: keys,
        }),
      );
    },
    onError: () => {
      toast.error('Failed to buy boosts!');
      onClose();
    },
  });

  return (
    <Drawer open={true} onOpenChange={onClose}>
      <DrawerContent className="flex flex-col items-center bottom-sheet-white bg-container-dark rounded-t-[32px] pb-12 px-4">
        <button
          className="text-black bg-base-container w-[30px] h-[30px] rounded-full flex justify-center items-center
            absolute top-[23px] right-[23px] z-[51]"
          onClick={onClose}
        >
          <img src={closeImg} />
        </button>

        <div className="flex justify-center w-20 h-20">
          <img className="w-20 h-20" src={item?.url} alt="" />
        </div>

        <h3 className="text-center text-xl font-bold  mt-3">{item?.name}</h3>

        <p className="text-gray-light text-center text-base font-medium max-w-container m-auto mt-2">
          Buy {item?.name.replace(/\s*\(.*\)/, '')} into your crypto portfolio
          profitability
        </p>

        <p className="text-semibold text-white relative flex items-center gap-2 m-auto mt-4">
          <Gem></Gem>
          <div className="absolute bottom-1 left-3">
            <DoubleGreenUp />
          </div>
          <span className="text-success-strong font-semibold text-xl">
            +
            <BalanceDisplay
              balance={item?.boostNextLevelBonus - item?.boostBonus || 0}
              big
            />
          </span>
          <div className="bg-faint-weak rounded-full text-xs font-semibold px-2 py-1">
            LvL {item?.boostLevel}
          </div>
        </p>

        <div className="mt-3 text-sm text-gray-light">
          <p>
            <BalanceDisplay balance={item?.boostBonus || 0} big /> →{' '}
            <span className="text-white">
              <BalanceDisplay balance={item?.boostNextLevelBonus || 0} big />
            </span>
          </p>
        </div>

        {Number(item?.boostNextLevelPrice) > Number(profile?.data.balance) ? (
          <div className="w-full h-[48px] mt-4">
            <Button disabled>Insufficient Funds</Button>
          </div>
        ) : (
          <div className="w-full h-[48px] mt-4">
            <Button
              className="h-full"
              loading={boost.isPending}
              onClick={() => boost.mutate()}
            >
              <Gem />
              <BalanceDisplay balance={item?.boostNextLevelPrice || 0} big />
            </Button>
          </div>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export const BottomSheetItem: React.FC<Properties> = ({ boosts, itemId, onClose }) => {
  const qc = useQueryClient();

  useBackButtonTelegram(onClose);

  const item = useMemo(
    () => boosts.find((boost) => boost.id === itemId),
    [boosts, itemId],
  );

  const profile = useQuery(MeAPI.getMeQueryOptions());

  const boost = useMutation({
    mutationFn: () => BoostsAPI.buyBoosts(itemId?.toString() || ''),
    onSuccess: () => {
      toast.success('Boosts successfully received!');
      [
        BoostsAPI.getBoostsQueryOptions().queryKey,
        MeAPI.getMeQueryOptions().queryKey,
      ].map((keys) =>
        qc.invalidateQueries({
          queryKey: keys,
        }),
      );
    },
    onError: () => {
      toast.error('Failed to buy boosts!');
      onClose();
    },
  });

  if (!itemId || !item) {
    return null;
  }

  return (
    <Drawer open={true} onOpenChange={onClose}>
      <DrawerContent className="flex flex-col items-center bottom-sheet-white bg-container-dark rounded-t-[32px] pb-10 px-4">
        <button
          className="text-black bg-base-container w-[30px] h-[30px] rounded-full flex justify-center items-center absolute top-[23px] right-[23px]"
          onClick={onClose}
        >
          <img src={closeImg} alt="Close" />
        </button>

        <div
          className="flex justify-center w-[220px] h-[220px] rounded-[32px] bg-cover bg-center"
          style={{
            backgroundImage: `url(${item.url})`,
            border: '1.42px solid rgba(255, 255, 255, 0.14)',
            boxShadow: 'inset 0px 11.45px 11.91px 0px rgba(255, 255, 255, 0.27)',
          }}
        ></div>

        <h3 className="text-center text-xl font-bold  mt-3">{item.name}</h3>

        <p className="text-gray-light text-center text-base font-medium max-w-container m-auto mt-2">
          {item.description}
        </p>

        <p className="text-semibold text-white relative flex items-center gap-2 m-auto mt-4">
          <Gem></Gem>
          <div className="absolute bottom-1 left-3">
            <DoubleGreenUp />
          </div>
          <span className="text-success-strong font-semibold text-xl">
            +
            <BalanceDisplay
              balance={item?.boostNextLevelBonus - item?.boostBonus || 0}
              big
            />
          </span>
          <div className="bg-faint-weak rounded-full text-xs font-semibold px-2 py-1">
            LvL {item?.boostLevel}
          </div>
        </p>

        <div className="mt-3 text-sm text-gray-light">
          <p>
            <BalanceDisplay balance={item?.boostBonus || 0} big /> →{' '}
            <span className="text-white">
              <BalanceDisplay balance={item?.boostNextLevelBonus || 0} big />
            </span>
          </p>
        </div>

        <div className="w-full h-[48px] mt-4">
          {item.price > Number(profile.data.balance) ? (
            <div className="max-w-container h-[48px] mt-4">
              <Button disabled>Insufficient Funds</Button>
            </div>
          ) : (
            <div className="w-full h-[48px] mt-4">
              <Button
                className="h-full"
                loading={boost.isPending}
                onClick={() => boost.mutate()}
              >
                <Gem />
                <BalanceDisplay balance={item?.boostNextLevelPrice || 0} big />
              </Button>
            </div>
          )}
        </div>
      </DrawerContent>
    </Drawer>
  );
};
