import { RouteObject } from 'react-router/dist/lib/context';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { dexRouter } from '@pages/dex/dex.router';
import { FriendsRoute } from '@pages/FrensAndLeaders/router';
import {
  EarnPage,
  HomePage,
  ListingPage,
  MiningBoostsPage,
  ProfileRouter,
} from '@pages/index';
import { AppInitLayout } from '@widgets/AppInitLayout';
import { ErrorBoundary } from '@widgets/ErrorBoundary';
import { DexLayout } from '@widgets/Layout/DexLayout';
import { GameLayout } from '@widgets/Layout/GameLayout';

export const gameRouter: RouteObject[] = [
  {
    index: true,
    element: <HomePage />,
  },
  {
    path: 'profile/*',
    element: <ProfileRouter />,
  },
  {
    path: 'mining',
    element: <MiningBoostsPage />,
  },
  {
    path: 'listing',
    element: <ListingPage />,
  },
  {
    path: 'earn',
    element: <EarnPage />,
  },
  {
    path: 'frens/*',
    element: <FriendsRoute />,
  },
];

export const rootRouter = createBrowserRouter([
  {
    path: '/',
    element: <AppInitLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/*',
        element: <GameLayout />,
        children: gameRouter,
      },

      {
        path: 'dex/*',
        element: <DexLayout />,
        children: dexRouter,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={'/'} />,
  },
]);
