import { clientAPI } from '@shared/api/client.ts';
import { queryOptions } from '@tanstack/react-query';

import { Me } from '../type';

export const MeAPI = {
  getMeAPI: () => clientAPI('game/me').json<Me>(),

  getUserPortfolio: () =>
    clientAPI('v2/meme/getUserBalance').json<{ totalMemeBalance: string }>(),

  getMeQueryOptions: () =>
    queryOptions({
      queryKey: ['profile'],
      queryFn: () => MeAPI.getMeAPI(),
    }),

  getUserPortfolioQueryOptions: () =>
    queryOptions({
      queryKey: ['user_portfolio_balance'],
      queryFn: () => MeAPI.getUserPortfolio(),
    }),
} as const;
