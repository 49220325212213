import React from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { Button } from '@components/ui/button.tsx';
import { Drawer, DrawerFooter } from '@components/ui/drawer';
import { DrawerContent } from '@components/ui/drawer.tsx';
import { ConfigsAPI } from '@entities/config/api';
import { Ton } from '@shared/ui';
import { TonButton } from '@shared/ui/TonButton';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import WebApp from '@twa-dev/sdk';
import { MemeAPI } from '@widgets/Dex/entities/meme/api';
import { BuyForTonResponse } from '@widgets/Dex/entities/meme/type';

import unicornGif from '/images/gif/unicorn.gif';
import closeImg from '/images/icons/close.svg';
import starImg from '/images/icons/star.svg';

interface Properties {
  modalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  onPaymentSuccess?: (response: unknown) => void;
}

export const BuyModal: React.FC<React.PropsWithChildren<Properties>> = ({
  modalOpen,
  setModalOpen,
  onPaymentSuccess,
}) => {
  const { address } = useParams<{ address: string }>();
  const dexConfig = useQuery(ConfigsAPI.getDexConfigOptions());

  const [tonConnectUI] = useTonConnectUI();
  const tonAddress = useTonAddress();

  const payWebhook = useMutation({
    mutationFn: MemeAPI.postBuyForTon,
  });

  const fetchInvoice = async () => {
    try {
      const response = await MemeAPI.getCreateMemeInvoice();
      const response_ = { hash: '' };

      if (response) {
        WebApp.openInvoice(response.invoiceLink, (status) => {
          if (status === 'paid' && onPaymentSuccess) {
            onPaymentSuccess(response_);
          }
        });
      }
    } catch (error) {
      console.error('Error fetching invoice:', error);
    }
  };

  const payTon = useMutation({
    mutationFn: async () => {
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 60 * 2,
        messages: [
          {
            address: dexConfig.data.tonAddressRoot,
            amount: dexConfig.data.memeBoostPrice1hour.tonInNano,
            // amount: '10000000',
          },
        ],
      };

      const { boc } = await tonConnectUI.sendTransaction(transaction);

      try {
        const response = await payWebhook.mutateAsync({
          boc,
          address,
          sender: tonAddress,
          paymentType: 'createMeme',
        });

        const responseJson: BuyForTonResponse = await response.json();

        if (onPaymentSuccess) {
          onPaymentSuccess(responseJson);
        }
      } catch (error) {
        console.error('Erorr buyForTon - createMeme', error);
      }
    },
    onSettled: () => {
      setModalOpen(false);
    },
    onError: (error) => {
      if (error) {
        console.error('Mutation Error response:', error);
      }
    },
  });

  return (
    <Drawer open={modalOpen} onOpenChange={(state) => setModalOpen(state)} modal={false}>
      <DrawerContent className="bottom-sheet-white">
        <button
          className="text-black bg-base-container w-[30px] h-[30px] rounded-full flex justify-center items-center
            absolute top-[23px] right-[23px]"
          onClick={() => setModalOpen(false)}
        >
          <img src={closeImg} />
        </button>

        <img src={unicornGif} className="w-20 h-20" alt="" />

        <div className="text-[22px] font-semibold mt-5 mb-3">Almost Done!</div>
        <p className="text-sm text-gray-light font-medium text-center max-w-[340px] mb-7">
          Pay for the creation of your Meme token, choose one of the options below.
        </p>

        <DrawerFooter>
          <Button variant="faint-weak" onClick={fetchInvoice}>
            <img src={starImg} alt="Ton Img" />
            330 Stars
          </Button>

          <p className="text-base front-semibold text-gray my-2">or</p>

          {tonConnectUI.connected ? (
            <Button
              variant="blue-4"
              rounded="2xl"
              onClick={() => {
                toast.promise(payTon.mutateAsync(), {
                  loading: 'Awaiting blockchain...',
                  success: 'Successfully!',
                  error: 'Error during transaction',
                });
              }}
            >
              <Ton width={30} height={30} />1 TON
            </Button>
          ) : (
            <TonButton>Connect TON Wallet</TonButton>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
