import { clientAPI } from '@shared/api/client';
import { queryOptions } from '@tanstack/react-query';

import { Daily } from '../type';

export const DailyAPI = {
  getDailyList: () => clientAPI('game/daily/list').json<Daily[]>(),

  getDaily: () =>
    clientAPI('game/daily').json<{ reward: number; count: number } | boolean>(),

  getDailyListQueryOptions: () =>
    queryOptions({
      queryKey: ['daily_list'],
      queryFn: () => DailyAPI.getDailyList(),
    }),

  getDailyQueryOptions: () =>
    queryOptions({
      queryKey: ['daily'],
      queryFn: () => DailyAPI.getDaily(),
    }),

  makeClaim: () =>
    clientAPI('game/daily/claim').json<{ reward: number; count: number }>(),
} as const;
