import { FC } from 'react';
import { DollarWhiteIcon } from '@shared/icons/DollarWhite';

interface Properties {
  value: string;
}

export const CalculatedBadge: FC<Properties> = ({ value }) => {
  return (
    <div className="flex items-center justify-between px-3 py-4 border-1 border-faint-weak rounded-2xl">
      <p className="text-sm font-semibold text-gray-light">Calculated Token Price:</p>
      <p className="flex items-center text-sm font-semibold">
        <DollarWhiteIcon width={11} />
        {value}
      </p>
    </div>
  );
};
