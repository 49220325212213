import React from 'react';
import toast from 'react-hot-toast';

import { MemsSimulator } from './ui/MemsSimulator';
import { Banners, Mining, Panel, Skin } from './ui';

export const HomePage: React.FC = () => {
  return (
    <div className=" flex flex-col h-[calc(100vh-175px)]">
      {/* <Balance /> */}
      <Skin />
      <div className="relative max-w-container mx-4">
        <Panel />
        <MemsSimulator />
        <Mining />
      </div>
      <Banners callSoonNotification={() => toast('Soon...')} />
    </div>
  );
};
