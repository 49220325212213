import { FC, PropsWithChildren } from 'react';

export const GradientPatch: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      className="
            px-2
            h-[18px]
            text-xs font-semibold
            flex justify-center items-center rounded-xl
            text-white bg-gradient-to-r from-blue-2 to-purple-2"
    >
      {children}
    </div>
  );
};
