import { FC } from 'react';
import { Progress } from '@shared/ui';

interface Properties {
  level: number;
  progress: number;
  isBorder: boolean;
}

export const Avatar: FC<Properties> = ({ level, progress, isBorder }) => {
  return (
    <div
      className={`rounded overflow-hidden h-[52px] w-[52px]
    bg-cover bg-center bg-no-repeat p-0.5
    flex flex-col justify-end ${isBorder && 'border-1 border-solid border-success-strong '}`}
      style={{ backgroundImage: `url(/images/skins_avatar/${level}.png)` }}
    >
      <div className="text-xs font-semibold">{level} LvL</div>
      <Progress height={7} progress={progress} />
    </div>
  );
};
