import * as React from 'react';
import { cn } from '@shared/lib/utils';
import { Drawer as DrawerPrimitive } from 'vaul';

interface DrawerContentProperties
  extends React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content> {
  childClassName?: string;
}

const Drawer = ({
  shouldScaleBackground = true,
  ...properties
}: React.ComponentProps<typeof DrawerPrimitive.Root>) => (
  <DrawerPrimitive.Root shouldScaleBackground={shouldScaleBackground} {...properties} />
);
Drawer.displayName = 'Drawer';

const DrawerTrigger = DrawerPrimitive.Trigger;

const DrawerPortal = DrawerPrimitive.Portal;

const DrawerClose = DrawerPrimitive.Close;

const DrawerOverlay = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...properties }, reference) => (
  <DrawerPrimitive.Overlay
    ref={reference}
    className={cn('fixed inset-0 z-50 bg-black/80', className)}
    {...properties}
  />
));
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;

const DrawerContent = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Content>,
  DrawerContentProperties
>(({ className, childClassName, children, ...properties }, reference) => (
  <DrawerPortal>
    <DrawerOverlay />
    <DrawerPrimitive.Content
      ref={reference}
      className={cn(
        'fixed inset-x-0 bottom-0 z-50 mt-24 flex h-auto flex-col items-center rounded-t-[32px]  bg-base-container',
        className,
      )}
      {...properties}
    >
      <div
        className={cn(
          'mx-auto mt-4 h-1 w-[100px] rounded-full dark:bg-neutral-800',
          childClassName,
        )}
      />
      {children}
    </DrawerPrimitive.Content>
  </DrawerPortal>
));
DrawerContent.displayName = 'DrawerContent';

const DrawerHeader = ({
  className,
  ...properties
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn('grid gap-1.5 p-4 text-center sm:text-left', className)}
    {...properties}
  />
);
DrawerHeader.displayName = 'DrawerHeader';

const DrawerFooter = ({
  className,
  ...properties
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'mt-auto flex flex-col items-center w-[340px] gap-2 py-4 mb-5',
      className,
    )}
    {...properties}
  />
);
DrawerFooter.displayName = 'DrawerFooter';

const DrawerTitle = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...properties }, reference) => (
  <DrawerPrimitive.Title
    ref={reference}
    className={cn('text-lg font-semibold leading-none tracking-tight', className)}
    {...properties}
  />
));
DrawerTitle.displayName = DrawerPrimitive.Title.displayName;

const DrawerDescription = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...properties }, reference) => (
  <DrawerPrimitive.Description
    ref={reference}
    className={cn('text-sm text-neutral-500 dark:text-neutral-400', className)}
    {...properties}
  />
));
DrawerDescription.displayName = DrawerPrimitive.Description.displayName;

export {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerPortal,
  DrawerTitle,
  DrawerTrigger,
};
