import ky, { KyRequest } from 'ky';

import { $token, updateToken } from '../../store/auth.ts';

export const clientAPI = ky.extend({
  prefixUrl: import.meta.env.VITE_API_URL,
  hooks: {
    beforeRequest: [
      (request): KyRequest => {
        const token = $token.getState();
        if (token) {
          request.headers.set('Authorization', token);
        }
        return request;
      },
    ],
    afterResponse: [
      async (_request, _options, response): Promise<void> => {
        if (response.status === 401) {
          updateToken('');
        }
        if (response.status >= 400) {
          throw await response.json();
        }
        return response.json();
      },
    ],
  },
});
