import { ConfigsAPI } from '@entities/config/api';
import { removeLoader } from '@shared/lib/utils';
import { useInfiniteQuery, useQueries } from '@tanstack/react-query';
import { MemeAPI } from '@widgets/Dex/entities/meme/api';

export const useInitDex = (): boolean => {
  const { pending, isError, errors } = useQueries({
    queries: [
      MemeAPI.getEthBalanceQueryOptions(),
      MemeAPI.getUserTokensQueryOptions(),
      ConfigsAPI.getDexConfigOptions(),
    ],
    combine: (results) => {
      return {
        pending: results.some((result) => result.isPending),
        isError: results.some((result) => result.isError),
        errors: results.map((result) => result.error),
      };
    },
  });

  useInfiniteQuery(MemeAPI.getTokensInfiniteQueryOptions('false'));

  if (isError) {
    removeLoader();
    const errorMessages = errors.map((error) => error).join('; ');
    throw new Error(errorMessages);
  }

  return !pending;
};
