import { FC } from 'react';
import { MeAPI } from '@entities/me/api';
import { DollarBaseIcon } from '@shared/icons/DollarBase';
import { weiToEther } from '@shared/lib/number/format-number';
import BalanceDisplay from '@shared/ui/BalanceDisplay';
import { useQuery } from '@tanstack/react-query';

import settingsImg from '/images/icons/settings.svg';

export const TotalBalance: FC = () => {
  const balance = useQuery(MeAPI.getUserPortfolioQueryOptions());

  return (
    <div className="w-full flex max-w-container mx-4 justify-between mt-4 px-4 py-[14px] rounded-2xl bg-container-light border-1 border-faint-weak">
      <div className="flex flex-col items-start gap-y-2">
        <p className="text-2xs text-gray-light font-semibold">MemeCoin Portfolio</p>
        <p className="flex items-center gap-x-1 text-1-5xl text-white font-bold">
          <DollarBaseIcon width={20} height={27} />
          <BalanceDisplay
            balance={Number(weiToEther(balance.data?.totalMemeBalance))}
            disableFrame={false}
          />
        </p>
      </div>
      <div className="flex flex-col items-end gap-y-3 opacity-0">
        <div className="flex items-center gap-x-1">
          <p className="text-2xs text-gray-light font-bold">All Time P/L</p>
          <img src={settingsImg} alt="Settings" />
        </div>
        {/* <Percent value={}/> */}
      </div>
    </div>
  );
};
