import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@components/index';
import { ConfigsAPI } from '@entities/config/api';
import dayjsCustom from '@shared/config/dayjs';
import { DollarBaseIcon } from '@shared/icons/DollarBase';
import {
  formatNumber,
  formatWithSeparators,
  weiToEther,
} from '@shared/lib/number/format-number';
import BalanceDisplay from '@shared/ui/BalanceDisplay';
import { Percent } from '@shared/ui/Percent';
import { useQuery } from '@tanstack/react-query';
import WebApp from '@twa-dev/sdk';
import { MemeAPI } from '@widgets/Dex/entities/meme/api';
import { ArrowUpRight } from 'lucide-react';

import { getTimeAgo } from '../../../../../../utils/time';
import { MetricCard } from '../MetricCard';

import ecoImg from '/images/icons/eco.svg';
import shareImg from '/images/icons/share.svg';
import tgImg from '/images/icons/tg36.svg';
import zipperImg from '/images/icons/zipper.svg';

dayjsCustom.updateLocale('en', {
  relativeTime: {
    future: '%s',
    past: '%s ago',
    s: '%ds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dmo',
    y: '1y',
    yy: '%dy',
  },
});

export const Heading: FC = () => {
  const { address } = useParams<{ address: string }>();
  const token = useQuery(MemeAPI.getTokenByAddressQueryOptions(address));
  const appConfig = useQuery(ConfigsAPI.getAppConfigOptions());

  const tokenLink = `https://t.me/${appConfig.data?.botName}?startapp=${btoa(
    JSON.stringify({ a: address, t: 'd' }),
  )}`;

  const tokenTime = getTimeAgo(token.data?.createdAt);

  const shouldShowTime =
    tokenTime.includes('s') || tokenTime.includes('m') || tokenTime.includes('h');

  const shareLink = `https://t.me/share/url?url=${encodeURIComponent(tokenLink)}&text=${encodeURIComponent('\n Too meme to miss 🤯')}`;

  return (
    <div className="max-w-container mx-4">
      <div className="flex items-center justify-between">
        <div className="flex gap-x-3">
          <div className="w-[56px] h-[56px] rounded-xl relative">
            <img
              className="w-14 h-14 rounded-xl object-cover"
              src={token.data?.iconUrl}
              alt="Token Icon"
            />
            <div className="absolute w-full h-full inset-0 rounded-xl border-[1px] border-white/20"></div>
          </div>

          <div className="flex flex-col items-start">
            <div className="flex gap-x-1">
              <p
                className={`text-lg font-semibold ${token.data?.top ? 'bg-gradient-to-b from-[#FFFBE3]  to-[#D29A00] bg-clip-text text-transparent' : 'text-white'}`}
              >
                {token.data?.symbol}{' '}
              </p>
              {token.data?.top && <img src={zipperImg} alt="" />}

              {shouldShowTime && (
                <div className="flex items-center ">
                  <img src={ecoImg} alt="" />
                  <p className="text-xs font-semibold text-success-green">{tokenTime}</p>
                </div>
              )}
            </div>

            <div className="flex items-center gap-x-2">
              <p className="flex text-xl font-semibold items-center">
                <DollarBaseIcon width={17} height={18} />
                <BalanceDisplay
                  balance={Number(token.data?.exchangeRate)}
                  big
                  useCondensedFormat
                />
              </p>
              <Percent
                value={Number(token.data?.percents?.value || 0)}
                upDown={token.data?.upDown || 'UP'}
              />
            </div>
          </div>
        </div>
        <Button
          variant="blue-purple-gradient"
          className="w-max px-3 py-[10px] text-[13px]"
          onClick={() => WebApp.openTelegramLink(shareLink)}
        >
          Invite
          <img src={shareImg} alt="share" />
        </Button>
      </div>
      {token.data?.tgChannel && (
        <Button
          variant="base-container"
          className="w-full h-[48px] mt-2"
          onClick={() => WebApp.openTelegramLink(token.data?.tgChannel)}
        >
          <img
            src={tgImg}
            className="w-8 h-8 rounded-full shadow-[0px_-3px_14.6px_0px_rgba(212,0,255,1)]"
            alt="Telegram"
          />
          Join Channel <ArrowUpRight />
        </Button>
      )}

      <div className="grid grid-cols-3 gap-2 my-5">
        <MetricCard
          title="Market Cap"
          value={formatNumber(Number(weiToEther(token.data.marketCap)), true)}
          isGem
        />
        <MetricCard
          title="Virtual Liq."
          value={formatNumber(Number(weiToEther(token.data.liquidity.value)), true)}
          isGem
        />
        <MetricCard
          title="Volume 24H"
          value={formatNumber(Number(weiToEther(token.data.volume.value)), true)}
          isGem
        />
        <MetricCard
          title="Total Supply"
          value={formatNumber(Number(weiToEther(token.data.totalSupply.value)), true)}
        />
        <MetricCard
          title="Transactions"
          value={formatWithSeparators(token.data.totalTransaction, 0)}
        />
        <MetricCard title="Holders" value={token.data.holders} isUser />
      </div>
    </div>
  );
};
