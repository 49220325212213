import { FC } from 'react';

interface Properties {
  width?: number;
  height?: number;
}

const DEFAULT_SIZE = 50;

export const DollarIcon: FC<Properties> = ({
  width = DEFAULT_SIZE,
  height = DEFAULT_SIZE,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13537_2085)">
        <rect width="24" height="24" transform="translate(0.214844 0.5)" fill="#1D59F6" />
        <path
          d="M12.0966 17.567C11.4353 17.567 10.8241 17.4868 10.263 17.3265C9.70195 17.1662 9.20098 16.9458 8.76012 16.6653C8.32929 16.3847 7.96859 16.0591 7.67803 15.6884L8.82024 14.3357C9.04067 14.6764 9.32622 14.972 9.6769 15.2225C10.0276 15.4629 10.4233 15.6533 10.8642 15.7936C11.305 15.9238 11.7559 15.9889 12.2168 15.9889C12.828 15.9889 13.3089 15.8888 13.6596 15.6884C14.0103 15.488 14.1856 15.1924 14.1856 14.8017C14.1856 14.5512 14.0854 14.3357 13.885 14.1554C13.6947 13.9751 13.359 13.8298 12.8781 13.7196L10.774 13.2386C10.2129 13.1084 9.73201 12.928 9.33123 12.6976C8.93045 12.4671 8.62486 12.1866 8.41446 11.856C8.21407 11.5153 8.11387 11.1245 8.11387 10.6837C8.11387 10.1026 8.26917 9.58656 8.57978 9.13569C8.9004 8.68481 9.35127 8.32412 9.93239 8.05359C10.5235 7.78307 11.2349 7.64781 12.0665 7.64781C12.9382 7.64781 13.7047 7.7981 14.366 8.09868C15.0273 8.39926 15.5883 8.84512 16.0492 9.43627L14.9671 10.6837C14.5664 10.1727 14.1205 9.80198 13.6295 9.57153C13.1486 9.33106 12.5825 9.21083 11.9313 9.21083C11.4904 9.21083 11.1297 9.26594 10.8492 9.37615C10.5686 9.47635 10.3582 9.61662 10.2179 9.79697C10.0877 9.97732 10.0226 10.1827 10.0226 10.4132C10.0226 10.5935 10.0626 10.7588 10.1428 10.9091C10.223 11.0494 10.3732 11.1746 10.5937 11.2848C10.8241 11.3951 11.1497 11.5003 11.5706 11.6005L13.3741 12.0062C14.3159 12.2166 14.9922 12.5272 15.403 12.938C15.8238 13.3388 16.0342 13.8749 16.0342 14.5462C16.0342 15.1874 15.8689 15.7335 15.5382 16.1843C15.2076 16.6352 14.7417 16.9809 14.1405 17.2213C13.5494 17.4518 12.8681 17.567 12.0966 17.567ZM11.3902 18.9647V6.28016H12.7879V18.9647H11.3902Z"
          fill="white"
        />
        <path
          d="M17.7174 7.60598L18.2787 8.14703L18.8062 8.85715L18.2584 9.25617L17.7444 8.53252L17.4063 7.83592L17.5618 7.72095L17.7174 7.60598ZM17.4063 7.60598L17.5618 7.72095L17.7174 7.83592L17.3792 8.53252L16.8652 9.25617L16.3174 8.85715L16.8517 8.14703L17.4063 7.60598ZM16.0942 6.88909L16.9396 7.17314L17.6227 7.53159L17.5618 7.72095L17.5009 7.91032L16.7367 7.79535L15.8913 7.53159L16.0942 6.88909ZM17.9 6.07076L17.8864 6.95672L17.7579 7.72095H17.5618H17.3657L17.2372 6.95672L17.2169 6.07076H17.9ZM19.0294 6.88909L19.2323 7.53159L18.3869 7.79535L17.6227 7.91032L17.5618 7.72095L17.5009 7.53159L18.184 7.17314L19.0294 6.88909Z"
          fill="white"
        />
      </g>
      <rect
        x="0.629688"
        y="0.7"
        width="23.1714"
        height="23.6"
        rx="11.5857"
        stroke="white"
        strokeOpacity="0.15"
        strokeWidth="0.4"
      />
      <defs>
        <clipPath id="clip0_13537_2085">
          <rect
            x="0.429688"
            y="0.5"
            width="23.5714"
            height="24"
            rx="11.7857"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
