import { FC } from 'react';
import { Button } from '@components/index';
import { Buy } from '@shared/ui';

import discoGif from '/images/gif/disco.gif';

export const Accepted: FC = () => {
  return (
    <div
      className="mx-4 max-w-container
    px-4 py-5 border-1 border-solid border-faint-weak rounded-3xl
    bg-[url(./images/bg/whitelist-accepted-bg.png)] bg-cover bg-center"
    >
      <img className="d-block m-auto w-16 h-16" src={discoGif} />
      <p className="text-center text-xl font-semibold mt-4">Whitelist Accepted</p>
      <p className="max-w-[293px] m-auto text-center text-2xs font-semibold text-gray-light mt-4 ">
        Now You can Buy $RAX Token Allocation by this link below.
      </p>

      <div className="h-[56px] mt-4">
        <Button variant="purple">
          <Buy /> Buy $RAX Allocation
        </Button>
      </div>
    </div>
  );
};
