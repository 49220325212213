import { FC, useEffect, useState } from 'react';
import { MeAPI } from '@entities/me/api';
import { Progress } from '@shared/ui';
import BalanceDisplay from '@shared/ui/BalanceDisplay';
import { useQuery } from '@tanstack/react-query';

export const LevelProgress: FC = () => {
  const profile = useQuery(MeAPI.getMeQueryOptions());

  const [exFrom, setExFrom] = useState(0);
  const [exNext, setExNext] = useState<number | string>(0);
  const [level, setLevel] = useState(0);
  const [levelName, setLevelName] = useState('');
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    if (profile && profile.data.level) {
      const currentLevel = profile.data.level.currentUserLevel;
      const nextLevel = profile.data.level.nextUserLevel;

      setExFrom(currentLevel.from);
      setExNext(nextLevel?.from || '∞');
      setLevel(profile.data.level.currentUserLevelIndex + 1);
      setLevelName(currentLevel.name);

      const nextLevelDifference = nextLevel ? nextLevel.from - currentLevel.from : 0;

      const remainingDifference = nextLevel
        ? nextLevel.from - Number(profile.data.experience)
        : 0;

      const calculatedPercent =
        profile.data.level.currentUserLevel.id < 20
          ? nextLevelDifference
            ? (1 - remainingDifference / nextLevelDifference) * 100
            : 0
          : 100;
      setPercent(calculatedPercent);
    }
  }, [profile]);

  return (
    <div className="px-4">
      <div className="flex justify-center mb-2">
        <p className="text-gray-dark font-semibold">
          {level} lvl <span className="text-white">{levelName}</span>{' '}
        </p>
      </div>
      <div className="flex items-center justify-between mb-2">
        <p className="text-xs text-gray-light font-semibold">
          <BalanceDisplay balance={exFrom} big />
        </p>

        <p className="text-xs text-white font-semibold">
          {level}lvl{' '}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-2 to-purple-2 font-bold">
            {percent.toFixed(1)}%
          </span>
        </p>

        <p className="text-xs text-gray-light font-semibold  text-end">
          {exNext === '∞' ? '∞' : <BalanceDisplay balance={Number(exNext)} big />}
        </p>
      </div>
      <Progress progress={percent} height={11} />
    </div>
  );
};
