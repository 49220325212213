import React from 'react';

import tokenLiqImg from '/images/icons/fire-nav.svg';
import memeGray from '/images/icons/memesGray.svg';

interface Properties {
  isActive?: boolean;
}

export const NewbiesIcon: React.FC<Properties> = ({ isActive }) => {
  return (
    <div>
      {isActive ? <img src={tokenLiqImg} alt="" /> : <img src={memeGray} alt="" />}
    </div>
  );
};
