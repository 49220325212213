import { FC } from 'react';
import { formatNumber } from '@shared/lib/number/format-number';
import BalanceDisplay from '@shared/ui/BalanceDisplay';

interface Properties {
  name: string;
  symbol: string;
  iconUrl: string;
  percent: number;
  exchangeRate: string;
  upDown: string;
  onClick: () => void;
}

const calcClass = (value: number, upDown: string) => {
  if (value === 0) {
    return 'text-gray-light';
  }
  return upDown === 'UP' ? 'text-success-strong' : 'text-critic-strong-up';
};

const upOrDown = (value: number, upDown: string) => {
  if (value === 0) return `${formatNumber(value, true)}`;

  return upDown === 'UP'
    ? `+${formatNumber(value, true)}`
    : `-${formatNumber(value, true)}`;
};

export const MemeItem: FC<Properties> = ({
  name,
  symbol,
  iconUrl,
  percent,
  exchangeRate,
  upDown,
  onClick,
}) => {
  return (
    <div className="flex rounded-2-5xl gap-2 px-2 mb-4 items-center" onClick={onClick}>
      <div className="w-[42px] h-[42px]">
        <img className="w-full h-full rounded-full object-cover" src={iconUrl} />
      </div>
      <div className="grow ml-3">
        <p className="text-[15px] font-semibold">{symbol}</p>
        <p className="text-2xs font-semibold text-gray-light">{name}</p>
      </div>
      <div className="flex justify-between items-end flex-col ">
        <div className="flex items-center gap-x-1">
          <div>
            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.69142 11.2208C4.05215 11.2208 3.46131 11.1433 2.91889 10.9883C2.37648 10.8333 1.89218 10.6202 1.466 10.349C1.04951 10.0778 0.700814 9.76304 0.419922 9.40466L1.52412 8.09706C1.73721 8.42638 2.01326 8.71212 2.35227 8.95427C2.69127 9.18673 3.07387 9.37076 3.50005 9.50636C3.92623 9.63228 4.3621 9.69524 4.80765 9.69524C5.39849 9.69524 5.86342 9.59838 6.20242 9.40466C6.54143 9.21094 6.71094 8.92521 6.71094 8.54746C6.71094 8.30531 6.61408 8.09706 6.42036 7.92271C6.23633 7.74837 5.91185 7.60792 5.44692 7.50138L3.41288 7.03645C2.87046 6.91053 2.40554 6.73619 2.0181 6.51341C1.63066 6.29063 1.33524 6.01943 1.13184 5.69979C0.938119 5.37047 0.84126 4.99272 0.84126 4.56654C0.84126 4.00475 0.991392 3.50593 1.29166 3.07006C1.60161 2.63419 2.03747 2.2855 2.59926 2.02398C3.17073 1.76246 3.85843 1.6317 4.66236 1.6317C5.50504 1.6317 6.24601 1.77699 6.88528 2.06757C7.52455 2.35815 8.06697 2.78917 8.51252 3.36064L7.46644 4.56654C7.079 4.07256 6.64798 3.71418 6.17337 3.4914C5.70844 3.25894 5.16119 3.14271 4.5316 3.14271C4.10542 3.14271 3.75673 3.19598 3.48552 3.30252C3.21431 3.39938 3.01091 3.53499 2.87531 3.70933C2.74939 3.88368 2.68643 4.08224 2.68643 4.30502C2.68643 4.47936 2.72517 4.63918 2.80266 4.78447C2.88015 4.92007 3.02544 5.04115 3.23853 5.14769C3.46131 5.25424 3.7761 5.35594 4.18291 5.4528L5.92638 5.84508C6.83685 6.04849 7.49065 6.34875 7.88778 6.74587C8.29459 7.13331 8.49799 7.65151 8.49799 8.30047C8.49799 8.92037 8.33817 9.44825 8.01854 9.88412C7.6989 10.32 7.24851 10.6541 6.66735 10.8866C6.09588 11.1094 5.43724 11.2208 4.69142 11.2208ZM4.00856 12.572V0.30957H5.35975V12.572H4.00856Z"
                fill="#AEB0B7"
              />
              <path
                d="M10.1231 2.12407L10.6658 2.64711L11.1757 3.3336L10.6462 3.71934L10.1493 3.01977L9.82238 2.34636L9.97275 2.23521L10.1231 2.12407ZM9.82238 2.12407L9.97275 2.23521L10.1231 2.34636L9.79623 3.01977L9.29934 3.71934L8.76976 3.3336L9.28626 2.64711L9.82238 2.12407ZM8.55401 1.43104L9.37126 1.70563L10.0316 2.05215L9.97275 2.23521L9.91391 2.41828L9.17512 2.30713L8.35787 2.05215L8.55401 1.43104ZM10.2997 0.63994L10.2866 1.49642L10.1624 2.23521H9.97275H9.78315L9.65893 1.49642L9.63932 0.63994H10.2997ZM11.3915 1.43104L11.5876 2.05215L10.7704 2.30713L10.0316 2.41828L9.97275 2.23521L9.91391 2.05215L10.5743 1.70563L11.3915 1.43104Z"
                fill="#AEB0B7"
              />
            </svg>
          </div>
          <p className="text-[15px] font-semibold">
            <BalanceDisplay balance={Number(exchangeRate)} big />
          </p>
        </div>
        <p className={`${calcClass(Number(percent), upDown)} text-[15px] font-semibold `}>
          {upOrDown(Number(percent), upDown)}%
        </p>
      </div>
    </div>
  );
};
