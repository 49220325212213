import { FC } from 'react';
import { MeAPI } from '@entities/me/api';
import { useQuery } from '@tanstack/react-query';

export const Skin: FC = () => {
  const profile = useQuery(MeAPI.getMeQueryOptions());

  return (
    <div
      className="flex-grow bg-contain bg-no-repeat bg-center"
      style={{
        backgroundImage: `url(${profile.data?.skinImage || ''})`,
      }}
    />
  );
};
