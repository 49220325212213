/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { useInit } from '@app/use-init';
import { useInitDex } from '@app/use-init/indexDex';
import { AuthAPI } from '@entities/auth/api';
import { DailyTasksPage } from '@pages/index';
import { safeParse } from '@shared/lib/utils';
import { useMutation } from '@tanstack/react-query';
import WebApp from '@twa-dev/sdk';
import { LevelUp } from '@widgets/LevelUp';
import { useUnit } from 'effector-react';

import { updateToken } from '../../store/auth';

WebApp.setHeaderColor('#090A0E');
WebApp.setBackgroundColor('#090A0E');
WebApp.expand();

export const AppInitLayout = (): JSX.Element => {
  // const isDesktop = WebApp.platform === "tdesktop";
  const navigate = useNavigate();
  const [searchParameters, setSearchParameters] = useSearchParams();
  const isDailyOpen = searchParameters.get('daily') === 'true';

  const closeDaily = () => {
    searchParameters.delete('daily');
    setSearchParameters(searchParameters);
  };

  const setToken = useUnit(updateToken);
  const isInit = useInit();
  const isInitDex = useInitDex();

  const login = useMutation({
    mutationFn: AuthAPI.postLogin,
    onSuccess(data) {
      setToken(data.accessToken);
    },
    onError() {
      console.error('error auth');
    },
  });

  useEffect(() => {
    login.mutate();
  }, []);

  useEffect(() => {
    if (isInit && isInitDex) {
      document.querySelector('#loader')?.classList?.toggle('hidden');
    }
  }, [isInit, isInitDex]);

  useEffect(() => {
    const startParameter = WebApp?.initDataUnsafe.start_param;
    if (startParameter) {
      const { t, a } = safeParse<{ t?: string; a?: string }>(startParameter);

      if (t === 'd') {
        navigate(`/dex/pay/${a}`);
      }
    }
  }, []);

  if (!isInit || !isInitDex) {
    return <div>loading</div>;
  }

  return (
    <>
      <Outlet />
      {isDailyOpen && <DailyTasksPage onClose={closeDaily} />}
      <LevelUp />

      <Toaster
        toastOptions={{
          style: {
            borderRadius: '999px',
            background: '#313238',
            color: '#fff',
          },
        }}
      />
    </>
  );
};
