export const groupBy = <I, K>(
  array: I[],
  grouped: (item: I, index: number, array: I[]) => K,
): Map<K, I[]> => {
  const output = new Map<K, I[]>();

  for (const [index, item] of array.entries()) {
    const group = grouped(item, index, array);
    const outputArray = output.get(group) ?? [];
    outputArray.push(item);
    output.set(group, outputArray);
  }

  return output;
};
