import { FC, PropsWithChildren } from 'react';

import { Gem } from '../Gem';

interface Properties {
  color: 'blue';
}

export const Bonus: FC<PropsWithChildren<Properties>> = ({ children, color }) => {
  const bgHash = {
    blue: 'bg-blue',
  };

  return (
    <div
      className={`h-[36px] rounded-xl flex items-center justify-center gap-1
      px-3 font-bold ${bgHash[color]}`}
    >
      {children} <Gem />
    </div>
  );
};
