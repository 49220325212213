import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button } from '@components/index';
import { Dialog, DialogClose, DialogContent } from '@components/ui/dialog';
import { ListingAPI } from '@pages/Listing/entities/api';
import { Gem } from '@shared/ui';
import { useMutation } from '@tanstack/react-query';

import { Step1Coins, Step2Sums } from '../../constants';
import { WalletInput } from '../WalletInput';

interface Properties {
  setSubmitted(): void;
}

export const Form: FC<Properties> = ({ setSubmitted }) => {
  const listing = useMutation({
    mutationKey: ['buyWhitelist'],
    mutationFn: ListingAPI.buyWhitelist,
    onSuccess: () => {
      toast.success('Whitelist successfully bought!');
      setSubmitted();
    },
  });

  const [focus, setFocus] = useState<boolean>(false);
  const [selectedCoinIndex, setSelectedCoinIndex] = useState(0);
  const [selectedSumOfInvestmentIndex, setSelectedSumOfInvestmentIndex] = useState(2);
  const [isWrongWalletAddress, setIsWrongWalletAddress] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [walletAddress, setWalletAddress] = useState('');

  useEffect(() => {
    if (
      selectedCoinIndex === -1 ||
      selectedSumOfInvestmentIndex === -1 ||
      walletAddress.length === 0
    )
      return setIsButtonDisabled(true);
    return setIsButtonDisabled(false);
  }, [selectedCoinIndex, selectedSumOfInvestmentIndex, walletAddress]);

  const submitHandler = (): void => {
    const tonRegex = /^(UQ|EQ)[\w-]{10,}$/;
    const ethRegex = /^(0x)[\dA-Fa-f]{40}$/;
    const bnbRegex = /^(bnb1)[\da-z]{38}$/;
    const solRegex = /^[\dA-Za-z]{10,}$/;

    const isTon = tonRegex.test(walletAddress);
    const isEth = ethRegex.test(walletAddress);
    const isBnb = bnbRegex.test(walletAddress);
    const isSol = solRegex.test(walletAddress);

    if (isTon || isEth || isBnb || isSol) {
      setIsWrongWalletAddress(false);
      setSubmitted();
      listing.mutate();
      setFocus(false);
    } else {
      setIsWrongWalletAddress(true);
    }
  };

  return (
    <div className="mx-4 max-w-container pl-4 py-5 overflow-hidden border-1 border-solid border-faint-weak rounded-3xl">
      <p className="font-semibold text-white text-center pr-4">
        Step 1. <span className="text-gray-light">Select Pre-Sale Network</span>
      </p>

      <div className="flex items-center gap-2 mt-4 pr-4">
        {Step1Coins.map((coin, index) => (
          <Button
            key={coin.id}
            variant={selectedCoinIndex === index ? 'accent-strong' : 'faint-weak'}
            onClick={() => setSelectedCoinIndex(index)}
            className="px-2 py-[10px] gap-x-1"
          >
            {coin.icon}

            {coin.title}
          </Button>
        ))}
      </div>

      <p className="font-semibold text-white font-semibold text-center mt-4 pr-4">
        Step 2. <span className="text-gray-light">Select Sum of Investment</span>
      </p>

      <div className="w-full overflow-x-scroll  scrollbar-none flex-shrink-0 mt-4">
        <div className="flex items-center gap-2">
          {Step2Sums.map((summ, index) => (
            <div key={index} className="h-full" style={{ flex: '1 0 calc(18% - 8px)' }}>
              <Button
                variant={
                  selectedSumOfInvestmentIndex === index ? 'accent-strong' : 'faint-weak'
                }
                onClick={() => setSelectedSumOfInvestmentIndex(index)}
              >
                ${summ}
              </Button>
            </div>
          ))}
        </div>
      </div>

      <p
        className={`
        ${isWrongWalletAddress ? 'text-critic-strong' : ' text-white '}
        font-semibold font-semibold text-center mt-4 mb-4 pr-4`}
      >
        Step 3.{' '}
        <span className={isWrongWalletAddress ? 'text-critic-strong' : ' text-white '}>
          Enter Your Wallet Address
        </span>
      </p>

      <div className="pr-4">
        <WalletInput
          type={isWrongWalletAddress ? 'error' : 'default'}
          value={walletAddress}
          setValue={setWalletAddress}
          onFocus={() => setFocus(true)}
        />
        {isWrongWalletAddress && (
          <p className="text-critic-strong text-center mt-2">Invalid Address</p>
        )}
        <div className="h-[56px] mt-8">
          <Button disabled={isButtonDisabled} onClick={submitHandler}>
            Buy for 100,000 <Gem />
          </Button>
        </div>
      </div>

      <Dialog
        modal={false}
        open={focus}
        onOpenChange={(state) => !state && setFocus(null)}
      >
        <DialogContent className="inset-0 left-0 top-0 translate-x-0 translate-y-0 p-0 m-0 pt-12">
          <div className="mx-4">
            <WalletInput
              type={isWrongWalletAddress ? 'error' : 'default'}
              value={walletAddress}
              setValue={setWalletAddress}
            />
            {isWrongWalletAddress && (
              <p className="text-critic-strong text-center mt-2">Invalid Address</p>
            )}
            <DialogClose asChild>
              <Button className="mt-8">Next</Button>
            </DialogClose>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
