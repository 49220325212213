import { FC } from 'react';

interface Properties {
  currentButtonIndex: number;
  buttons: string[];
  setCurrentButtonIndex(index: number): void;
}

export const ToggleButtons: FC<Properties> = ({
  currentButtonIndex,
  buttons,
  setCurrentButtonIndex,
}) => {
  return (
    <div className="max-w-[66vh] overflow-x-auto sticky top-[56px] py-1 z-[1] bg-base-background">
      <div className="flex items-center justify-start gap-x-3">
        {buttons.map((button, index) => (
          <button
            key={index}
            className={`px-6 py-3 text-base font-semibold rounded-2xl relative w-[250px] ${
              currentButtonIndex === index
                ? 'text-white bg-gradient-to-r from-blue-2 to-purple-2'
                : 'text-gray-light bg-base-background'
            }`}
            onClick={() => setCurrentButtonIndex(index)}
          >
            {button}
          </button>
        ))}
      </div>
    </div>
  );
};
