import { FC } from 'react';
import { Gem } from '@shared/ui';
import BalanceDisplay from '@shared/ui/BalanceDisplay';

import lockImg from '/images/icons/lock.png';

interface Properties {
  day: number;
  isCurrent: boolean;
  reward: number;
  isOpen: boolean;
}

export const Day: FC<Properties> = ({ day, isCurrent, reward, isOpen }) => {
  return (
    <div
      className={
        isCurrent
          ? 'bg-gradient-to-r from-complement-strong to-purple-2 p-0.2 rounded-2xl'
          : 'p-0.2 rounded-2xl'
      }
    >
      <div
        className={`
          bg-base-container
          rounded-2xl flex flex-col justify-center py-2`}
      >
        <p className="text-center text-2xs">Day {day}</p>

        <div className="flex justify-center gap-1 mt-2">
          {isOpen ? (
            <Gem size={24} />
          ) : (
            <img src={lockImg} className="mix-blend-luminosity" />
          )}
        </div>

        <div className="text-center text-2xs mt-2">
          <BalanceDisplay balance={reward} big />
        </div>
      </div>
    </div>
  );
};
