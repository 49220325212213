import { FC } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';

import style from './style.module.scss';

import electroImg from '/images/gif/electro.gif';
import calendarImg from '/images/icons/daily_point.png';

export const Panel: FC = () => {
  const [, setSearchParameters] = useSearchParams();

  const toDailyTasks = () => {
    setSearchParameters({ daily: 'true' });
  };
  return (
    <div className="flex justify-between  mb-2 absolute bottom-full w-full">
      <NavLink to="/profile/vip">
        <button className={style.vip}>
          <div
            style={{
              content: '""',
              position: 'absolute',
              inset: 0,
              zIndex: 0,
              borderRadius: '9999px',
              background:
                'linear-gradient(2.84deg, rgba(255, 255, 255, 0) 1.52%, rgba(255, 255, 255, 0.406) 109.84%)',
              padding: '1px',
              WebkitMask:
                'linear-gradient(white, white) content-box, linear-gradient(white, white)',
              WebkitMaskComposite: 'destination-out',
              maskComposite: 'exclude',
            }}
          />
          <Diamond /> VIP Skin
        </button>
      </NavLink>

      <div className="flex gap-3">
        <NavLink to="/listing" className={style.button}>
          <img src={electroImg} />
          Listing
        </NavLink>
        <NavLink to="?daily=true" data-calendar={true} onClick={toDailyTasks}>
          <img className="w-11 h-11" src={calendarImg} />
        </NavLink>
      </div>
    </div>
  );
};

const Diamond: FC = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.49944 9.35938H18.5586L9.49944 18.6686V9.35938Z"
      fill="url(#paint0_linear_11273_606)"
    />
    <path
      d="M9.5 9.36005V0.0507812L0.440845 9.36005H9.5Z"
      fill="url(#paint1_linear_11273_606)"
    />
    <path
      d="M9.5 9.35869V18.668L0.440845 9.35869H9.5Z"
      fill="url(#paint2_linear_11273_606)"
    />
    <path
      d="M9.49944 9.35938H18.5586L9.49944 0.0501027V9.35938Z"
      fill="url(#paint3_linear_11273_606)"
    />
    <rect
      width="6.49482"
      height="6.49482"
      transform="matrix(-0.697414 -0.716669 -0.697414 0.716669 14.0293 9.35938)"
      fill="url(#paint4_linear_11273_606)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1621 9.35938L9.49858 4.56709L4.83505 9.35938L9.49858 14.1517L14.1621 9.35938ZM13.8942 9.35938L9.49858 13.8764L5.10295 9.35938L9.49858 4.84239L13.8942 9.35938Z"
      fill="url(#paint5_linear_11273_606)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_11273_606"
        x1="9.93724"
        y1="12.2138"
        x2="12.6009"
        y2="15.1779"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#65B5FF" />
        <stop offset="1" stopColor="#64E4FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_11273_606"
        x1="-2.18901"
        y1="11.7279"
        x2="2.01635"
        y2="-0.258786"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.14601" stopColor="#8EECFF" />
        <stop offset="1" stopColor="#A8EFFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_11273_606"
        x1="1.15437"
        y1="8.64844"
        x2="4.40932"
        y2="21.3312"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2E3DB0" />
        <stop offset="1" stopColor="#55C1F2" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_11273_606"
        x1="7.86251"
        y1="-0.0814245"
        x2="18.9029"
        y2="2.29142"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2354C0" />
        <stop offset="0.0001" stopColor="#2354C0" />
        <stop offset="1" stopColor="#A3E9FF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_11273_606"
        x1="-1.40122"
        y1="2.6496"
        x2="8.05283"
        y2="2.21511"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0203454" stopColor="#2A67C4" />
        <stop offset="0.349209" stopColor="#2E3AB0" />
        <stop offset="1" stopColor="#79E8FF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_11273_606"
        x1="9.46265"
        y1="4.52633"
        x2="9.45831"
        y2="13.4302"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D7F8FF" />
        <stop offset="0.176669" stopColor="#9EEDFF" />
        <stop offset="0.432173" stopColor="#66E3FF" />
        <stop offset="0.685688" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
