import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { MeAPI } from '@entities/me/api';
import { useQuery } from '@tanstack/react-query';
import { MemeAPI } from '@widgets/Dex/entities/meme/api';
import { Token } from '@widgets/Dex/entities/meme/type';

import { Convert, PortfolioToken, TotalBalance } from './ui';

export const PortfolioPage: FC = () => {
  const profile = useQuery(MeAPI.getMeQueryOptions());
  const meme = useQuery(MemeAPI.getUserTokensQueryOptions());

  const userCreatedTokens = meme.data?.items.filter(
    (memeCoin: Token) => Number(memeCoin.userId) === profile.data.id,
  );

  const otherTokens = meme.data?.items.filter(
    (memeCoin: Token) => Number(memeCoin.userId) !== profile.data.id,
  );

  return (
    <div className="pb-3">
      {/* <Heading /> */}
      <TotalBalance />
      <Convert />

      {meme.isFetched && meme.data?.items?.length === 0 && (
        <div className="flex flex-col gap-4">
          <div className="pt-4 text-sm text-gray justify-center flex">
            You don&apos;t have a coin
          </div>
          {/* FIXME: make link to list coins*/}
          {/*<Button size="lg">Buy now</Button>*/}
        </div>
      )}

      <div className="mt-6">
        {userCreatedTokens?.map((memeCoin: Token) => {
          return (
            <NavLink key={memeCoin.address} to={`/dex/pay/${memeCoin.address}`}>
              <PortfolioToken tokenData={memeCoin} isUserCreate={true} />
            </NavLink>
          );
        })}
      </div>

      <div className="mt-6">
        {otherTokens?.map((memeCoin: Token) => {
          return (
            <NavLink key={memeCoin.address} to={`/dex/pay/${memeCoin.address}`}>
              <PortfolioToken tokenData={memeCoin} isUserCreate={false} />
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};
