import { formatNumber } from '@shared/lib/number/format-number';

import arrowImg from '/images/icons/arrow.svg';

const calcClass = (value: number, upDown: string) => {
  if (value === 0) {
    return 'bg-gray-light';
  }
  return upDown === 'UP' ? 'bg-success-strong' : 'bg-critic-strong-up';
};

export const Percent = ({ value, upDown }: { value: number; upDown: string }) => {
  const arrowRotationClass = upDown === 'DOWN' && value !== 0 ? 'rotate-180' : '';

  return (
    <div
      className={`flex items-center gap-x-1 px-2 py-[3px] leading-[16.8px] rounded-2-5xl text-[13px]  text-base-container font-medium ${calcClass(value, upDown)}`}
    >
      <img
        src={arrowImg}
        alt="Arrow"
        className={`${arrowRotationClass} transition-transform duration-300`}
      />
      {formatNumber(value, true)}%
    </div>
  );
};
