import { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// import { MeAPI } from '@entities/me/api';
import { DailyAPI } from '@pages/DailyTasks/entities/api';
import { Gem } from '@shared/ui';
import { TonButton } from '@shared/ui/TonButton';
import { useQuery } from '@tanstack/react-query';

// import WebApp from '@twa-dev/sdk';
import { TasksAPI } from './entities/api';
import { TaskWrap } from './entities/type';
import { BottomSheetTgSubScribe, Task } from './ui';

// const checkStatus = async () => {
//   return new Promise((resolve, reject) => {
//     WebApp.checkHomeScreenStatus((status) => {
//       if (status) {
//         resolve(status);
//       } else {
//         reject(new Error('Failed to retrieve status'));
//       }
//     });
//   });
// };

// const btAddToHomeScreen = async () => {
//   try {
//     const status = await checkStatus();
//     if (status !== 'unsupported' && status !== 'added') {
//       WebApp.addToHomeScreen();
//     }
//   } catch (error) {
//     console.error('Error checking home screen status:', error);
//   }
// };

export const EarnPage: FC = () => {
  const { data: taskData } = useQuery(TasksAPI.getTasksListQueryOptions());
  const { data: dailyList } = useQuery(DailyAPI.getDailyListQueryOptions());
  const [, setSearchParameters] = useSearchParams();
  const [showDailySheet, setShowDailySheet] = useState(false);
  const [selectedItem, setSelectedItem] = useState<TaskWrap | null>(null);

  const toDailyTasks = () => {
    setSearchParameters({ daily: 'true' });
  };

  const currentDaily = () => {
    const list = dailyList ?? [];
    const daily =
      list.find((element_, index, d) => !element_.claimed && d[index - 1]?.claimed) ??
      list[0];
    return daily ? daily.reward : null;
  };

  const handleTaskOpen = (task: TaskWrap) => {
    setShowDailySheet(true);
    setSelectedItem(task);
  };

  // const storyTask = taskData?.find((task) => task.task.linkUrl === ':share');
  const remainingTasks = taskData?.filter((task) => task.task.linkUrl !== ':share');

  const sortedTasks = remainingTasks
    ? [...remainingTasks].sort((a, b) => Number(a.complate) - Number(b.complate))
    : [];

  return (
    <div className="max-w-container mx-4">
      <h3 className="text-3-5xl text-center text-white font-bold mt-3">
        {taskData?.length} Tasks Available
      </h3>
      <p className="text-base text-center text-gray font-medium mt-2">
        Complete tasks and earn <span className="text-white">RXP</span> <Gem />
      </p>
      <div className="mt-2">
        <TonButton>
          Connect TON Wallet +20,000 <Gem />
        </TonButton>
      </div>

      {/* <Button onClick={btAddToHomeScreen} className="mt-3">
        Add to Home Screen
      </Button> */}

      <div className="mt-4">
        <p className="text-xl mb-2 font-semibold">Daily Tasks</p>
        <Task
          icon="daily"
          title="Daily Reward"
          color="#FF44D6"
          reward={currentDaily()}
          onClick={toDailyTasks}
        />
        {/* <Task
          icon={storyTask.task.linkUrl}
          title={storyTask.task.title}
          color="#FF44D6"
          reward={storyTask.task.reward}
          complete={storyTask.complate}
          onClick={() => handleTaskOpen(storyTask)}
        /> */}
        <p className="text-xl mt-2 font-semibold">Task List</p>
        {sortedTasks.map((task) => (
          <Task
            key={task.task.id}
            icon={task.task.linkUrl}
            title={task.task.title}
            color={task.task.color}
            reward={task.task.reward}
            complete={task.complate}
            onClick={() => handleTaskOpen(task)}
          />
        ))}
      </div>

      {showDailySheet && (
        <BottomSheetTgSubScribe
          data={selectedItem}
          open={showDailySheet}
          onClose={() => setShowDailySheet(false)}
        />
      )}
    </div>
  );
};
