import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@components/index';

import searchImg from '/images/icons/searchWhite.svg';

interface Properties {
  buttonIndex: number;
  setButtonIndex(index: number): void;
  isSearch?: boolean;
}

export const ToggleButtons: FC<Properties> = ({
  buttonIndex,
  setButtonIndex,
  isSearch,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex justify-between gap-x-3">
      <div className="flex items-center w-full h-10 bg-container-dark rounded-xl ">
        <Button
          variant={buttonIndex === 0 ? 'success-strong-down' : 'container-dark'}
          onClick={() => setButtonIndex(0)}
          className="h-full"
        >
          Buy
        </Button>
        <Button
          variant={buttonIndex === 1 ? 'critic-strong-down' : 'container-dark'}
          onClick={() => setButtonIndex(1)}
          className="h-full"
        >
          Sell
        </Button>
      </div>
      {isSearch && (
        <div
          className="min-w-10 h-10 bg-faint-weak rounded-xl flex justify-center items-center"
          onClick={() => navigate(`/dex/search?back=${location.pathname}`)}
        >
          <img src={searchImg} alt="Search" />
        </div>
      )}
    </div>
  );
};
