import { FC, useEffect, useRef, useState } from 'react';

import uploadImg from '/images/icons/upload.svg';

interface UploadImageProperties {
  onFileSelect: (file: File) => void;
  resetPreview: boolean;
}

export const UploadImage: FC<UploadImageProperties> = ({
  onFileSelect,
  resetPreview,
}) => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const inputReference = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (resetPreview) {
      setImagePreview(null);
      onFileSelect(null);
    }
  }, [resetPreview, onFileSelect]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        if (typeof reader.result === 'string') {
          setImagePreview(reader.result);
        }
      });
      reader.readAsDataURL(file);

      onFileSelect(file);
    }
  };

  const handleClick = () => {
    inputReference.current?.click();
  };

  return (
    <div className="mt-5" onClick={handleClick}>
      <input
        type="file"
        accept="image/png, image/jpeg, image/gif"
        ref={inputReference}
        className="hidden"
        onChange={handleImageUpload}
      />
      <div
        className={`w-24 h-24 rounded-xl
        ${imagePreview ? '' : 'bg-gradient-to-r from-[#587BFF33] to-[#B518FF33]'}
      flex justify-center items-center mx-auto
      `}
        style={{
          backgroundImage: imagePreview ? `url(${imagePreview})` : undefined,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {!imagePreview && <img src={uploadImg} alt="Upload Icon" />}
      </div>

      <p className="text-center font-semibold text-white text-lg mt-2">
        Upload token avatar
      </p>
      <p className="text-center text-gray">(.png .jpg .gif)</p>
    </div>
  );
};
