import { FC, useState } from 'react';
import { MeAPI } from '@entities/me/api';
import { useQuery } from '@tanstack/react-query';

import { Accepted, Form, Heading, Links } from './ui';

import listingImg from '/images/banners/listing.png';

export const ListingPage: FC = () => {
  const profile = useQuery(MeAPI.getMeQueryOptions());
  const [isAccepted, setIsAccepted] = useState(false);

  return (
    <>
      <div className="w-[239px] h-[132px] mx-auto">
        <img src={listingImg} />
      </div>

      <Heading />

      <Links />

      {isAccepted || profile.data?.whitelistBought ? (
        <Accepted />
      ) : (
        <Form setSubmitted={() => setIsAccepted(true)} />
      )}
    </>
  );
};
