import { clientAPI } from '@shared/api/client';

import { IBoost } from './../type/index';

export const BoostsAPI = {
  getBoosts: () => clientAPI('game/boosts').json<{ boosts: IBoost[] }>(),
  buyBoosts: (id: string) =>
    clientAPI(`game/boosts/buy?id=${id}`).json<{ boosts: IBoost[] }>(),

  getBoostsQueryOptions: () => ({
    queryKey: ['boosts'],
    queryFn: () => BoostsAPI.getBoosts(),
  }),

  getBuyBoostsQueryOptions: (id: string) => ({
    queryKey: ['boosts_buy', id],
    queryFn: () => BoostsAPI.buyBoosts(id),
  }),
};
