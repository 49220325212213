import { FC, Ref } from 'react';
import { Input } from '@components/ui/input';
import { PaperPlaneFilled, PaperPlaneGradient } from '@shared/ui';

interface Properties {
  ref: Ref<HTMLInputElement>;
  type?: 'default' | 'success';
  value: string;
  onChange: (value: string) => void;
}

export const TelegramInput: FC<Properties> = ({ type, value, onChange }) => (
  <div className="h-[56px] relative">
    <div className="absolute left-3 h-full flex items-center">
      {type === 'default' ? <PaperPlaneFilled /> : <PaperPlaneGradient />}
    </div>
    <Input
      value={value}
      padding="10"
      variant={'container'}
      placeholder="TG Channel (Optional)"
      onChange={(event_) => onChange(event_.target.value)}
    />
  </div>
);
