import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { NavLink } from 'react-router-dom';
import { Button } from '@components/index';
import { MeAPI } from '@entities/me/api';
import { formatNumber } from '@shared/lib/number/format-number';
import { Friend, InviteButtons, Leader } from '@shared/ui';
import BalanceDisplay from '@shared/ui/BalanceDisplay';
import { Gem } from '@shared/ui/Gem';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { FriendsAPI } from '../entities/friends/api';
import { LeadersAPI } from '../entities/leaders/api';

import { gifts, percentCells } from './constants';
import { Gift, PercentCell, SwitchButtons } from './ui';

const buttons = ['Frens', 'Leaderboard'];

const MAX_FRENS_TO_DISPLAY = 10;

export const FrensAndLeadersPage: React.FC = () => {
  const qc = useQueryClient();
  const [activeButton, setActiveButton] = useState(0);
  const [claim, setClaim] = useState<number>(0);

  const { data: frens } = useQuery(FriendsAPI.getFriendsQueryOptions());
  const { data: profile } = useQuery(MeAPI.getMeQueryOptions());
  const { data: leadersPages, fetchNextPage: loadMoreLeaders } = useInfiniteQuery(
    LeadersAPI.getLeadersInfinityQueryOptions(),
  );
  const leaders = leadersPages?.pages.flatMap((leaderPage) => leaderPage.users) ?? [];

  useEffect(() => {
    setClaim(profile.amountFriends);
  }, [profile]);

  const totalLeaders = leadersPages?.pages[0].total ?? 0;

  const claimMutatin = useMutation({
    mutationKey: ['frens_claim'],
    mutationFn: () => FriendsAPI.claimFriens(),
    onSuccess: () => {
      toast.success('Reward received!');
    },
    onSettled() {
      qc.invalidateQueries({
        queryKey: MeAPI.getMeQueryOptions().queryKey,
      });
    },
  });

  const userLeader = leaders.find((leader) => leader.id === profile?.id);

  return (
    <div className="pb-20">
      <div className="mt-5 mb-4 sticky top-[60px] z-[2]">
        <SwitchButtons
          currentButtonIndex={activeButton}
          buttons={buttons}
          setCurrentButtonIndex={setActiveButton}
        ></SwitchButtons>
      </div>

      <div className="max-w-container mx-4">
        {activeButton === 0 ? (
          <>
            <h3 className="text-1-5xl text-center font-semibold">
              3 lvl Referral Program
            </h3>
            <div className="grid grid-cols-3 gap-2  mt-4">
              {percentCells.map((cell) => (
                <PercentCell
                  key={cell.id}
                  percent={cell.percent}
                  text={cell.text}
                ></PercentCell>
              ))}
            </div>
            <div className="mt-4">
              {gifts.map((gift) => (
                <Gift
                  key={gift.id}
                  img={gift.img}
                  title={gift.title}
                  bonus={Number(gift.bonus)}
                  bg={gift.id === 0 ? 'default' : 'premium'}
                ></Gift>
              ))}
            </div>
            <div className="flex items-center justify-between mt-4 mb-4">
              <p className="text-1-5xl font-semibold">My Frens: {frens?.totalCount}</p>
              <div className="h-[44px] w-[155px] relative">
                {claim ? (
                  <Button onClick={() => claimMutatin.mutate()}>
                    Claim <Gem></Gem>
                    <BalanceDisplay balance={claim} big />
                    <div className="absolute top-[10px] right-[9px] w-[6px] h-[6px] rounded-full bg-gradient-to-r from-pink to-red"></div>
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {frens?.users
              .slice(0, MAX_FRENS_TO_DISPLAY)
              .map((fren) => (
                <Friend
                  key={fren.id}
                  telegramID={fren.telegramId}
                  name={fren.name}
                  lvl={fren.level.currentUserLevelIndex + 1}
                  friends={fren.inviteCount}
                  balance={fren.balance.toString()}
                ></Friend>
              ))}
          </>
        ) : (
          <>
            {leaders.map((leader, index) => (
              <Leader
                key={leader.id}
                telegramID={leader.telegramId}
                name={leader.name}
                lvl={leader.level.currentUserLevelIndex + 1}
                friends={leader.inviteCount}
                balance={leader.balance.toString()}
                place={index + 1}
              />
            ))}

            {leaders.length < totalLeaders ? (
              <div className="flex justify-center mt-4">
                <button
                  className="bg-transparent text-blue text-sm font-semibold"
                  onClick={() => loadMoreLeaders()}
                >
                  Load more &rsaquo;
                </button>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>

      {activeButton === 0 && frens?.totalCount !== undefined ? (
        frens.totalCount === 0 ? (
          <p className="text-base text-gray text-center mt-2">
            Your friends list is empty
          </p>
        ) : (
          <>
            <div className="flex justify-center mt-4">
              <NavLink to="/frens/all">
                <button className="bg-transparent text-blue text-sm font-semibold">
                  Show All &rsaquo;
                </button>
              </NavLink>
            </div>
          </>
        )
      ) : null}

      <div
        className={`w-full mt-4 px-4 fixed ${activeButton === 0 && 'bottom-24'} ${userLeader ? 'bottom-20' : 'bottom-24'} left-0`}
      >
        {activeButton === 0 ? (
          <InviteButtons />
        ) : userLeader ? (
          <Leader
            key={userLeader.id}
            telegramID={userLeader.telegramId}
            name={userLeader.name}
            lvl={userLeader.level.currentUserLevelIndex + 1}
            friends={userLeader.inviteCount}
            balance={formatNumber(userLeader.balance, true)}
            place={leaders.findIndex((l) => l.id === userLeader.id) + 1}
          />
        ) : (
          <InviteButtons />
        )}
      </div>
    </div>
  );
};
