import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { Button } from '@components/ui/button.tsx';
import { Drawer, DrawerFooter, DrawerTrigger } from '@components/ui/drawer';
import { DrawerContent } from '@components/ui/drawer.tsx';
import { ConfigsAPI } from '@entities/config/api';
import { Ton } from '@shared/ui';
import { TonButton } from '@shared/ui/TonButton';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { MemeAPI } from '@widgets/Dex/entities/meme/api';
import { MemeCard } from '@widgets/Dex/ui/MemeCard';

import fireGif from '/images/gif/fire.gif';
import closeImg from '/images/icons/close.svg';

interface Properties {
  triggerProps?: {
    asChild?: boolean;
  };
}

export const BoostModal: React.FC<React.PropsWithChildren<Properties>> = ({
  children,
  triggerProps,
}) => {
  const { address } = useParams<{ address: string }>();
  const token = useQuery(MemeAPI.getTokenByAddressQueryOptions(address));
  const dexConfig = useQuery(ConfigsAPI.getDexConfigOptions());

  const [isOpen, setIsOpen] = useState(false);

  const [tonConnectUI] = useTonConnectUI();
  const tonAddress = useTonAddress();

  const payWebhook = useMutation({
    mutationFn: MemeAPI.postBuyForTon,
  });

  const payTon = useMutation({
    mutationFn: async () => {
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 60 * 2, // 120 sec
        messages: [
          {
            address: dexConfig.data.tonAddressRoot,
            amount: dexConfig.data.memeBoostPrice1hour.tonInNano,
          },
        ],
      };

      const { boc } = await tonConnectUI.sendTransaction(transaction);

      await payWebhook.mutateAsync({
        boc,
        address,
        sender: tonAddress,
        paymentType: 'hourInTop',
      });
    },
  });

  return (
    <Drawer open={isOpen} onOpenChange={setIsOpen}>
      <DrawerTrigger asChild={triggerProps?.asChild}>{children}</DrawerTrigger>
      <DrawerContent className="bottom-sheet-white">
        <button
          className="text-black bg-base-container w-[30px] h-[30px] rounded-full flex justify-center items-center
            absolute top-[23px] right-[23px]"
          onClick={() => setIsOpen(false)}
        >
          <img src={closeImg} />
        </button>
        <img src={fireGif} className="w-20 h-20" alt="" />
        <div className="text-[22px] font-semibold mt-5 mb-3">
          Boost Your Meme for{' '}
          <span className="bg-gradient-to-b from-[#587BFF] to-[#B518FF] bg-clip-text text-transparent">
            1 Hour
          </span>
        </div>
        <p className="text-sm text-gray-light font-medium text-center max-w-[340px] mb-7">
          Your meme will be at the <span className="text-white">Top 5</span> of the
          Rankings for 1 hour with a{' '}
          <span className="font-bold bg-gradient-to-b from-[#FFFBE3]  to-[#D29A00] bg-clip-text text-transparent">
            GOLDEN TICKER
          </span>
        </p>
        <p className="text-sm font-semibold mb-4">Your Meme for 1 hour:</p>
        <div className="w-[340px]">
          <MemeCard token={{ ...token.data, top: true }} />
        </div>
        <DrawerFooter>
          {tonConnectUI.connected ? (
            <Button
              variant="blue-4"
              className="w-full"
              onClick={() => {
                toast.promise(payTon.mutateAsync(), {
                  loading: 'Awaiting blockchain...',
                  success: 'Successfully!',
                  error: 'Error during transaction',
                });
              }}
            >
              <Ton width={30} height={30} />
              Boost for 1 TON
            </Button>
          ) : (
            <TonButton>Connect TON Wallet</TonButton>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
