import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from '@components/index';
import { DollarIcon } from '@shared/icons/Dollar';
import { DollarWhiteIcon } from '@shared/icons/DollarWhite';
import { weiToEther } from '@shared/lib/number/format-number';
import BalanceDisplay from '@shared/ui/BalanceDisplay';
import { useQuery } from '@tanstack/react-query';
import { MemeAPI } from '@widgets/Dex/entities/meme/api';

export const Convert: FC = () => {
  const balance = useQuery(MemeAPI.getEthBalanceQueryOptions());

  return (
    <div className="w-full flex items-center max-w-container mx-4 justify-between mt-4 px-4 py-[14px] rounded-[20px] bg-base-background border-1 border-faint-weak">
      <div className="flex gap-x-3 item-center">
        <div className="w-[42px] h-[42px]">
          <DollarIcon width={42} height={42} />
        </div>
        <div className="flex flex-col items-start">
          <p className="text-[15px] text-white font-semibold text-base">
            Dollar Simulator
          </p>
          <div className="flex items-center gap-x-0.5">
            <DollarWhiteIcon width={13} />
            <p className="text-[13px] text-white font-semibold">
              <BalanceDisplay
                balance={Number(weiToEther(balance.data?.weiBalance?.value))}
              />
            </p>
          </div>
        </div>
      </div>
      <NavLink to={'/dex/swap'}>
        <div className="w-max relative h-10">
          <Button
            className="w-full h-full pl-4 pr-5 relative gap-x-1 text-[15px]"
            rounded="full"
          >
            Swap
            <div className="absolute right-3 top-[11px] w-[6px] h-[6px] rounded-full bg-gradient-to-r from-pink to-red"></div>
          </Button>
        </div>
      </NavLink>
    </div>
  );
};
