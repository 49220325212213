import { FC, PropsWithChildren } from 'react';
import { queryClient } from '@shared/config/query-client';
import { isDev } from '@shared/variables';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react';

export const Provider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <TonConnectUIProvider
      manifestUrl="https://app.ray.sx/tonconnect-manifest.json"
      uiPreferences={{ theme: THEME.DARK }}
      actionsConfiguration={{
        twaReturnUrl: 'https://t.me/miniapptester_bot/start',
      }}
    >
      <QueryClientProvider client={queryClient}>
        {children}
        {isDev && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </TonConnectUIProvider>
  );
};
