import { MeAPI } from '@entities/me/api';
import { BoostsAPI } from '@pages/MiningBoosts/entities/api/index.ts';
import { removeLoader } from '@shared/lib/utils.ts';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import WebApp from '@twa-dev/sdk';
import { useUnit } from 'effector-react';

import { DailyAPI } from '../../pages/DailyTasks/entities/api/index.ts';
import { TasksAPI } from '../../pages/Earn/entities/api/index.ts';
import { FriendsAPI } from '../../pages/FrensAndLeaders/entities/friends/api/index.ts';
import { LeadersAPI } from '../../pages/FrensAndLeaders/entities/leaders/api/index.ts';
import { MiningAPI } from '../../pages/Home/entities/mining/api';
import { LevelsAPI } from '../../pages/Profile/entities/levels/api/index.ts';
import { SkinsAPI } from '../../pages/Profile/entities/skins/api/index.ts';
import { $token } from '../../store/auth.ts';

export const useInit = (): boolean => {
  const [token] = useUnit([$token]);

  WebApp.ready();
  WebApp.disableVerticalSwipes();

  // rewrite to useQueries
  const profile = useQuery({
    ...MeAPI.getMeQueryOptions(),
    enabled: !!token,
  });

  const mining = useQuery({
    ...MiningAPI.getMiningQueryOptions(),
    enabled: !!token,
  });

  const portfolio = useQuery({
    ...MeAPI.getUserPortfolioQueryOptions(),
    enabled: !!token,
  });

  useQuery({
    ...FriendsAPI.getFriendsQueryOptions(),
    enabled: !!token,
  });

  useQuery({
    ...LevelsAPI.getLevelsQueryOptions(),
    enabled: !!token,
  });

  useQuery({
    ...SkinsAPI.getSkinsQueryOptions(),
    enabled: !!token,
  });

  useQuery({
    ...DailyAPI.getDailyListQueryOptions(),
    enabled: !!token,
  });

  useQuery({
    ...DailyAPI.getDailyQueryOptions(),
    enabled: !!token,
  });

  useQuery({
    ...TasksAPI.getTasksListQueryOptions(),
    enabled: !!token,
  });

  useQuery({
    ...BoostsAPI.getBoostsQueryOptions(),
    enabled: !!token,
  });

  useInfiniteQuery({
    ...FriendsAPI.getFriendsInfinityQueryOptions(),
    enabled: !!token,
  });

  useInfiniteQuery({
    ...LeadersAPI.getLeadersInfinityQueryOptions(),
    enabled: !!token,
  });

  if (profile.isError) {
    removeLoader();
    throw new Error(profile.error.message);
  }

  if (mining.isError) {
    removeLoader();
    throw new Error(mining.error.message);
  }

  if (portfolio.isError) {
    removeLoader();
    throw new Error(portfolio.error.message);
  }

  return !!token && profile.isFetched && mining.isFetched;
};
