import { FC } from 'react';

interface Properties {
  name: string;
}

export const Badge: FC<Properties> = ({ name }) => {
  return (
    <div
      className="w-max rounded-full px-1 py-[3.7px] bg-[#FFFFFFAD] text-xs text-faint-weak font-semibold"
      style={{
        backdropFilter: 'blur(21.5px)',
        WebkitBackdropFilter: 'blur(21.5px)',
      }}
    >
      {name}
    </div>
  );
};
