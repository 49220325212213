import { clientAPI } from '@shared/api/client';
import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query';

import { Friend, Friends } from '../type';

const LIMIT_OF_FRENS = 10;

export const FriendsAPI = {
  claimFriens: () => clientAPI('game/friends/claim').json<{ balance: number }>(),

  getFriends: () =>
    clientAPI('game/friends', {
      searchParams: { page: 0, peerPage: 10 },
    }).json<Friends>(),

  getInfiniteFriends: (offset: number = 0) =>
    clientAPI('game/friends', {
      searchParams: { page: offset, peerPage: LIMIT_OF_FRENS },
    }).json<{ totalCount: number; users: Friend[] }>(),

  getFriendsInfinityQueryOptions: () =>
    infiniteQueryOptions({
      queryKey: ['inf_friends'],
      queryFn: ({ pageParam: pageParameter = 0 }) =>
        FriendsAPI.getInfiniteFriends(pageParameter),
      getNextPageParam: (lastPage: { totalCount: number; users: Friend[] }, allPages) => {
        return lastPage.users.length > 0 ? allPages.length : undefined;
      },
      initialPageParam: 0,
    }),

  getFriendsQueryOptions: () =>
    queryOptions({
      queryKey: ['friends'],
      queryFn: () => FriendsAPI.getFriends(),
    }),
} as const;
