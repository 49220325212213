import { FC } from 'react';

import ecoIcon from '/images/icons/eco-toggle.svg';
import ecoIconInactive from '/images/icons/ecoGray.svg';
import fireIcon from '/images/icons/fire-toggle.svg';
import fireIconInactive from '/images/icons/fireGray.svg';
import topIcon from '/images/icons/top-toggle.svg';
import topIconInactive from '/images/icons/top100Gray.svg';

interface Properties {
  currentButtonIndex: number;
  buttons: string[];
  setCurrentButtonIndex(index: number): void;
}

export const ToggleButtons: FC<Properties> = ({
  currentButtonIndex,
  buttons,
  setCurrentButtonIndex,
}) => {
  const activeIcons = {
    Hot: fireIcon,
    'Top 100': topIcon,
    New: ecoIcon,
  };

  const inactiveIcons = {
    Hot: fireIconInactive,
    'Top 100': topIconInactive,
    New: ecoIconInactive,
  };

  return (
    <div className="flex items-center sticky top-[56px]  py-1 z-[2] bg-base-background">
      {buttons.map((button, index) => (
        <button
          key={index}
          className={`flex w-max items-center gap-x-1 justify-center px-[13px] h-[44px] py-3 text-base font-semibold  rounded-2xl ${
            currentButtonIndex === index
              ? 'text-white bg-gradient-to-r from-blue-2 to-purple-2'
              : 'text-gray bg-base-background'
          }`}
          onClick={() => setCurrentButtonIndex(index)}
        >
          <img
            src={
              currentButtonIndex === index ? activeIcons[button] : inactiveIcons[button]
            }
            alt={`${button} Icon`}
          />
          {button}
        </button>
      ))}
    </div>
  );
};
