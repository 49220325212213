import { clientAPI } from '@shared/api/client.ts';
import { ApiWithPagination } from '@shared/types/api-with-pagination';
import { Balances } from '@shared/types/BigintAPI';
import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query';
import dayjs from 'dayjs';

import {
  BuyForTonDto,
  CandleData,
  CandleDto,
  SwapErc20ForErc20Dto,
  Token,
  TokenData,
} from '../type';

export const MemeAPI = {
  createToken: async (
    data: TokenData,
    imageFile: File | null,
    calculatedDexLiquidity: string,
  ) => {
    const formData = new FormData();

    formData.append('name', data.tokenName);
    formData.append('symbol', data.tokenSymbol);
    formData.append('totalSupply', data.totalSupply);
    formData.append('supplyForDex', calculatedDexLiquidity);
    formData.append('raysNetworkTokenForDex', data.raysNetworkTokenForDex);
    formData.append('description', data.tokenDescription);
    formData.append('tgChannel', data.telegram || '');
    formData.append('hash', data.hash || '');

    if (imageFile) {
      formData.append('image', imageFile);
    }

    return clientAPI.post('v2/meme/createToken', {
      body: formData,
      timeout: false,
    });
  },

  swapErc20ForErc20: ({ signal, ...dto }: SwapErc20ForErc20Dto) =>
    clientAPI
      .post('v2/dex/swapErc20ForErc20', {
        json: dto,
        signal,
      })
      .json<{ amountOut: string }>(),

  getTokens: (page: number, top: string) =>
    clientAPI(`v2/meme/getTokens?page=${page}&perPage=15&top=${top}`).json<
      ApiWithPagination<Token>
    >(),

  getFindTokens: (symbol?: string) =>
    clientAPI(`v2/meme/findToken?name=${symbol}`).json<Token[]>(),

  getTokenByAddress: (address: string) =>
    clientAPI(`v2/meme/getToken?address=${address}`).json<Token>(),

  postBuyForTon: (dto: BuyForTonDto) =>
    clientAPI.post('v2/meme/buyForTon', { json: dto }),

  getCreateMemeInvoice: () =>
    clientAPI('v2/meme/getStarsInvoice?type=createMeme').json<{
      invoiceLink: string;
    }>(),

  getTokenBalanceByAddress: (address: string) =>
    clientAPI(`v2/meme/getMemeBalance?tokenAddress=${address}`).json<{
      value: string;
    }>(),

  getUserTokens: () =>
    clientAPI('v2/meme/getUserTokens?perPage=15&page=1').json<{
      items: Token[];
    }>(),

  getEthBalance: () => clientAPI('v2/meme/getEthBalance').json<Balances>(),

  getTokensInfiniteQueryOptions: (top: string) =>
    infiniteQueryOptions({
      queryKey: ['meme_tokens', top],
      queryFn: ({ pageParam }) => MemeAPI.getTokens(pageParam, top),
      initialPageParam: 0,
      getNextPageParam: (data) => data.metadata.next,
      select: ({ pages }) => pages.flatMap(({ items }) => items),
    }),

  getUserTokensQueryOptions: () =>
    queryOptions({
      queryKey: ['portfolio_meme_tokens'],
      queryFn: () => MemeAPI.getUserTokens(),
    }),

  getFindTokensQueryOptions: (symbol?: string) =>
    queryOptions({
      queryKey: ['find_meme_tokens', symbol],
      queryFn: () => MemeAPI.getFindTokens(symbol),
    }),

  getTokenByAddressQueryOptions: (address: string) =>
    queryOptions({
      queryKey: [`meme_token_id-${address}`],
      queryFn: () => MemeAPI.getTokenByAddress(address),
    }),

  getTokenBalanceByAddressQueryOptions: (address: string) =>
    queryOptions({
      queryKey: [`meme_token_id_balance-${address}`],
      queryFn: () => MemeAPI.getTokenBalanceByAddress(address),
    }),

  getEthBalanceQueryOptions: () =>
    queryOptions({
      queryKey: ['balance_eth'],
      queryFn: () => MemeAPI.getEthBalance(),
    }),

  getCandleHistory: (dto: CandleDto) =>
    clientAPI(`v2/meme/getAllCandle?timeFrame=${dto.time}&address=${dto.address}`).json<
      CandleData[]
    >(),

  getCandleHistoryQueryOptions: (dto: CandleDto) =>
    queryOptions({
      queryKey: ['meme_candles_history', dto.time, dto.address],
      queryFn: () => MemeAPI.getCandleHistory(dto),
      select: (data) => data.map((v) => ({ time: dayjs(v.time).toISOString(), ...v })),
    }),

  getLastCandle: (dto: CandleDto) =>
    clientAPI(
      `v2/meme/getLastCandle?timeFrame=${dto.time}&address=${dto.address}`,
    ).json<CandleData>(),

  getLastCandleQueryOptions: (dto: CandleDto) =>
    queryOptions({
      queryKey: ['meme_last_candle', dto.time, dto.address],
      queryFn: () => MemeAPI.getLastCandle(dto),
      staleTime: 0,
      refetchInterval: 2000,
    }),
};
