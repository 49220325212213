import { FC } from 'react';

interface Properties {
  width?: number;
  height?: number;
}

const DEFAULT_SIZE = 50;

export const BNB: FC<Properties> = ({ width = DEFAULT_SIZE, height = DEFAULT_SIZE }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 0C38.8081 0 50 11.1919 50 25C50 38.8081 38.8081 50 25 50C11.1919 50 0 38.8081 0 25C0 11.1919 11.1919 0 25 0Z"
      fill="#F0B90B"
    />
    <path
      d="M13.7388 25.0071L13.7568 31.6177L19.3738 34.923V38.7932L10.4695 33.5708V23.074L13.7388 25.0071ZM13.7388 18.3966V22.2487L10.4675 20.3136V16.4615L13.7388 14.5264L17.026 16.4615L13.7388 18.3966ZM21.7195 16.4615L24.9908 14.5264L28.278 16.4615L24.9908 18.3966L21.7195 16.4615Z"
      fill="white"
    />
    <path
      d="M16.1072 30.2475V26.3773L19.3784 28.3124V32.1646L16.1072 30.2475ZM21.7242 36.3092L24.9954 38.2443L28.2827 36.3092V40.1614L24.9954 42.0965L21.7242 40.1614V36.3092ZM32.9742 16.4615L36.2454 14.5264L39.5327 16.4615V20.3136L36.2454 22.2487V18.3966L32.9742 16.4615ZM36.2454 31.6177L36.2634 25.0071L39.5347 23.072V33.5688L30.6304 38.7912V34.921L36.2454 31.6177Z"
      fill="white"
    />
    <path
      d="M33.8926 30.2374L30.6213 32.1544V28.3023L33.8926 26.3672V30.2374Z"
      fill="white"
    />
    <path
      d="M33.8996 19.7591L33.9177 23.6293L28.2847 26.9346V33.5612L25.0134 35.4783L21.7422 33.5612V26.9346L16.1092 23.6293V19.7591L19.3944 17.824L24.9934 21.1453L30.6264 17.824L33.9137 19.7591H33.8996ZM16.1072 13.1505L24.9954 7.91016L33.8996 13.1505L30.6284 15.0856L24.9954 11.7643L19.3784 15.0856L16.1072 13.1505Z"
      fill="white"
    />
  </svg>
);
