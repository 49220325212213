import { FC } from 'react';
import { MeAPI } from '@entities/me/api';
import BalanceDisplay from '@shared/ui/BalanceDisplay';
import { useQuery } from '@tanstack/react-query';

import { LevelsAPI } from '../../../entities/levels/api';

const borderStyle = `
  .custom-gradient:after {
    background: linear-gradient(118.65deg, #587BFF 15.67%, #B518FF 91.74%);
    border-radius: 8px;
    box-shadow: 0 0 12.429px 1.714px rgba(158, 48, 255, 0.83);
    position: absolute;
    inset: 0;
  }
`;

const Level = ({
  isCurrent,
  icon,
  name,
  from,
  to,
}: {
  isCurrent: boolean;
  icon: string;
  name: string;
  from: number;
  to: number | string;
}): JSX.Element => (
  <>
    <style>{borderStyle}</style>

    <div
      className={`relative w-full flex justify-between items-center p-[17px] border-t-solid border-t-1 ${
        isCurrent && 'bg-base-container'
      } border-y-base-container`}
    >
      <div className="flex items-center gap-2">
        <div className="custom-gradient w-max px-2 relative text-3xs font-semibold rounded-xl text-white bg-gradient-to-r from-blue-2 to-purple-2">
          {icon.split('_')[1]}lvl
        </div>
        <p className="text-sm font-semibold">{name}</p>
      </div>

      <div className="text-sm font-medium">
        <BalanceDisplay balance={from} big /> -{' '}
        {Number.isInteger(to) ? <BalanceDisplay balance={Number(to)} big /> : to}
      </div>
    </div>
  </>
);

export const AllLevels: FC = () => {
  const { data: levels } = useQuery(LevelsAPI.getLevelsQueryOptions());
  const { data: profile } = useQuery(MeAPI.getMeQueryOptions());

  const currentLevel = profile.level.currentUserLevelIndex + 1;

  return (
    <div className="pt-5">
      <div className="px-4">
        <p className="text-xl text-white font-semibold ">All levels</p>

        <div className="mb-[7px] mt-4 flex items-center justify-between text-gray-light">
          <p className="text-xs font-semibold">Level</p>

          <p className="text-xs font-semibold">RXP Score</p>
        </div>
      </div>

      {levels?.map((level, index) => (
        <Level
          isCurrent={currentLevel === Number(level.icon.split('_')[1])}
          key={level.id}
          icon={level.icon}
          name={level.name}
          from={level.from}
          to={levels[index + 1] ? levels[index + 1].from : '∞'}
        />
      ))}
    </div>
  );
};
