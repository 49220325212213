import { FC } from 'react';
import { Gem } from '@shared/ui';

export const Heading: FC = () => {
  return (
    <div className="text-1-8xl text-center font-semibold mb-4">
      Easy <span className="text-purple">Swap</span> Your <br /> Crystals{' '}
      <Gem size={30} /> for Dollar*
    </div>
  );
};
