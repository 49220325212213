import { FC } from 'react';

interface Properties {
  currentButtonIndex: number;
  buttons: string[];
  setCurrentButtonIndex(index: number): void;
}

export const SwitchButtons: FC<Properties> = ({
  currentButtonIndex,
  buttons,
  setCurrentButtonIndex,
}) => {
  return (
    <div className="flex w-full max-w-container mx-4">
      <div className="w-full  flex-shrink-0 overflow-hidden">
        <div className="flex bg-container-light rounded-xl">
          {buttons.map((button, index) => (
            <button
              key={button}
              style={{
                flexShrink: 0,
                flexGrow: 1,
              }}
              className={`w-[50%]
            pt-[13px] pb-[13px]
            h-[44px]
            text-base font-semibold
            flex justify-center items-center rounded-xl relative
            ${
              currentButtonIndex === index
                ? 'text-white bg-gradient-to-r from-blue-2 to-purple-2'
                : 'text-gray-light bg-transparent'
            }
            `}
              onClick={() => setCurrentButtonIndex(index)}
            >
              {button}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
