import { FC } from 'react';

interface Properties {
  width?: number;
}

const DEFAULT_SIZE = 15;

export const DollarGreenIcon: FC<Properties> = ({ width = DEFAULT_SIZE }) => {
  return (
    <svg
      width={width}
      height={width + 5}
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.23788 17.3994C5.38726 17.3994 4.60109 17.2963 3.87935 17.0901C3.15761 16.8839 2.5132 16.6003 1.94612 16.2395C1.39193 15.8786 0.927956 15.4597 0.554199 14.9829L2.02345 13.243C2.30699 13.6811 2.6743 14.0613 3.12539 14.3836C3.57648 14.6929 4.08556 14.9377 4.65264 15.1182C5.21972 15.2857 5.79969 15.3695 6.39254 15.3695C7.17872 15.3695 7.79735 15.2406 8.24844 14.9829C8.69952 14.7251 8.92507 14.3449 8.92507 13.8423C8.92507 13.52 8.79619 13.243 8.53842 13.011C8.29355 12.779 7.86179 12.5921 7.24316 12.4503L4.53664 11.8317C3.81491 11.6641 3.19627 11.4322 2.68075 11.1357C2.16522 10.8393 1.77213 10.4784 1.50148 10.0531C1.24372 9.61493 1.11483 9.11229 1.11483 8.54521C1.11483 7.7977 1.3146 7.13396 1.71413 6.55399C2.12656 5.97402 2.70652 5.51005 3.45404 5.16207C4.21444 4.81409 5.1295 4.6401 6.19922 4.6401C7.32049 4.6401 8.30644 4.83342 9.15705 5.22006C10.0077 5.60671 10.7294 6.18023 11.3223 6.94063L9.93034 8.54521C9.41482 7.88791 8.84129 7.41105 8.20977 7.11462C7.59114 6.80531 6.86296 6.65065 6.02523 6.65065C5.45815 6.65065 4.99417 6.72153 4.63331 6.86331C4.27244 6.99219 4.00179 7.17262 3.82135 7.40461C3.6538 7.6366 3.57003 7.9008 3.57003 8.19723C3.57003 8.42922 3.62158 8.64187 3.72469 8.8352C3.8278 9.01563 4.02112 9.17673 4.30466 9.3185C4.60109 9.46027 5.01995 9.5956 5.56125 9.72448L7.88113 10.2464C9.09261 10.5171 9.96257 10.9166 10.491 11.4451C11.0323 11.9606 11.3029 12.6501 11.3029 13.5136C11.3029 14.3384 11.0903 15.0408 10.665 15.6208C10.2397 16.2008 9.64036 16.6454 8.86707 16.9547C8.10667 17.2512 7.23027 17.3994 6.23788 17.3994ZM5.32927 19.1973V2.88086H7.12717V19.1973H5.32927Z"
        fill="#6DFFA8"
      />
      <path
        d="M13.4654 5.29524L14.1874 5.9912L14.866 6.90465L14.1613 7.41792L13.5002 6.48708L13.0652 5.59103L13.2653 5.44313L13.4654 5.29524ZM13.0652 5.29524L13.2653 5.44313L13.4654 5.59103L13.0304 6.48708L12.3692 7.41792L11.6646 6.90465L12.3518 5.9912L13.0652 5.29524ZM11.3775 4.37309L12.4649 4.73847L13.3436 5.19955L13.2653 5.44313L13.187 5.68672L12.2039 5.53883L11.1165 5.19955L11.3775 4.37309ZM13.7002 3.32045L13.6828 4.46009L13.5176 5.44313H13.2653H13.013L12.8477 4.46009L12.8216 3.32045H13.7002ZM15.1531 4.37309L15.414 5.19955L14.3266 5.53883L13.3436 5.68672L13.2653 5.44313L13.187 5.19955L14.0656 4.73847L15.1531 4.37309Z"
        fill="#6DFFA8"
      />
    </svg>
  );
};
