import { weiToEther } from '@shared/lib/number/format-number';
import { MedalPlace } from '@shared/ui';
import BalanceDisplay from '@shared/ui/BalanceDisplay';
import { ExchangeRate } from '@shared/ui/ExchangeRate';
import { Percent } from '@shared/ui/Percent';
import WebApp from '@twa-dev/sdk';

import { getTimeAgo } from '../../../../utils/time';
import { Token } from '../../entities/meme/type';

import ecoImg from '/images/icons/eco.svg';
import telegramImg from '/images/icons/telegram.svg';
import usersGroupImg from '/images/icons/users-group.svg';
import zipperImg from '/images/icons/zipper.svg';
export const MemeCard = ({
  token,
  profileId = -1,
  topPlace,
}: {
  token: Token;
  profileId?: number;
  topPlace?: number;
}) => {
  const tokenTime = getTimeAgo(token.createdAt);

  const shouldShowTime =
    tokenTime.includes('s') || tokenTime.includes('m') || tokenTime.includes('h');

  return (
    <div
      className={`w-full flex relative h-[76px] justify-between mb-3 px-2 py-[9px] rounded-2xl bg-gradient-to-r from-container-light to-base-container border-1 ${profileId === Number(token?.userId) ? 'border-purple' : 'border-faint-weak'}  overflow-hidden`}
    >
      {topPlace && (
        <div className="absolute left-0 top-0 w-[28px] h-[32px] flex items-center justify-center backdrop-blur-custom-blur rounded-br-xl text-gray-light text-xs font-semibold z-[1]">
          <MedalPlace place={topPlace} />
        </div>
      )}
      <div className="flex items-center gap-x-3">
        <div className="w-[50px] h-[50px] rounded-xl relative">
          <img
            className="w-[50px] h-[50px] rounded-xl object-cover"
            src={token.iconUrl}
            alt=""
          />
          <div className="absolute w-full h-full inset-0 rounded-xl border-[1px] border-white/20"></div>
        </div>
        <div className="flex flex-col items-start gap-y-1">
          <div className="flex items-center gap-x-1">
            <div className="flex items-center gap-x-1">
              <p
                className={`text-base font-semibold ${token.top ? 'bg-gradient-to-b from-[#FFFBE3]  to-[#D29A00] bg-clip-text text-transparent' : 'text-white'} `}
              >
                {token.symbol}
              </p>
              <p className={'text-sm font-semibold text-gray truncate'}>{token.name}</p>
            </div>

            {token.top && <img src={zipperImg} alt="" />}
            {shouldShowTime && (
              <div className="flex items-center ">
                <img src={ecoImg} alt="" />
                <p className="text-xs font-semibold text-success-green">{tokenTime}</p>
              </div>
            )}
          </div>
          <div className="flex items-center gap-x-3">
            <div className="w-[76px] items-center flex gap-x-[4px]">
              <p className="text-2xs text-gray-light font-semibold">MC</p>
              <div className="flex items-center">
                <div>
                  <svg
                    width="11"
                    height="15"
                    viewBox="0 0 11 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.28394 11.7976C3.70025 11.7976 3.16077 11.7268 2.66551 11.5853C2.17025 11.4438 1.72806 11.2493 1.33893 11.0017C0.958642 10.754 0.640262 10.4666 0.383789 10.1394L1.39199 8.94545C1.58656 9.24614 1.83861 9.50703 2.14814 9.72813C2.45768 9.94038 2.80701 10.1084 3.19615 10.2322C3.58528 10.3472 3.98325 10.4047 4.39007 10.4047C4.92955 10.4047 5.35405 10.3162 5.66359 10.1394C5.97313 9.96249 6.12789 9.7016 6.12789 9.35669C6.12789 9.13559 6.03946 8.94545 5.86258 8.78626C5.69454 8.62707 5.39827 8.49883 4.97377 8.40155L3.11655 7.97704C2.62129 7.86207 2.19679 7.70288 1.84303 7.49947C1.48928 7.29606 1.21954 7.04843 1.03381 6.75659C0.856937 6.45589 0.768498 6.11098 0.768498 5.72185C0.768498 5.2089 0.905578 4.75344 1.17974 4.35547C1.46274 3.95749 1.86072 3.63911 2.37366 3.40033C2.89545 3.16154 3.52337 3.04215 4.25741 3.04215C5.02683 3.04215 5.70339 3.17481 6.28708 3.44013C6.87078 3.70544 7.36604 4.099 7.77286 4.62079L6.81772 5.72185C6.46396 5.27081 6.07041 4.94359 5.63706 4.74018C5.21255 4.52792 4.71287 4.4218 4.13802 4.4218C3.74889 4.4218 3.43051 4.47044 3.18288 4.56772C2.93525 4.65616 2.74953 4.77998 2.62571 4.93917C2.51074 5.09836 2.45326 5.27966 2.45326 5.48306C2.45326 5.64225 2.48863 5.78818 2.55939 5.92084C2.63014 6.04465 2.76279 6.1552 2.95736 6.25248C3.16077 6.34977 3.4482 6.44263 3.81964 6.53107L5.41154 6.88924C6.24287 7.07496 6.83983 7.34913 7.20243 7.71172C7.57387 8.06548 7.75959 8.53863 7.75959 9.13117C7.75959 9.69718 7.61367 10.1792 7.32182 10.5771C7.02997 10.9751 6.61873 11.2802 6.0881 11.4925C5.56631 11.6959 4.96492 11.7976 4.28394 11.7976ZM3.66045 13.0313V1.83496H4.89417V13.0313H3.66045Z"
                      fill="white"
                    />
                    <path
                      d="M9.24345 3.49171L9.73893 3.96928L10.2046 4.5961L9.72102 4.9483L9.26733 4.30955L8.96885 3.69468L9.10615 3.5932L9.24345 3.49171ZM8.96885 3.49171L9.10615 3.5932L9.24345 3.69468L8.94497 4.30955L8.49128 4.9483L8.00774 4.5961L8.47934 3.96928L8.96885 3.49171ZM7.81074 2.85893L8.55694 3.10966L9.15987 3.42605L9.10615 3.5932L9.05242 3.76035L8.37785 3.65886L7.63165 3.42605L7.81074 2.85893ZM9.40463 2.13661L9.39269 2.91863L9.27927 3.5932H9.10615H8.93303L8.8196 2.91863L8.8017 2.13661H9.40463ZM10.4016 2.85893L10.5806 3.42605L9.83444 3.65886L9.15987 3.76035L9.10615 3.5932L9.05242 3.42605L9.65535 3.10966L10.4016 2.85893Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <p className="text-2xs text-white font-semibold">
                  <BalanceDisplay
                    balance={Number(weiToEther(token.marketCap))}
                    big
                    useCondensedFormat
                  />
                </p>
              </div>
            </div>
            <div className="flex items-center gap-x-2">
              <img className="w-[12px] h-[12px]" src={usersGroupImg} alt="" />
              <p className="text-2xs font-semibold">
                <BalanceDisplay balance={token.holders} big />
              </p>
            </div>
            {token.tgChannel && (
              <img
                className="w-6 h-6"
                src={telegramImg}
                alt="Tg"
                onClick={(event) => {
                  event.preventDefault();
                  WebApp.openTelegramLink(token.tgChannel);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-end gap-y-2">
        <Percent value={Number(token.percents?.value)} upDown={token.upDown} />
        <ExchangeRate value={Number(token.exchangeRate)} />
      </div>
    </div>
  );
};
