import { useEffect, useMemo, useRef, useState } from 'react';
import { formatNumber, formatWithSeparators } from '@shared/lib/number/format-number';

type Properties = {
  balance: number;
  symbol?: boolean;
  big?: boolean;
  disableFrame?: boolean;
  f?: number;
  useCondensedFormat?: boolean;
};

const extractAndModifyBalance = (balance: string): string => {
  const balanceString = balance.toString();

  const match = balanceString.match(/\((\d+)\)/);
  if (match) {
    const numberInParentheses = match[1];
    const lowerIndexNumber = numberInParentheses
      .split('')
      .map((digit) => `<sub>${digit}</sub>`)
      .join('');

    return balanceString.replace(`(${numberInParentheses})`, lowerIndexNumber);
  }

  return balanceString;
};

const BalanceDisplay: React.FC<Properties> = ({
  balance,
  symbol,
  big,
  disableFrame = true,
  f = 2,
  useCondensedFormat = false,
}) => {
  const [current, setCurrent] = useState(balance);
  const lastTimeReference = useRef(performance.now());
  const deltaTimeReference = useRef(0);
  const frameReference = useRef<number | null>(null);
  const currentReference = useRef(current);
  const threshold = 0.001;

  useEffect(() => {
    if (disableFrame) {
      setCurrent(balance);
      return;
    }

    const onFrame = (dtime: number): void => {
      const delta = currentReference.current - balance;
      let newCurrent = currentReference.current;

      if (Math.abs(delta) < threshold) {
        cancelAnimationFrame(frameReference.current!);
        return;
      }

      newCurrent -= Math.abs(delta) > 2 ? delta * dtime * 0.01 : delta;

      if (Math.abs(newCurrent - currentReference.current) > threshold) {
        currentReference.current = newCurrent;
        setCurrent(newCurrent);
      }
    };

    if (balance !== currentReference.current) {
      const loop = (time: number): void => {
        frameReference.current = requestAnimationFrame(loop);

        deltaTimeReference.current = time - lastTimeReference.current;
        lastTimeReference.current = time;

        onFrame(deltaTimeReference.current);
      };

      frameReference.current = requestAnimationFrame(loop);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (frameReference.current !== null) {
        cancelAnimationFrame(frameReference.current);
      }
    };
  }, [balance, disableFrame]);

  const formattedBalance = useMemo(() => {
    const formatted = big
      ? formatNumber(current, big, undefined, useCondensedFormat)
      : formatWithSeparators(current, f);
    const prefix = symbol ? (current < 0 ? '-' : '+') : '';
    const modifiedBalance = extractAndModifyBalance(formatted);
    return prefix + modifiedBalance;
  }, [current, symbol, big, f, useCondensedFormat]);

  return <span dangerouslySetInnerHTML={{ __html: formattedBalance }} />;
};

export default BalanceDisplay;
