import React from 'react';

import tokenLiqImg from '/images/icons/portfolio-nav.svg';
import portfolioGray from '/images/icons/portfolioGray.svg';

interface Properties {
  isActive?: boolean;
}

export const PortfolioIcon: React.FC<Properties> = ({ isActive }) => {
  return (
    <div>
      {isActive ? <img src={tokenLiqImg} alt="" /> : <img src={portfolioGray} alt="" />}
    </div>
  );
};
