import { FC } from 'react';
import { Button } from '@components/index';
import { MeAPI } from '@entities/me/api';
import { Multiplicator3 } from '@shared/icons/Multiplicators/3';
import { Multiplicator4 } from '@shared/icons/Multiplicators/4';
import { Multiplicator5 } from '@shared/icons/Multiplicators/5';
import { DoubleGreenUp } from '@shared/ui';
import BalanceDisplay from '@shared/ui/BalanceDisplay';
import { Gem } from '@shared/ui/Gem';
import { useQuery } from '@tanstack/react-query';

import okHand from '/images/icons/ok-hand.png';
import starImg from '/images/icons/star.svg';

export interface skin {
  id: number;
  img: string;
  multiplicator: number;
  title: string;
  lvl: string;
  boost: string;
  stars: number;
  multiplication: number;
  expandSkin(id: number): void;
}

const getMultiplicator = (multiplicator: number): JSX.Element => {
  const hash = {
    3: <Multiplicator3 />,
    4: <Multiplicator4 />,
    5: <Multiplicator5 />,
  };
  // eslint-disable-next-line security/detect-object-injection
  return hash[multiplicator] ?? hash[0];
};

export const Skin: FC<skin> = ({
  id,
  img,
  multiplicator,
  lvl,
  boost,
  stars,
  multiplication,
  expandSkin,
}) => {
  const profile = useQuery(MeAPI.getMeQueryOptions());

  const isPurchased = profile.data.level.currentUserSkinIndex + 1 >= id;

  return (
    <div
      className="flex items-center border-1 border-solid border-faint-weak
    bg-gradient-to-r from-container-light to-base-container rounded-2-5xl p-2"
    >
      <div className="relative h-[152px]">
        <img src={img} className="w-[163px] h-[152px]" />

        <div className="absolute bottom-[15px] left-[15px]">
          {getMultiplicator(multiplicator)}
        </div>
      </div>
      <div className="h-full flex flex-col gap-y-3">
        <div className="text-lg font-semibold flex items-center">
          {id} Skin <div className="w-[3px]"></div>
          <span className="text-gray">{lvl}Lvl</span>
          <div className="w-[3px]"></div>
          <div className="relative">
            <Gem size={20} />
            <div className="absolute bottom-2 left-4">
              <DoubleGreenUp />
            </div>
          </div>
        </div>

        <p className="text-xs font-semibold">
          <span className="text-gray">Unlimitted</span> x{multiplication} Mining Boost{' '}
          <br />
          <span className="text-gray">1h Mining from </span>
          <BalanceDisplay balance={Number(boost)} big />
        </p>
        {isPurchased ? (
          <Button className="text-sm" variant="faint-weak" rounded="full">
            <img className="w-[17px] h-[17px]" src={okHand} alt="ok hand" />
            Purchased
          </Button>
        ) : (
          <Button variant="accent-strong" rounded="full" onClick={() => expandSkin(id)}>
            <img src={starImg} />
            <BalanceDisplay balance={stars} f={0} />
          </Button>
        )}
      </div>
    </div>
  );
};
