import { FC } from 'react';

interface Properties {
  width?: number;
  height?: number;
}

const DEFAULT_SIZE = 11;

export const DollarBaseIcon: FC<Properties> = ({
  width = DEFAULT_SIZE,
  height = DEFAULT_SIZE,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.28297 11.6111C3.69927 11.6111 3.15979 11.5403 2.66454 11.3988C2.16928 11.2573 1.72708 11.0628 1.33795 10.8151C0.957665 10.5675 0.639285 10.2801 0.382812 9.95285L1.39102 8.75892C1.58558 9.05962 1.83763 9.32051 2.14717 9.54161C2.4567 9.75386 2.80604 9.9219 3.19517 10.0457C3.5843 10.1607 3.98228 10.2182 4.38909 10.2182C4.92857 10.2182 5.35308 10.1297 5.66261 9.95285C5.97215 9.77597 6.12692 9.51508 6.12692 9.17016C6.12692 8.94907 6.03848 8.75892 5.8616 8.59973C5.69357 8.44054 5.3973 8.31231 4.97279 8.21502L3.11557 7.79052C2.62032 7.67555 2.19581 7.51636 1.84205 7.31295C1.4883 7.10954 1.21856 6.86191 1.03284 6.57006C0.855961 6.26937 0.767522 5.92446 0.767522 5.53533C0.767522 5.02238 0.904602 4.56692 1.17876 4.16895C1.46177 3.77097 1.85974 3.45259 2.37269 3.21381C2.89448 2.97502 3.52239 2.85563 4.25644 2.85563C5.02585 2.85563 5.70241 2.98829 6.28611 3.2536C6.8698 3.51892 7.36506 3.91247 7.77188 4.43426L6.81674 5.53533C6.46299 5.08429 6.06943 4.75706 5.63608 4.55365C5.21158 4.3414 4.7119 4.23527 4.13704 4.23527C3.74791 4.23527 3.42953 4.28392 3.1819 4.3812C2.93427 4.46964 2.74855 4.59345 2.62474 4.75264C2.50977 4.91183 2.45228 5.09313 2.45228 5.29654C2.45228 5.45573 2.48766 5.60166 2.55841 5.73431C2.62916 5.85813 2.76182 5.96868 2.95638 6.06596C3.15979 6.16324 3.44722 6.2561 3.81866 6.34454L5.41056 6.70272C6.24189 6.88844 6.83885 7.1626 7.20145 7.5252C7.57289 7.87896 7.75862 8.3521 7.75862 8.94465C7.75862 9.51065 7.61269 9.99265 7.32084 10.3906C7.029 10.7886 6.61775 11.0937 6.08712 11.306C5.56533 11.5094 4.96395 11.6111 4.28297 11.6111ZM3.65947 12.8448V1.64844H4.8932V12.8448H3.65947Z"
        fill="#AEB0B7"
      />
      <path
        d="M9.24247 3.30519L9.73795 3.78276L10.2036 4.40957L9.72004 4.76178L9.26635 4.12303L8.96787 3.50816L9.10517 3.40667L9.24247 3.30519ZM8.96787 3.30519L9.10517 3.40667L9.24247 3.50816L8.94399 4.12303L8.4903 4.76178L8.00676 4.40957L8.47836 3.78276L8.96787 3.30519ZM7.80976 2.67241L8.55596 2.92314L9.1589 3.23953L9.10517 3.40667L9.05144 3.57382L8.37688 3.47234L7.63067 3.23953L7.80976 2.67241ZM9.40365 1.95009L9.39171 2.73211L9.27829 3.40667H9.10517H8.93205L8.81863 2.73211L8.80072 1.95009H9.40365ZM10.4006 2.67241L10.5797 3.23953L9.83346 3.47234L9.1589 3.57382L9.10517 3.40667L9.05144 3.23953L9.65438 2.92314L10.4006 2.67241Z"
        fill="#AEB0B7"
      />
    </svg>
  );
};
