import { FC } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Header } from '@widgets/Header';
import { NavBarDex } from '@widgets/NavBarDex';

export const DexLayout: FC = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const isHeader = pathname.includes('launch');

  return (
    <div className="mb-[96px]">
      <Header display={isHeader ? 'hidden' : 'flex'} />
      <div>
        <Outlet />
      </div>
      <NavBarDex />
    </div>
  );
};
