import { clientAPI } from '@shared/api/client';
import { queryOptions } from '@tanstack/react-query';

import { ITask, TaskWrap } from '../type';

export const TasksAPI = {
  getTasksList: () => clientAPI('game/task').json<TaskWrap[]>(),

  getTasksListQueryOptions: () => {
    return queryOptions({
      queryKey: ['tasks_list'],
      queryFn: () => TasksAPI.getTasksList(),
    });
  },

  makeClaim: (id: string) => clientAPI(`game/task/claim?id=${id}`).json<ITask>(),
} as const;
