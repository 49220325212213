import { FC } from 'react';
import { DollarBaseIcon } from '@shared/icons/DollarBase';
import { formatNumber, weiToEther } from '@shared/lib/number/format-number';
import BalanceDisplay from '@shared/ui/BalanceDisplay';
import { Token } from '@widgets/Dex/entities/meme/type';

const calcClass = (value: number, upDown: string) => {
  if (value === 0) {
    return 'text-gray-light';
  }
  return upDown === 'UP' ? 'text-success-strong' : 'text-critic-strong-up';
};

const upOrDown = (value: number, upDown: string) => {
  if (value === 0) return `${formatNumber(value, true)}`;

  return upDown === 'UP'
    ? `+${formatNumber(value, true)}`
    : `-${formatNumber(value, true)}`;
};

export const PortfolioToken: FC<{
  tokenData: Token;
  isUserCreate: boolean;
}> = ({ tokenData, isUserCreate }) => {
  return (
    <div
      className={`w-full flex max-w-container mx-4 justify-between mt-3 px-4 py-3 rounded-[20px] border-1 ${isUserCreate ? 'border-purple bg-container-light' : 'bg-gradient-to-r from-[#24252D] to-[#1A1B22] border-faint-weak'}`}
    >
      <div className="flex gap-x-3 item-center">
        <img
          className="w-[42px] h-[42px] rounded-full object-cover"
          src={tokenData.iconUrl}
          alt=""
        />

        <div className="flex flex-col items-start">
          <p className="text-[15px] text-white font-semibold flex items-center gap-1">
            <span>
              <BalanceDisplay balance={Number(weiToEther(tokenData.balance.value))} big />
            </span>
            <span className="text-accent-strong-up">{tokenData.symbol}</span>
          </p>
          <div className="flex items-center gap-0.5 font-bold text-2xs  rounded-2-5xl">
            <DollarBaseIcon width={12} height={17} />{' '}
            <BalanceDisplay
              balance={
                Number(weiToEther(tokenData?.balance?.value)) *
                Number(tokenData.exchangeRate)
              }
              big
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-end">
        <p
          className={`text-[15px] font-bold ${calcClass(Number(tokenData.percents?.value), tokenData.upDown)}`}
        >
          {upOrDown(Number(tokenData.percents?.value), tokenData.upDown)}%
        </p>
        <div className="flex items-center justify-center text-gray-light font-medium text-2xs  rounded-2-5xl">
          <DollarBaseIcon width={10} height={15} />{' '}
          <BalanceDisplay
            balance={Number(tokenData.exchangeRate)}
            big
            useCondensedFormat
          />
        </div>
      </div>
    </div>
  );
};
