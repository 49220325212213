import { FC, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { MeAPI } from '@entities/me/api';
import { SKINS } from '@pages/Profile/Index/ui/consts';
import { weiToEther } from '@shared/lib/number/format-number';
import BalanceDisplay from '@shared/ui/BalanceDisplay';
import { Gem } from '@shared/ui/Gem';
import { useQuery } from '@tanstack/react-query';
import { Avatar } from '@widgets/Header/ui/Avatar';

import { BadgeHeader } from './ui/Badge';

export const Header: FC<{ display?: string }> = ({ display = 'flex' }) => {
  const styleTitle = 'text-gray-light text-xs font-semibol';
  const styleValue = 'font-semibold text-base flex leading-[19px] items-center h-max';

  const [percent, setPercent] = useState(0);
  const location = useLocation();

  const {
    data: profile,
    isLoading: profileLoading,
    isError: profileError,
  } = useQuery(MeAPI.getMeQueryOptions());

  const {
    data: portfolio,
    isLoading: portfolioLoading,
    isError: portfolioError,
  } = useQuery(MeAPI.getUserPortfolioQueryOptions());

  const skinCoefficient =
    SKINS[(profile?.level.currentUserSkinIndex + 1) as keyof typeof SKINS]?.boost || 0;

  useEffect(() => {
    if (profile && profile.level) {
      const currentLevel = profile.level.currentUserLevel;
      const nextLevel = profile.level.nextUserLevel;

      const nextLevelDifference = nextLevel ? nextLevel.from - currentLevel.from : 0;

      const remainingDifference = nextLevel
        ? nextLevel.from - Number(profile.experience)
        : 0;

      const calculatedPercent =
        profile.level.currentUserLevel.id < 20
          ? nextLevelDifference
            ? (1 - remainingDifference / nextLevelDifference) * 100
            : 0
          : 100;
      setPercent(calculatedPercent);
    }
  }, [profile]);

  if (profileLoading || profileError || portfolioLoading || portfolioError)
    return <div>Loading...</div>;

  return (
    <div
      className={`${display} item-center px-4 pb-2 m-auto mb-3 sticky top-0 bg-base-background z-[3]
        `}
    >
      <NavLink to="/profile" className="mr-3">
        <Avatar
          level={profile.level.currentUserLevelIndex + 1}
          progress={percent}
          isBorder={location.pathname === '/profile'}
        />
      </NavLink>
      <div className="w-full flex items-center gap-x-6 h-[48px] pt-[8px]">
        <div className="flex flex-col justify-between w-[72px] h-full">
          <div className={`flex items-center relative w-12 ${styleTitle}`}>
            Profit
            <BadgeHeader boost={skinCoefficient} />
          </div>
          <div className={styleValue}>
            <Gem />+
            <BalanceDisplay
              balance={Number(profile.mining.hourReward + profile.mining.skinReward)}
              big
            />
          </div>
        </div>

        <div className="flex flex-col justify-between h-full">
          <div className={styleTitle}>Balance</div>
          <div className={styleValue}>
            <Gem />
            <BalanceDisplay balance={Number(profile.balance)} big />
          </div>
        </div>
        <div className="flex flex-col justify-between h-full">
          <div className={styleTitle}>Meme Portfolio</div>
          <div className={styleValue}>
            <div>
              <svg
                width="13"
                height="14"
                viewBox="0 0 13 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.69529 12.0436C4.01027 12.0436 3.37716 11.9606 2.79594 11.7945C2.21471 11.6285 1.69577 11.4001 1.23909 11.1095C0.792796 10.8189 0.419154 10.4816 0.118164 10.0976L1.30137 8.69643C1.5297 9.04931 1.8255 9.35549 2.18877 9.61497C2.55203 9.86406 2.962 10.0613 3.41867 10.2066C3.87535 10.3415 4.3424 10.409 4.81983 10.409C5.45295 10.409 5.95114 10.3052 6.3144 10.0976C6.67767 9.89001 6.8593 9.58383 6.8593 9.17905C6.8593 8.91958 6.75551 8.69643 6.54793 8.50961C6.35073 8.32279 6.00303 8.17229 5.50484 8.05812L3.32526 7.55993C2.74404 7.42501 2.24585 7.23819 1.83069 6.99947C1.41553 6.76075 1.09898 6.47014 0.881018 6.12764C0.673438 5.77475 0.569649 5.36997 0.569649 4.9133C0.569649 4.31132 0.730523 3.7768 1.05227 3.30975C1.3844 2.8427 1.85145 2.46905 2.45343 2.18882C3.06579 1.90859 3.80269 1.76847 4.66415 1.76847C5.56712 1.76847 6.36111 1.92416 7.04612 2.23553C7.73113 2.5469 8.31235 3.00876 8.78979 3.62112L7.66886 4.9133C7.2537 4.38397 6.79184 3.99995 6.28327 3.76123C5.78508 3.51214 5.19867 3.38759 4.52403 3.38759C4.06736 3.38759 3.69372 3.44468 3.40311 3.55884C3.11249 3.66263 2.89454 3.80794 2.74923 3.99476C2.6143 4.18158 2.54684 4.39435 2.54684 4.63307C2.54684 4.81989 2.58836 4.99114 2.67139 5.14682C2.75442 5.29213 2.9101 5.42187 3.13844 5.53604C3.37716 5.6502 3.71447 5.75918 4.15039 5.86297L6.0186 6.28332C6.99423 6.50128 7.69481 6.82303 8.12034 7.24856C8.55626 7.66372 8.77422 8.219 8.77422 8.91439C8.77422 9.57864 8.60296 10.1443 8.26046 10.6113C7.91795 11.0784 7.43533 11.4365 6.81259 11.6856C6.20023 11.9243 5.49447 12.0436 4.69529 12.0436ZM3.96357 13.4915V0.351746H5.41143V13.4915H3.96357Z"
                  fill="#6DFFA8"
                />
                <path
                  d="M10.5156 2.29607L11.0971 2.85653L11.6436 3.59214L11.0761 4.00548L10.5437 3.25586L10.1934 2.53427L10.3545 2.41517L10.5156 2.29607ZM10.1934 2.29607L10.3545 2.41517L10.5156 2.53427L10.1653 3.25586L9.6329 4.00548L9.06543 3.59214L9.61889 2.85653L10.1934 2.29607ZM8.83424 1.55345L9.70997 1.8477L10.4176 2.21901L10.3545 2.41517L10.2914 2.61133L9.49979 2.49223L8.62407 2.21901L8.83424 1.55345ZM10.7048 0.705753L10.6908 1.62351L10.5577 2.41517H10.3545H10.1513L10.0182 1.62351L9.9972 0.705753H10.7048ZM11.8748 1.55345L12.0849 2.21901L11.2092 2.49223L10.4176 2.61133L10.3545 2.41517L10.2914 2.21901L10.999 1.8477L11.8748 1.55345Z"
                  fill="#6DFFA8"
                />
              </svg>
            </div>
            <BalanceDisplay
              balance={Number(weiToEther(portfolio.totalMemeBalance))}
              big
            />
          </div>
        </div>
      </div>
    </div>
  );
};
