import { FC } from 'react';
import toast from 'react-hot-toast';
import { Button } from '@components/index';
import { MeAPI } from '@entities/me/api';
import { useQuery } from '@tanstack/react-query';
import WebApp from '@twa-dev/sdk';

import copyImg from '/images/icons/copy.svg';

const inviteFriend = (inviteLink: string): void => {
  WebApp.HapticFeedback.notificationOccurred('success');
  WebApp.openTelegramLink(`https://t.me/share/url?url=${inviteLink}`);
};

const copyLink = (inviteLink: string) => {
  if (inviteLink) {
    return navigator.clipboard
      .writeText(inviteLink)
      .then(() => {
        WebApp.HapticFeedback.notificationOccurred('success');
        toast.success('You invite link copied to clipboard');
        return 1;
      })
      .catch((error) => {
        toast.error('Failed to copy the invite link');
        throw error;
      });
  } else {
    toast.error('Invite link is unavailable');
    return null;
  }
};

export const InviteButtons: FC = () => {
  const { data: profile } = useQuery(MeAPI.getMeQueryOptions());

  const inviteLink = profile?.inviteLink;
  return (
    <div className="flex gap-1 w-full m-auto">
      <div className="invite-friend-button-container h-[56px]">
        <Button onClick={() => inviteFriend(inviteLink)}>Invite a friend</Button>
      </div>
      <div className="w-[60px] h-[56px]">
        <Button onClick={() => copyLink(inviteLink)}>
          <img src={copyImg} alt="Copy" />
        </Button>
      </div>
    </div>
  );
};
