const styles = `
  .base {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 9px;
    font-size: 14px;
    font-weight: 700;
    line-height: 120%;
  }

  .integer {
    font-size: 20px !important;
  }

  .d-1 {
    border: 2px solid rgba(255, 255, 255, 0.23);
    background: linear-gradient(124deg, #ff8425 4.02%, #c33fe6 34.64%, #7000c5 74.08%);
  }

  .d-2 {
    border: 2px solid rgba(255, 255, 255, 0.23);
    background: linear-gradient(124deg, #9fffb4 4.02%, #26be00 34.64%, #008849 74.08%);
  }

  .d-3 {
    border: 2px solid rgba(255, 255, 255, 0.23);
    background: linear-gradient(124deg, #6893ff 4.02%, #3f47e6 34.64%, #0700c5 74.08%);
  }

  .d-4 {
    border: 2px solid rgba(255, 255, 255, 0.23);
    background: linear-gradient(124deg, #ffcc92 4.02%, #fd7600 34.64%, #a90000 107.96%);
  }

  .d-5 {
    border: 2px solid rgba(255, 255, 255, 0.23);
    background: linear-gradient(124deg, #fff6c0 4.02%, #fffefd 18.99%, #ffb83d 29.64%, #87541a 49.59%, #885223 76.7%, #cfa156 96.22%);
  }
`;

export const Boost = ({ boost }: { boost: number }) => {
  const boostClass = `base d-${Math.trunc(boost)} ${Number.isInteger(boost) ? 'integer' : ''}`;

  return (
    <>
      <style>{styles}</style>
      <div className={boostClass}>
        <p>x{boost}</p>
      </div>
    </>
  );
};
