import { FC } from 'react';

interface Properties {
  percent: number;
  text: string[];
}

export const PercentCell: FC<Properties> = ({ percent, text }) => {
  return (
    <div
      className="flex flex-col items-center justify-center py-3.5 px-4
    border-1 border-solid border-faint-weak rounded-2-5xl"
    >
      <p className="font-semibold ">
        <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-2 to-purple-2 block">
          <span className="text-3-5xl font-bold">{percent}</span>%
        </span>
      </p>
      <p className="text-xs text-center font-semibold text-gray-light">
        {text[0]} <span className="text-white">{text[1]}</span> {text[2]}
      </p>
    </div>
  );
};
