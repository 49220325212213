import { FC } from 'react';

export const Roadmap: FC = () => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.28451 2.00065H5.95117V0.333984H4.28451V2.00065ZM7.61784 2.00065V0.333984H9.28451V2.00065H7.61784ZM4.28451 8.66732V7.00065H5.95117V8.66732H4.28451ZM10.9512 5.33398V3.66732H12.6178V5.33398H10.9512ZM10.9512 8.66732V7.00065H12.6178V8.66732H10.9512ZM7.61784 8.66732V7.00065H9.28451V8.66732H7.61784ZM10.9512 2.00065V0.333984H12.6178V2.00065H10.9512ZM5.95117 3.66732V2.00065H7.61784V3.66732H5.95117ZM0.951172 13.6673V0.333984H2.61784V2.00065H4.28451V3.66732H2.61784V5.33398H4.28451V7.00065H2.61784V13.6673H0.951172ZM9.28451 7.00065V5.33398H10.9512V7.00065H9.28451ZM5.95117 7.00065V5.33398H7.61784V7.00065H5.95117ZM4.28451 5.33398V3.66732H5.95117V5.33398H4.28451ZM7.61784 5.33398V3.66732H9.28451V5.33398H7.61784ZM9.28451 3.66732V2.00065H10.9512V3.66732H9.28451Z"
        fill="url(#paint0_linear_8899_28550)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8899_28550"
          x1="3.77809"
          y1="0.333986"
          x2="14.9484"
          y2="5.67441"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#587BFF" />
          <stop offset="1" stopColor="#B518FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
