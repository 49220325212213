import { FC } from 'react';

interface Properties {
  width?: number;
  height?: number;
}

const DEFAULT_SIZE = 50;

export const Ton: FC<Properties> = ({ width = DEFAULT_SIZE, height = DEFAULT_SIZE }) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 50 50'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8035_17112)">
      <path
        d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
        fill="#0098EA"
      />
      <path
        d="M33.5356 13.959H16.4627C13.3236 13.959 11.3339 17.3451 12.9132 20.0826L23.45 38.3458C24.1376 39.5383 25.8607 39.5383 26.5483 38.3458L37.0872 20.0826C38.6644 17.3495 36.6747 13.959 33.5378 13.959H33.5356ZM23.4414 32.8689L21.1467 28.4277L15.6097 18.5248C15.2445 17.8909 15.6956 17.0787 16.4605 17.0787H23.4393V32.871L23.4414 32.8689ZM34.3843 18.5226L28.8495 28.4299L26.5547 32.8689V17.0766H33.5335C34.2984 17.0766 34.7495 17.8888 34.3843 18.5226Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_8035_17112">
        <rect width="50" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
