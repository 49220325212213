import React from 'react';

import launchGray from '/images/icons/launchGray.svg';
import tokenLiqImg from '/images/icons/unicorn-nav.svg';

interface Properties {
  isActive?: boolean;
}

export const LaunchIcon: React.FC<Properties> = ({ isActive }) => {
  return (
    <div>
      {isActive ? <img src={tokenLiqImg} alt="" /> : <img src={launchGray} alt="" />}
    </div>
  );
};
