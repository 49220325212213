import { clientAPI } from '@shared/api/client.ts';
import WebApp from '@twa-dev/sdk';

export const AuthAPI = {
  postLogin: () =>
    clientAPI
      .post('auth/login', {
        json: {
          authorization: `tma ${WebApp.initData}`,
        },
      })
      .json<{ accessToken: string }>(),
};
