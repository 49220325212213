import { BigNumber } from 'bignumber.js';
import Decimal from 'decimal.js';

// eslint-disable-next-line sonarjs/cognitive-complexity
function decimalFormat(number_: number, useCondensedFormat: boolean = false): string {
  const valueString = number_.toFixed(15).replace(/0+$/, '');
  const [integerPart, decimalPart] = valueString.split('.');
  if (!decimalPart || /^0*$/.test(decimalPart)) {
    return integerPart;
  }

  if (useCondensedFormat && integerPart === '0') {
    const match = decimalPart.match(/^(0+)(\d+)/);
    if (match) {
      const leadingZeros = match[1].length;
      const significantDigits = match[2].slice(0, 2);

      if (leadingZeros >= 4) {
        return `0.0(${leadingZeros - 1})${significantDigits}`;
      }
    }
  }

  if (integerPart === '0') {
    const significantDigits = decimalPart.match(/^(0*)(\d{1,2})/);
    if (significantDigits) {
      return `0.${significantDigits[1]}${significantDigits[2]}`;
    }
  } else {
    const trimmedDecimalPart = decimalPart.replace(/0*$/, '');
    if (trimmedDecimalPart.length === 0) {
      return integerPart;
    }

    if (trimmedDecimalPart[0] === '0') {
      return `${integerPart}`;
    }

    return `${integerPart}.${trimmedDecimalPart[0]}`;
  }

  return number_.toString();
}

export function formatNumber(
  number_: number,
  big = false,
  roundedFrom_?: number,
  useCondensedFormat: boolean = false,
): string {
  if (Number.isNaN(number_)) {
    return '0';
  }

  const isNegative = number_ < 0;
  const absoluteNumber = Math.abs(number_);
  const rounded = roundedFrom_ || 1000;

  if (big && absoluteNumber >= rounded) {
    const units = [
      '',
      'k',
      'm',
      'b',
      't',
      'q',
      'Q',
      's',
      'S',
      'o',
      'n',
      'd',
      'D',
      'u',
      'U',
      'v',
      'V',
      'N',
      'j',
      'J',
      'L',
      'Qe',
      'Nq',
      'Qu',
      'Se',
      'Ot',
    ];
    const magnitude = Math.floor(Math.log10(absoluteNumber) / 3);
    const divisor = Math.pow(1000, magnitude);
    const shortNumber = Math.floor((absoluteNumber / divisor) * 10) / 10;

    return `${isNegative ? '-' : ''}${shortNumber}${units[magnitude]}`;
  }

  return `${isNegative ? '-' : ''}${decimalFormat(absoluteNumber, useCondensedFormat)}`;
}

export function convertToSubscript(input: string): string {
  const subscriptMap: { [key: string]: string } = {
    '2': '₂',
    '3': '₃',
    '4': '₄',
    '5': '₅',
    '6': '₆',
    '7': '₇',
    '8': '₈',
    '9': '₉',
  };

  const match = input.match(/\((\d+)\)/);

  if (match) {
    const numberInBrackets = match[1];

    const subscript = numberInBrackets
      .split('')
      .map((char) => {
        return char === '0' || char === '1' ? '' : subscriptMap[char] || char;
      })
      .join('');

    return input.replace(`(${numberInBrackets})`, subscript);
  }

  return input;
}

export function formatWithSeparators(number: number, f: number): string {
  const [integerPart, decimalPart] = Math.abs(number).toFixed(f).split('.');

  const formattedInteger =
    [...integerPart]
      .reverse()
      .join('')
      .match(/.{1,3}/g)
      ?.join(',')
      .split('')
      .reverse()
      .join('') ?? integerPart;

  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
}

export function etherToWei(etherValue: string | number): string {
  return new BigNumber(etherValue).multipliedBy('1e18').toFixed(18);
}

export function weiToEther(weiValue: string | number): string {
  return new BigNumber(weiValue).dividedBy('1e18').toFixed(18);
}

export const decimalToBigInt = (decimalValue: Decimal): bigint => {
  const roundedValue = decimalValue.toNearest(1);

  if (!roundedValue.isFinite()) {
    throw new Error('Cannot convert infinite value to BigInt');
  }

  return BigInt(roundedValue.toFixed(0));
};

export const bigIntToDecimal = (bigint: bigint): Decimal => {
  return new Decimal(bigint.toString());
};

export function formatDecimal(decimal: Decimal, maxPrecision: number = 200): string {
  if (!decimal || typeof decimal.toFixed !== 'function') {
    throw new Error('Invalid decimal input');
  }

  if (!Number.isInteger(maxPrecision) || maxPrecision < 0) {
    throw new Error('Invalid precision value');
  }

  const result = decimal.toFixed(maxPrecision);

  return result.replace(/\.?0+(?=$)/, '');
}
