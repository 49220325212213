import { FC } from 'react';

export const DoubleGreenUp: FC = () => (
  <svg
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99935 4.66797L3.33268 3.25888L0.666016 4.66797L3.33268 0.667969L5.99935 4.66797Z"
      fill="#6DFFA8"
    />
    <path
      d="M5.99935 9.33398L3.33268 7.92489L0.666016 9.33398L3.33268 5.33398L5.99935 9.33398Z"
      fill="#6DFFA8"
    />
  </svg>
);
