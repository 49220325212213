import { DollarWhiteIcon } from '@shared/icons/DollarWhite';

import BalanceDisplay from '../BalanceDisplay';

interface Properties {
  value: number;
}

export const ExchangeRate = ({ value }: Properties) => {
  return (
    <div className="flex items-center text-xs gap-1 bg-faint-weak py-1 px-2 rounded-2-5xl py-[3px] leading-[16.8px]">
      <DollarWhiteIcon width={11} />
      <p className="text-xs  font-medium">
        <BalanceDisplay balance={value} big useCondensedFormat />
      </p>
    </div>
  );
};
