import React from 'react';

import homeImg from '/images/icons/home.svg';
import homeGray from '/images/icons/homeGray.svg';

interface Properties {
  isActive?: boolean;
}

export const HomeIcon: React.FC<Properties> = ({ isActive }) => {
  return (
    <div>{isActive ? <img src={homeImg} alt="" /> : <img src={homeGray} alt="" />}</div>
  );
};
