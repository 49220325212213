import { FC } from 'react';

export const Multiplicator4: FC = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_8374_35648)">
        <rect
          width="40.0024"
          height="39.9967"
          rx="9"
          transform="matrix(0.999941 -7.83693e-10 0.0104328 1.00003 0 0)"
          fill="url(#paint0_linear_8374_35648)"
        />
        <rect
          x="1.01037"
          y="1.00003"
          width="38.0024"
          height="37.9967"
          rx="8"
          transform="matrix(0.999941 -7.83693e-10 0.0104328 1.00003 -0.0103731 -2.8491e-05)"
          stroke="white"
          strokeOpacity="0.23"
          strokeWidth="2"
        />
        <path
          d="M12.2174 15.9985L14.7299 20.5451L15.8604 20.4441L19.8999 26.1021L15.9598 26.1021L13.3659 21.4948L12.1737 21.4948L8.25716 15.9985L12.2174 15.9985ZM19.7137 15.9985L15.9518 21.4544L14.678 21.3736L12.0804 26.1021L8.22113 26.1021L12.223 20.4037L13.3966 20.5653L15.8544 15.9985L19.7137 15.9985ZM27.3935 26.1021L27.3629 23.1721L20.8567 23.1721L20.8339 20.9897C20.9529 20.7741 21.1051 20.5114 21.2905 20.2016C21.4893 19.8917 21.7213 19.5347 21.9865 19.1306L26.2642 12.7653L30.3255 12.7653L30.4092 20.7876L32.45 20.7876L32.4749 23.1721L30.4341 23.1721L30.4647 26.1021L27.3935 26.1021ZM23.6202 20.7876L27.3784 20.7876L27.3363 16.7461C27.3334 16.4767 27.3373 16.2005 27.3478 15.9176C27.3582 15.6213 27.3686 15.3249 27.379 15.0285L27.3385 15.0285C27.2068 15.3114 27.0482 15.6078 26.8629 15.9176C26.6909 16.214 26.5253 16.4834 26.3662 16.7259L24.0382 20.1814C23.972 20.2891 23.9057 20.3902 23.8393 20.4845C23.7729 20.5788 23.6999 20.6798 23.6202 20.7876Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8374_35648"
          x="0.09375"
          y="0"
          width="43.8305"
          height="43.598"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="0.8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.51 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8374_35648"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8374_35648"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8374_35648"
          x1="11.2528"
          y1="-12.7769"
          x2="59.1627"
          y2="19.3542"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB259" />
          <stop offset="0.294677" stopColor="#FD7600" />
          <stop offset="1" stopColor="#A90000" />
        </linearGradient>
      </defs>
    </svg>
  );
};
