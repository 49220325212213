import { FC } from 'react';

export const Multiplicator5: FC = () => {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_8374_35645)">
        <rect
          width="40.0024"
          height="39.9967"
          rx="9"
          transform="matrix(0.999941 -7.83693e-10 0.0104328 1.00003 0.28125 0)"
          fill="url(#paint0_linear_8374_35645)"
        />
        <rect
          x="1.01037"
          y="1.00003"
          width="38.0024"
          height="37.9967"
          rx="8"
          transform="matrix(0.999941 -7.83693e-10 0.0104328 1.00003 0.270877 -2.8491e-05)"
          stroke="white"
          strokeOpacity="0.23"
          strokeWidth="2"
        />
        <path
          d="M12.4987 15.9985L15.0112 20.5451L16.1416 20.4441L20.1811 26.1021L16.2411 26.1021L13.6471 21.4948L12.455 21.4948L8.53841 15.9985L12.4987 15.9985ZM19.9949 15.9985L16.233 21.4544L14.9592 21.3736L12.3616 26.1021L8.50238 26.1021L12.5042 20.4037L13.6778 20.5653L16.1357 15.9985L19.9949 15.9985ZM26.0806 26.3042C24.9221 26.3042 23.9095 26.0819 23.0428 25.6373C22.1894 25.1793 21.4677 24.4384 20.8778 23.4145L23.913 21.899C24.0668 22.4379 24.3071 22.8689 24.6337 23.1923C24.9739 23.5156 25.4739 23.6772 26.134 23.6772C26.5516 23.6772 26.9078 23.6031 27.2026 23.455C27.4974 23.3068 27.7241 23.0912 27.8828 22.8083C28.0548 22.5119 28.1387 22.155 28.1343 21.7373C28.1301 21.3332 28.0458 20.9964 27.8813 20.727C27.7303 20.4575 27.5059 20.2555 27.2082 20.1208C26.9103 19.9726 26.5391 19.8985 26.0946 19.8985C25.7713 19.8985 25.482 19.9322 25.2268 19.9995C24.9714 20.0534 24.7366 20.141 24.5223 20.2622C24.3214 20.37 24.1343 20.5114 23.961 20.6866L21.3292 20.2016L22.1003 12.7653L30.5058 12.7653L30.5338 15.4529L24.654 15.4529L24.3383 18.1202L24.2811 18.4436L24.3013 18.4436C24.5675 18.1337 24.9422 17.898 25.4254 17.7363C25.9087 17.5746 26.4467 17.4938 27.0393 17.4938C27.9149 17.4938 28.6643 17.6689 29.2876 18.0192C29.9109 18.3695 30.3941 18.8477 30.7372 19.4539C31.0803 20.0601 31.2559 20.7539 31.264 21.5353C31.2726 22.357 31.1386 23.071 30.8621 23.6772C30.5989 24.27 30.2201 24.7617 29.7258 25.1524C29.2449 25.543 28.6889 25.8327 28.0578 26.0213C27.4401 26.2099 26.781 26.3042 26.0806 26.3042Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8374_35645"
          x="0.375"
          y="0"
          width="43.8305"
          height="43.598"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="0.8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.51 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8374_35645"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8374_35645"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8374_35645"
          x1="11.2528"
          y1="-12.7769"
          x2="59.1627"
          y2="19.3542"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF6C0" />
          <stop offset="0.144093" stopColor="#FFFEFD" />
          <stop offset="0.246562" stopColor="#FFB83D" />
          <stop offset="0.438477" stopColor="#87541A" />
          <stop offset="0.699288" stopColor="#885223" />
          <stop offset="0.887095" stopColor="#CFA156" />
        </linearGradient>
      </defs>
    </svg>
  );
};
