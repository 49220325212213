import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBackButtonTelegram } from '@shared/hooks/useBackButtonTelegram';

import { FormLaunch, Heading } from './ui';

export const LaunchMemeCoin: FC = () => {
  const navigate = useNavigate();

  useBackButtonTelegram(() => {
    navigate('/dex/newbies');
  });

  return (
    <div className="relative">
      <Heading />

      <FormLaunch />
    </div>
  );
};
