import React, { useState } from 'react';
import { MeAPI } from '@entities/me/api';
import { useQuery } from '@tanstack/react-query';

import { BoostsAPI } from './entities/api';
import { IBoost, UserBoostModel } from './entities/type';
import { categories } from './constants';
import {
  BottomSheetCoin,
  BottomSheetItem,
  Coin,
  Header,
  Item,
  ToggleButtons,
} from './ui';

export const MiningBoostsPage: React.FC = () => {
  const boostsQuery = useQuery(BoostsAPI.getBoostsQueryOptions());
  const profileQuery = useQuery(MeAPI.getMeQueryOptions());

  const [category, setCategory] = useState<number>(0);
  const [selectedItemId, setSelectedItemId] = useState<number>(0);
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [showBottomSheetItem, setShowBottomSheetItem] = useState(false);

  const mergedBoosts = React.useMemo(() => {
    if (!boostsQuery.data || !profileQuery.data) return [];

    const boosts = boostsQuery.data.boosts;
    const userBoosts: UserBoostModel[] = profileQuery.data.boosts;

    return boosts.map((boost) => {
      const userBoost = userBoosts.find((ub: UserBoostModel) => ub.boostId === boost.id);
      return {
        ...boost,
        boostLevel: userBoost?.boostLevel ?? 0,
        boostBonus: userBoost?.boostBonus ?? 0,
        boostNextLevelPrice: userBoost?.boostNextLevelPrice ?? boost.price,
        boostNextLevelBonus: userBoost?.boostNextLevelBonus ?? boost.bonus,
      };
    });
  }, [boostsQuery.data, profileQuery.data]);

  const filteredBoosts = React.useMemo(() => {
    return mergedBoosts.filter(
      (boost) => boost.categoryId === category && boost.available === true,
    );
  }, [mergedBoosts, category]);

  const handleItemClick = (id: number) => {
    setSelectedItemId(id);
    setShowBottomSheetItem(true);
  };

  const handleCoinClick = (id: number) => {
    setSelectedItemId(id);
    setShowBottomSheet(true);
  };

  const renderItems = (boosts: IBoost[]) => (
    <div className={category === 0 ? 'mt-5' : 'grid grid-cols-2 gap-3 mt-5'}>
      {boosts.map((boost) => (
        <React.Fragment key={boost.id}>
          {category === 0 ? (
            <Coin
              key={boost.id}
              id={boost.id}
              coin={boost.url}
              title={boost.name}
              trand={boost.boostBonus}
              lvl={boost.boostLevel}
              openAtLevel={boost.opensAtLevel}
              balance={boost.boostNextLevelPrice}
              isAvailable={
                boost.opensAtLevel <= profileQuery.data.level.currentUserLevel.id
              }
              onClick={() => handleCoinClick(boost.id)}
            />
          ) : (
            <Item
              key={boost.id}
              img={boost.url}
              title={boost.name}
              trand={boost.boostBonus}
              lvl={boost.boostLevel}
              balance={boost.boostNextLevelPrice}
              openAtLevel={boost.opensAtLevel}
              isAvailable={
                boost.opensAtLevel <= profileQuery.data.level.currentUserLevel.id
              }
              onClick={() => handleItemClick(boost.id)}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );

  return (
    <div className="flex flex-col max-w-container mx-4">
      <Header />

      <ToggleButtons
        buttons={categories}
        currentButtonIndex={category}
        setCurrentButtonIndex={setCategory}
      />

      {renderItems(filteredBoosts)}

      {showBottomSheet && (
        <BottomSheetCoin
          boosts={filteredBoosts}
          itemId={selectedItemId}
          onClose={() => setShowBottomSheet(false)}
        />
      )}

      {showBottomSheetItem && (
        <BottomSheetItem
          boosts={filteredBoosts}
          itemId={selectedItemId}
          onClose={() => setShowBottomSheetItem(false)}
        />
      )}
    </div>
  );
};
