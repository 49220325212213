import { FC } from 'react';

import unicornImg from '/images/icons/unicorn.svg';

export const Heading: FC = () => {
  return (
    <div className="flex justify-center items-center gap-1 text-1-5xl font-semibold pt-5">
      Launch Meme
      <img src={unicornImg} />
    </div>
  );
};
