import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '@widgets/Header';
import { NavBar } from '@widgets/NavBar';

export const GameLayout: FC = () => {
  return (
    <>
      <Header />
      <div className="mb-[92px]">
        <Outlet />
      </div>
      <NavBar />
    </>
  );
};
