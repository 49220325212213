import { FC, ReactNode } from 'react';

interface Properties {
  image?: string;
  variant?: 'gray' | 'blue' | 'orange' | 'purple';
  prize?: ReactNode;
  title: ReactNode;
  onClick: () => void;
}

const getGradientStyle = (variant: string) => {
  switch (variant) {
    case 'gray': {
      return 'linear-gradient(to right, #313238, #313238)';
    }
    case 'blue': {
      return 'linear-gradient(to right, #2346cf, #b518ff)';
    }
    case 'orange': {
      return 'linear-gradient(to right, #ff2727, #ea7c2c, #ff3d00)';
    }
    case 'purple': {
      return 'linear-gradient(118.65deg, #931add 15.67%, #ff1887 91.74%)';
    }
    default: {
      return '';
    }
  }
};

export const BannerItem: FC<Properties> = ({
  title,
  image,
  variant = 'gray',
  prize,
  onClick,
}) => {
  const gradientStyle = getGradientStyle(variant);

  return (
    <div
      className=" px-3 flex-grow flex-shrink w-[163px] pt-[11px] pb-[9px] flex items-center rounded-2xl"
      style={{
        background: image ? `url(${image}), ${gradientStyle}` : gradientStyle,
        backgroundSize: 'cover, cover',
        backgroundPosition: 'center, center',
        backgroundRepeat: 'no-repeat, no-repeat',
        boxShadow: 'inset 0 0 0 1px rgba(255, 255, 255, 0.1)',
      }}
      onClick={onClick}
    >
      <div className="flex flex-col gap-y-1">
        {title}
        {prize && (
          <div className="flex text-[13px] gap-x-1 items-center rounded-full py-1 px-2 w-max bg-[rgb(255_255_255_/_14%)]">
            {prize}
          </div>
        )}
      </div>
    </div>
  );
};
