import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Button } from '@components/index';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@components/ui/dropdown-menu';
import { Ton } from '@shared/icons/Ton';
import addressShort from '@shared/lib/addressShort';
import { useTonAddress, useTonConnectModal, useTonConnectUI } from '@tonconnect/ui-react';
import { LogOutIcon } from 'lucide-react';

export const TonButton = ({ children }) => {
  const { open } = useTonConnectModal();
  const [tonConnectUI] = useTonConnectUI();
  const tonAddress = useTonAddress();

  useEffect(() => {
    if (tonConnectUI?.account?.chain === '-3') {
      handleDisconnect();
      toast.error('Please connect Mainnet Wallet');
    }
  }, [tonConnectUI?.account?.chain]);

  const handleDisconnect = async () => {
    try {
      await tonConnectUI.disconnect();
    } catch (error) {
      console.error('Failed to disconnect:', error);
    }
  };

  return tonConnectUI.connected ? (
    <DropdownMenu>
      <DropdownMenuTrigger className="w-full">
        <Button variant="container-light" rounded="2xl" className="w-full">
          <div className="border-1 border-solid border-white rounded-full">
            <Ton width={24} height={24} />
          </div>{' '}
          {addressShort(tonAddress, 5)}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[calc(100vw-32px)] mx-auto">
        <Button
          variant="base-container"
          className="h-[40px] border-none"
          onClick={handleDisconnect}
        >
          Disconnect
          <LogOutIcon />
        </Button>
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    <Button variant="blue-4" onClick={open} rounded="2xl" className="w-full">
      <div className="border-1 border-solid border-white rounded-full">
        <Ton width={24} height={24} />
      </div>{' '}
      {children}
    </Button>
  );
};
