import React from 'react';

import earn from '/images/icons/earn_nav.svg';
import earnGray from '/images/icons/earnGray.svg';

interface Properties {
  isActive?: boolean;
}

export const EarnIcon: React.FC<Properties> = ({ isActive }) => {
  return (
    <div>{isActive ? <img src={earn} alt="" /> : <img src={earnGray} alt="" />}</div>
  );
};
