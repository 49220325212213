import { FC } from 'react';
import { Gem } from '@shared/ui';
import BalanceDisplay from '@shared/ui/BalanceDisplay';

import buyImg from '/images/icons/buy-task.svg';
import convertImg from '/images/icons/convert-task.svg';
import createImg from '/images/icons/create-task.svg';
import calendarImg from '/images/icons/daily.png';
import friendsImg from '/images/icons/friends_white.svg';
import sellImg from '/images/icons/sell-task.svg';
import storyImg from '/images/icons/story.svg';
import tgImg from '/images/icons/tg.svg';
import tonImg from '/images/icons/ton-task.svg';

interface Properties {
  icon: string;
  title: string;
  reward: number;
  color: string;
  complete?: boolean;
  onClick: () => void;
}

export const Task: FC<Properties> = ({
  icon,
  title,
  reward,
  color,
  complete,
  onClick,
}) => {
  const icons: Record<string, string> = {
    tg: tgImg,
    ':invite': friendsImg,
    daily: calendarImg,
    sellToken: sellImg,
    buyToken: buyImg,
    createToken: createImg,
    convertCrystals: convertImg,
    connectTon: tonImg,
    ':share': storyImg,
  };

  const iconSource = icons[icon];

  return (
    <div
      className="flex items-center border-b-1 justify-between border-b-solid border-b-base-container py-3"
      onClick={onClick}
    >
      <div className="flex w-max items-center gap-x-2">
        <div
          className="w-[40px] h-[40px] rounded-lg flex items-center justify-center border-1 border-1"
          style={{
            boxShadow: `0px 0px 20px 0px ${color}99`,
            background: `linear-gradient(150deg, #1A1B22 0%, #1A1B22 40%, ${color}66 100%)`,
            border: `1px solid ${color}`,
          }}
        >
          <img className="w-[28px] h-[28px]" src={iconSource} alt={icon} />
        </div>
        <div>
          <p className="font-semibold">{title}</p>
        </div>
      </div>

      <div
        className="h-[36px] rounded-4xl flex items-center gap-1
      bg-container-light px-3 font-bold rounded-full"
      >
        {complete ? (
          <p className="text-success-strong">Done</p>
        ) : (
          <>
            +
            <BalanceDisplay balance={reward} big />
            <Gem size={16} />
          </>
        )}
      </div>
    </div>
  );
};
