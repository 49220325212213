import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { MeAPI } from '@entities/me/api';
import { FriendsAPI } from '@pages/FrensAndLeaders/entities/friends/api';
import { Gem } from '@shared/ui/Gem';
import { useQuery } from '@tanstack/react-query';
import WebApp from '@twa-dev/sdk';

import { BannerItem } from './ui/BannerItem';

import inviteImg from '/images/banners/invite.png';
import lotteryImg from '/images/banners/lottery.png';
import nftImg from '/images/banners/nft.png';
import whiteListImg from '/images/banners/whitelist.png';
import usdtSvg from '/images/icons/usdt.svg';

interface Properties {
  callSoonNotification(): void;
}

export const Banners: FC<Properties> = ({ callSoonNotification }) => {
  const navigate = useNavigate();

  const { data: profile } = useQuery(MeAPI.getMeQueryOptions());
  const { data: frens } = useQuery(FriendsAPI.getFriendsQueryOptions());

  const needInvite = frens?.totalCount < 3;

  const inviteFriend = (): void => {
    if (!profile) return;
    WebApp.openTelegramLink(`https://t.me/share/url?url=${profile.inviteLink}`);
  };

  const goListing = (): void => {
    navigate('/listing');
  };

  return (
    <div className="overflow-hidden px-4 overflow-x-auto scrollbar-none">
      <div className="w-max flex gap-2 h-[76px] overflow-hidden">
        {needInvite && (
          <BannerItem
            title={
              <p className="text-xs font-semibold leading-[14.4px]">
                Invite First <br /> 3 Frens
              </p>
            }
            variant="gray"
            image={inviteImg}
            prize={
              <>
                <Gem size={16} />
                <p className="text-[13px] font-semibold leading-[14.4px]">100K</p>
              </>
            }
            onClick={inviteFriend}
          />
        )}
        <BannerItem
          title={
            <p className="text-xs font-semibold leading-[14.4px]">
              Buy Whitelist for <br /> $RAX Pre-Sale
            </p>
          }
          variant="blue"
          image={whiteListImg}
          prize={
            <>
              <p className="text-[13px] font-semibold leading-[14.4px]">100K</p>{' '}
              <Gem size={16} />
            </>
          }
          onClick={goListing}
        />
        <BannerItem
          variant="orange"
          image={lotteryImg}
          title={
            <p className="text-xs font-semibold leading-[14.4px]">
              Lottery Entry <br /> With Prize
            </p>
          }
          prize={
            <>
              <img src={usdtSvg} alt="USDT" />
              <p className="text-[13px] font-semibold leading-[14.4px]">+25,000</p>
            </>
          }
          onClick={callSoonNotification}
        />
        <BannerItem
          variant="blue"
          image={nftImg}
          title={
            <p className="text-base font-semibold leading-[14.4px]">
              NFT Key <br />
              Mint
            </p>
          }
          onClick={callSoonNotification}
        />
      </div>
    </div>
  );
};
