import { FC } from 'react';

export const Buy: FC = () => (
  <svg
    width="19"
    height="17"
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.10448 16.4993C3.72948 16.4993 3.39615 16.3848 3.10448 16.1556C2.81282 15.9264 2.61143 15.6313 2.50032 15.2702L0.375316 7.56185C0.305871 7.29796 0.35101 7.0549 0.510732 6.83268C0.670455 6.61046 0.889205 6.49935 1.16698 6.49935H5.12532L8.79198 1.04102C8.86143 0.929904 8.95865 0.839627 9.08365 0.770182C9.20865 0.700738 9.34059 0.666016 9.47948 0.666016C9.61837 0.666016 9.75032 0.700738 9.87532 0.770182C10.0003 0.839627 10.0975 0.929904 10.167 1.04102L13.8336 6.49935H17.8336C18.1114 6.49935 18.3302 6.61046 18.4899 6.83268C18.6496 7.0549 18.6948 7.29796 18.6253 7.56185L16.5003 15.2702C16.3892 15.6313 16.1878 15.9264 15.8961 16.1556C15.6045 16.3848 15.2711 16.4993 14.8961 16.4993H4.10448ZM9.50032 13.166C9.95865 13.166 10.351 13.0028 10.6774 12.6764C11.0038 12.35 11.167 11.9577 11.167 11.4993C11.167 11.041 11.0038 10.6487 10.6774 10.3223C10.351 9.99588 9.95865 9.83268 9.50032 9.83268C9.04198 9.83268 8.64962 9.99588 8.32323 10.3223C7.99684 10.6487 7.83365 11.041 7.83365 11.4993C7.83365 11.9577 7.99684 12.35 8.32323 12.6764C8.64962 13.0028 9.04198 13.166 9.50032 13.166ZM7.14615 6.49935H11.8336L9.47948 2.99935L7.14615 6.49935Z"
      fill="white"
    />
  </svg>
);
