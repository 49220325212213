import { FC } from 'react';
import { Telegram } from '@shared/icons/Telegram';
import { Gem } from '@shared/ui/Gem';
import { GradientPatch } from '@widgets/GradientPatch';

import { MedalPlace } from '../../icons/MedalPlace';
import { Avatar } from '../Avatar';
import BalanceDisplay from '../BalanceDisplay';

import friendsImg from '/images/icons/friends.svg';

interface Properties {
  telegramID: number;
  name: string;
  lvl: number;
  friends: number;
  balance: string;
  place: number;
}

export const Leader: FC<Properties> = ({
  telegramID,
  name,
  lvl,
  friends,
  balance,
  place,
}) => {
  const url = `${import.meta.env.VITE_API_URL}/photo/${telegramID}`;
  return (
    <div className="flex items-center gap-4 relative border-b-[1px] border-b-solid bg-base-background border-b-base-container py-4 w-full">
      <div>
        <MedalPlace place={place} />
      </div>
      <div className="relative">
        <div className="w-10 h-10">
          <Avatar url={url} />
        </div>
        <div className="absolute bottom-0 right-[-5px]">
          <Telegram />
        </div>
      </div>
      <div>
        <p className="text-base font-semibold">{name}</p>
        <div className="flex items-center gap-2">
          <GradientPatch>{lvl}lvl</GradientPatch>
          <div className="flex items-center gap-1 font-medium">
            <img src={friendsImg} />
            <BalanceDisplay balance={friends} f={0} big />
          </div>
        </div>
      </div>

      <div className="absolute right-0 flex items-center gap-1 font-semibold">
        <Gem></Gem>
        <BalanceDisplay balance={Number(balance)} f={0} big />
      </div>
    </div>
  );
};
