import { useRoutes } from 'react-router-dom';

import { ProfilePage } from './Index/Profile.page.tsx';
import { VipSkinsPage } from './VipSkins/VipSkins.page.tsx';

export const ProfileRouter = () => {
  return useRoutes([
    {
      path: '/',
      element: <ProfilePage />,
    },
    {
      path: '/vip',
      element: <VipSkinsPage />,
    },
  ]);
};
