import { FC, useEffect, useState } from 'react';
import { Button } from '@components/index';
import { Drawer, DrawerContent } from '@components/ui/drawer';
import { useBackButtonTelegram } from '@shared/hooks/useBackButtonTelegram';
import { groupBy } from '@shared/lib/groupBy';
import { formatNumber } from '@shared/lib/number/format-number';
import { Bonus } from '@shared/ui';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import JSConfetti from 'js-confetti';

import { DailyAPI } from './entities/api';
import { Day } from './ui';

import dailyImg from '/images/icons/calendar.png';
import closeImg from '/images/icons/close.svg';

const jsConfetti = new JSConfetti();

interface Properties {
  onClose: () => void;
}

export const DailyTasksPage: FC<Properties> = ({ onClose }) => {
  const qc = useQueryClient();
  const { data: dailyList } = useQuery(DailyAPI.getDailyListQueryOptions());
  const { data: daily } = useQuery(DailyAPI.getDailyQueryOptions());
  const [isClaim, setIsClaim] = useState<boolean>(false);

  const handleClose = () => {
    onClose();
  };

  useBackButtonTelegram(handleClose);

  useEffect(() => {
    if (daily === false) {
      setIsClaim(true);
    } else {
      setIsClaim(false);
    }
  }, [daily]);

  const claim = useMutation({
    mutationFn: DailyAPI.makeClaim,
    onSuccess: () => {
      jsConfetti.addConfetti({
        emojis: ['🌟', '⚡️', '💥', '✨', '🔶', '⭐️'],
      });
    },
    onSettled: () => {
      [
        DailyAPI.getDailyQueryOptions().queryKey,
        DailyAPI.getDailyListQueryOptions().queryKey,
      ].map((keys) =>
        qc.invalidateQueries({
          queryKey: keys,
        }),
      );
      setIsClaim(true);
    },
  });

  const dailyListGrouped = dailyList ? groupBy(dailyList, (task) => task?.group) : [];

  return (
    <Drawer open onOpenChange={handleClose}>
      <DrawerContent
        className="max-h-[calc(100vh-50px)] bg-base-background"
        childClassName="mt-0"
      >
        <div
          className="text-black bg-faint-weak w-[30px] h-[30px] rounded-full flex justify-center items-center
            absolute top-[23px] right-[23px] z-[51]"
          onClick={handleClose}
          onTouchStart={handleClose}
        >
          <img src={closeImg} />
        </div>
        <div className="flex-1 overflow-y-auto scrollbar-none pb-24">
          <div className="flex flex-col items-center gap-y-4">
            <img src={dailyImg} alt="Daily Reward" />
            <div className="flex flex-col items-center gap-y-2">
              <h3 className="text-center text-3-5xl font-bold">Daily Reward</h3>
              <p className="text-2xs text-gray text-center">
                Collect coins for daily logging game without skipping. &quot;Claim&quot;
                button need to press daily, otherwise the day counter will start again.
              </p>
            </div>
          </div>

          {[...dailyListGrouped].map((value, index) => (
            <div key={index} className="max-w-container mx-4 grid grid-cols-4 gap-2 mt-4">
              {value[1].map((task) => (
                <Day
                  key={task?.day}
                  day={task?.day}
                  reward={task?.reward}
                  isCurrent={
                    task?.reward === (typeof daily === 'boolean' ? false : daily?.reward)
                  }
                  isOpen={
                    task?.claimed ||
                    task?.reward === (typeof daily === 'boolean' ? false : daily?.reward)
                  }
                />
              ))}
            </div>
          ))}
        </div>

        {isClaim ? (
          <div className="w-full bottom-5 fixed">
            <div className="max-w-container mx-4 h-[56px]">
              <Button variant="faint-weak" className="h-full">
                Return tomorrow for claim
              </Button>
            </div>
          </div>
        ) : (
          <div className="w-full bottom-5 fixed">
            <div className="max-w-container mx-4 h-[56px]">
              <Button
                loading={claim.isPending}
                onClick={() => claim.mutate()}
                className="h-full"
              >
                Claim
                <Bonus color="blue">
                  +
                  {formatNumber(
                    (typeof daily === 'boolean' ? 0 : daily.reward) || 0,
                    true,
                  )}
                </Bonus>
              </Button>
            </div>
          </div>
        )}
      </DrawerContent>
    </Drawer>
  );
};
