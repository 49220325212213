import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cn } from '@shared/lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import { LoaderCircle } from 'lucide-react';

const buttonVariants = cva(
  'inline-flex items-center justify-center gap-2 whitespace-nowrap  text-prebase font-semibold focus-visible:outline-none disabled:pointer-events-none disabled:mix-blend-luminosity disabled:opacity-50',
  {
    variants: {
      variant: {
        'accent-strong': 'bg-accent-strong text-white',
        'base-container':
          'bg-base-container text-gray-light border-1 border-solid border-[#24252D]',
        'container-light': 'bg-container-light text-white',
        'faint-weak': 'bg-faint-weak text-white',
        purple: 'bg-purple text-white',
        'blue-4': 'bg-blue-4 text-white',
        'blue-purple-gradient':
          'text-white bg-gradient-to-r from-complement-strong to-purple-2',
        'success-strong': 'bg-success-strong text-base-background',
        'container-dark': 'bg-container-dark text-white',
        'critic-strong-up': 'bg-critic-strong-up text-base-background',
        'critic-strong-down': 'bg-critic-strong-down text-white',
        'success-strong-down': 'bg-success-strong-down text-white',
        background: 'bg-base-background text-white',
        white: 'bg-white text-faint-weak',
        gold: 'bg-gold-gradient shadow-gold-shadow text-white',
        'dark-gold':
          'bg-dark-gold-gradient border-1 border-solid border-[#D29A00] text-white',
      },
      size: {
        default: 'w-full px-3 py-3',
        sm: 'h-9 px-3',
        lg: 'h-11 px-8',
        icon: 'h-10 w-10',
      },
      rounded: {
        default: 'rounded-xl',
        '2xl': 'rounded-2xl',
        full: 'rounded-full',
      },
    },
    defaultVariants: {
      variant: 'accent-strong',
      size: 'default',
      rounded: 'default',
    },
  },
);

export interface ButtonProperties
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProperties>(
  (
    {
      className,
      variant,
      size,
      rounded,
      asChild = false,
      loading = false,
      children,
      ...properties
    },
    reference,
  ) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, rounded, className }))}
        ref={reference}
        disabled={loading}
        {...properties}
      >
        {loading ? <LoaderCircle className="animate-spin" /> : children}
      </Comp>
    );
  },
);

Button.displayName = 'Button';

export { Button, buttonVariants };
