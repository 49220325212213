import React from 'react';

import fireGif from '/images/gif/fire.gif';
import memeDexImg from '/images/icons/memedex-icon.png';

export const MemeDexIcon: React.FC = () => {
  return (
    <div className="relative">
      <img className="w-7 h-7" src={memeDexImg} alt="" />
      <img className="absolute top-0 -right-3 w-4 h-4" src={fireGif} alt="" />
    </div>
  );
};
