import * as React from 'react';
import { cn } from '@shared/lib/utils';
import { cva, VariantProps } from 'class-variance-authority';

const inputVariant = cva('w-full h-full py-4 rounded-xl outline-none box-border', {
  variants: {
    variant: {
      'faint-weak':
        'bg-faint-weak text-gray focus:border-1 focus:border-solid focus:border-accent-strong-up',
      critic:
        'bg-critic-dark text-critic-strong focus:border-1 focus:border-solid focus:border-critic',
      container:
        'bg-base-container text-white focus:border-1 focus:border-solid focus:border-accent-strong-up',
    },
    padding: {
      '3': 'pl-3',
      '10': 'pl-10',
    },
    textCenter: {
      true: 'text-center',
      false: 'text-start',
    },
  },
  defaultVariants: {
    variant: 'faint-weak',
    padding: '3',
    textCenter: false,
  },
});

export interface InputProperties
  extends React.ComponentProps<'input'>,
    VariantProps<typeof inputVariant> {}

const Input = React.forwardRef<HTMLInputElement, InputProperties>(
  ({ className, variant, padding, textCenter, type, ...properties }, reference) => {
    const inputClasses = inputVariant({ variant, padding, textCenter });

    return (
      <input
        type={type}
        className={cn(inputClasses, className)}
        ref={reference}
        {...properties}
      />
    );
  },
);
Input.displayName = 'Input';

export { Input };
