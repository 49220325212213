import { FC } from 'react';

export const Heading: FC = () => {
  return (
    <h3 className="text-1-8xl text-center text-white font-bold max-w-container mx-4 mb-4">
      Buy Whitelist for Guarant{' '}
      <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-2 to-purple-2">
        $RAX
      </span>{' '}
      Pre-Sale
    </h3>
  );
};
