import { clientAPI } from '@shared/api/client';
import { infiniteQueryOptions } from '@tanstack/react-query';

import { Leader } from '../type';

const LIMIT_OF_LEADERS = 10;

export const LeadersAPI = {
  getInfiniteLeaders: (offset: number = 0) =>
    clientAPI('game/leaderboard', {
      searchParams: { page: offset, peerPage: LIMIT_OF_LEADERS },
    }).json<{ total: number; users: Leader[] }>(),

  getLeadersInfinityQueryOptions: () =>
    infiniteQueryOptions({
      queryKey: ['inf_leaderboard'],
      queryFn: ({ pageParam: pageParameter = 0 }) =>
        LeadersAPI.getInfiniteLeaders(pageParameter),
      getNextPageParam: (lastPage: { total: number; users: Leader[] }, allPages) => {
        return lastPage.users.length > 0 ? allPages.length : undefined;
      },
      initialPageParam: 0,
    }),
} as const;
