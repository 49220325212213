import * as ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Provider } from '@app/provider';
import * as Sentry from '@sentry/react';
// import './loader.sass';
// import "./app/i18next.ts";
import { ErrorBoundary } from '@widgets/ErrorBoundary/index.tsx';

import './index.scss';

import { rootRouter } from './app/root.router.tsx';

// if (import.meta.env.MODE === 'production') {
Sentry.init({
  dsn: 'https://8721e04d2e10a74e88278902a7b1b28e@o4508494478901248.ingest.us.sentry.io/4508516709498880',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
// }

ReactDOM.createRoot(document.querySelector('#root')!).render(
  <Provider>
    <Sentry.ErrorBoundary
      fallback={<ErrorBoundary />}
      onError={() => console.info(document.querySelector('#loader'))}
    >
      <RouterProvider router={rootRouter} />
    </Sentry.ErrorBoundary>
  </Provider>,
);

declare module 'react' {
  interface CSSProperties {
    [key: `--${string}`]: string | number;
  }
}
