import React, { MouseEventHandler } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { MeAPI } from '@entities/me/api';
import { useVirtualKeyBoard } from '@shared/hooks/useVirtualKeyBoard.ts';
import { cn } from '@shared/lib/utils.ts';
import { useQuery } from '@tanstack/react-query';

import { BackIcon } from './ui/Back';
import { Item } from './ui/Item';
import { ConvertIcon, LaunchIcon, NewbiesIcon, PortfolioIcon } from './ui';

export const NavBarDex: React.FC = () => {
  const location = useLocation();

  const keyBoard = useVirtualKeyBoard();

  const isActive = (linkTitle: string): boolean => {
    const currentPathName = location.pathname;
    return linkTitle === currentPathName;
  };

  const profile = useQuery(MeAPI.getMeQueryOptions());
  const navigate = useNavigate();

  const handlerClickToLaunch: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    if (profile.data.level.currentUserLevel.id < 10) {
      toast.error('You need 10 level');
    } else {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      navigate('/dex/launch');
    }
  };

  return (
    <div
      className={cn(
        'w-full bg-base-background fixed bottom-0 left-0 z-10 pb-5',
        keyBoard.isOpen && 'hidden',
      )}
    >
      <div className="flex bg-base-background w-full justify-center items-center">
        <Item title="Back" linkTo="/">
          <BackIcon />
        </Item>
        <Item title="Convert" linkTo="/dex/swap" isActive={isActive('/dex/swap')}>
          <ConvertIcon isActive={isActive('/dex/swap')} />
        </Item>
        <Item title="Memes" linkTo="/dex/newbies" isActive={isActive('/dex/newbies')}>
          <NewbiesIcon isActive={isActive('/dex/newbies')} />
        </Item>
        <Item
          title="Launch"
          onClick={handlerClickToLaunch}
          linkTo="/dex/launch"
          isActive={isActive('/dex/launch')}
        >
          <LaunchIcon isActive={isActive('/dex/launch')} />
        </Item>
        <Item
          title="Portfolio"
          linkTo="/dex/portfolio"
          isActive={isActive('/dex/portfolio')}
        >
          <PortfolioIcon isActive={isActive('/dex/portfolio')} />
        </Item>
      </div>
    </div>
  );
};
