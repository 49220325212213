import { FC } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { MeAPI } from '@entities/me/api';
import { SkinsAPI } from '@pages/Profile/entities/skins/api';
import { ISkin } from '@pages/Profile/entities/skins/type';
import { Multiplicator3 } from '@shared/icons/Multiplicators/3';
import { Multiplicator4 } from '@shared/icons/Multiplicators/4';
import { Multiplicator5 } from '@shared/icons/Multiplicators/5';
import { DoubleGreenUp } from '@shared/ui';
import BalanceDisplay from '@shared/ui/BalanceDisplay';
import { Gem } from '@shared/ui/Gem';
import { useQueryClient } from '@tanstack/react-query';
import WebApp from '@twa-dev/sdk';

import 'react-spring-bottom-sheet/dist/style.css';

import closeImg from '/images/icons/close.svg';
import starImg from '/images/icons/star.svg';

interface Properties {
  skin: ISkin | null;
  open: boolean;
  onClose(): void;
}

const getMultiplicator = (multiplicator: number): JSX.Element => {
  const hash = {
    3: <Multiplicator3 />,
    4: <Multiplicator4 />,
    5: <Multiplicator5 />,
  };
  // eslint-disable-next-line security/detect-object-injection
  return hash[multiplicator] ?? hash[0];
};

export const BottomSheetSkin: FC<Properties> = ({ open, skin, onClose }) => {
  const qc = useQueryClient();
  const fetchInvoice = async () => {
    if (!skin) return;

    try {
      const response = await SkinsAPI.getSkinInvoice(skin.id.toString());

      if (response) {
        WebApp.openInvoice(response.invoiceLink, (status) => {
          if (status === 'paid') {
            setTimeout(() => {
              qc.invalidateQueries({
                queryKey: MeAPI.getMeQueryOptions().queryKey,
              });
              onClose();
            }, 1500);
          }
        });
      }
    } catch (error) {
      console.error('Error fetching invoice:', error);
    }
  };

  if (!skin) return <div></div>;

  return (
    <BottomSheet header={<></>} open={open}>
      <>
        <div className="h-min-[100px] bottom-sheet-white bg-base-container rounded-t-[32px] py-5">
          <button
            className="text-black bg-base-container w-[30px] h-[30px] rounded-full flex justify-center items-center
            absolute top-[23px] right-[23px]"
            onClick={onClose}
          >
            <img src={closeImg} />
          </button>

          <img src={skin.image} className="block m-auto w-[248px] h-[217px]" />

          <div>
            <p className="text-1-5xl text-white text-center font-semibold">
              {skin.id} Skin <span className="text-gray-dark">{skin.id}lvl</span>
            </p>
          </div>

          <div className="text-center">
            <p className="text-gray-light">
              Unlimited <span className="text-white">x{skin.id - 5} Mining Boost</span>
            </p>
            <p className="text-gray-light">
              1h Mining from{' '}
              <span className="text-white">
                <BalanceDisplay balance={skin.from} big />
              </span>
            </p>
          </div>

          <div className="flex justify-center items-center gap-2 mt-5">
            <div className="relative">
              <Gem size={32} />
              <div className="absolute bottom-2 left-6">
                <DoubleGreenUp />
              </div>
            </div>

            {getMultiplicator(skin.id - 5)}
          </div>

          <button
            onClick={fetchInvoice}
            className="flex justify-center items-center gap-2 bg-accent-strong w-[342px] h-[56px] m-auto rounded-2xl mt-5"
          >
            <img src={starImg} />
            <BalanceDisplay balance={skin.price} f={0} />
          </button>
        </div>
      </>
    </BottomSheet>
  );
};
