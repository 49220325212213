import React from 'react';

import backImg from '/images/icons/back.svg';

export const BackIcon: React.FC = () => {
  return (
    <div>
      <img src={backImg} alt="" />
    </div>
  );
};
