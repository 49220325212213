import { FC } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components/index';
import { MeAPI } from '@entities/me/api';
import { useQuery } from '@tanstack/react-query';

import fireGif from '/images/gif/fire.gif';
import unicornImg from '/images/gif/unicorn.gif';
import memeDexImg from '/images/icons/memedex-icon.png';
import searchImg from '/images/icons/searchWhite.svg';
import swapWhiteImg from '/images/icons/swap-white.svg';

export const Heading: FC = () => {
  const navigate = useNavigate();
  const profile = useQuery(MeAPI.getMeQueryOptions());

  const handlerClick = () => {
    if (profile.data.level.currentUserLevel.id < 10) {
      toast.error('You need 10 level');
    } else {
      navigate('/dex/launch');
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-5">
        <div className="flex items-center gap-x-2">
          <p className="text-4xl font-bold leading-[39.6px]">MemsDEX</p>
          <div className="relative">
            <img className="w-8 h-8" src={memeDexImg} alt="" />
            <img className="absolute top-0 -right-3 w-4 h-4" src={fireGif} alt="" />
          </div>
        </div>
        <div
          className="min-w-10 h-10 bg-faint-weak rounded-xl flex justify-center items-center"
          onClick={() => navigate('/dex/search?back=/dex/newbies')}
        >
          <img src={searchImg} alt="Search" />
        </div>
      </div>
      <div className="flex gap-2 justify-between w-full h-[44px]">
        <Button
          variant="gold"
          className="px-0 relative"
          rounded="full"
          onClick={handlerClick}
        >
          <div
            style={{
              content: '""',
              position: 'absolute',
              inset: 0,
              zIndex: 0,
              borderRadius: '9999px',
              background:
                'linear-gradient(2.84deg, rgba(255, 255, 255, 0) 1.52%, rgba(255, 255, 255, 0.406) 109.84%)',
              padding: '1px',
              WebkitMask:
                'linear-gradient(white, white) content-box, linear-gradient(white, white)',
              WebkitMaskComposite: 'destination-out',
              maskComposite: 'exclude',
            }}
          />
          <img src={unicornImg} alt="Unicorn" className="w-5 h-5" />
          Launch Meme
        </Button>
        <Button
          className="relative px-0 gap-x-0"
          rounded="full"
          onClick={() => navigate('/dex/swap')}
        >
          <img src={swapWhiteImg} alt="Swap" />
          Swap
          <div className="absolute top-[11px] right-[22%] w-[6px] h-[6px] rounded-full bg-gradient-to-r from-pink to-red"></div>
        </Button>
      </div>
    </div>
  );
};
