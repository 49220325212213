import { FC } from 'react';
import { DollarBaseIcon } from '@shared/icons/DollarBase';

import friendsImg from '/images/icons/friends.svg';

export const MetricCard: FC<{
  title: string;
  value: string | number;
  isGem?: boolean;
  isUser?: boolean;
}> = ({ title, value, isGem = false, isUser = false }) => {
  return (
    <div className="border-1 border-solid border-container-light rounded-lg px-3 py-2 h-[52px]">
      <p className="text-[11px] text-gray-light font-medium">{title}</p>
      <div className="flex items-center gap-x-1">
        {isGem && <DollarBaseIcon width={12} height={16} />}
        {isUser && <img src={friendsImg} alt="Friends Icon" />}
        <p className="text-base text-white font-semibold leading-[19.2px]">{value}</p>
      </div>
    </div>
  );
};
