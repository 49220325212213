import { ChangeEvent, FC } from 'react';
import { InputAttributes, NumericFormat, NumericFormatProps } from 'react-number-format';
import { Input } from '@components/ui/input';
import BalanceDisplay from '@shared/ui/BalanceDisplay';

import { Coin } from '../../type';

interface Properties {
  coinData: Coin;
  value: string;
  disabled: boolean;
  critic: boolean;
  prefix: string;
  onChange(value: string): void;
  inputProps?: Omit<NumericFormatProps<InputAttributes>, 'onChange'>;
}

export const BuyInput: FC<Properties> = ({
  coinData,
  value,
  critic,
  disabled,
  prefix,
  onChange,
  inputProps,
}) => {
  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value;

    const formattedValue = rawValue.replace(/[^\d.]/g, '');
    const dotCount = (formattedValue.match(/\./g) || []).length;

    if (dotCount > 1) {
      return;
    }

    onChange(formattedValue);
  };

  return (
    <div className="border-1 border-solid border-faint-weak flex items-center bg-base-background rounded-2xl py-2 mt-4">
      <div className="grow">
        <NumericFormat
          className={`w-full h-full bg-transparent focus:border-none outline-none pl-4 text-xl font-semibold ${critic ? 'text-critic-strong-up' : 'text-white'}`}
          value={value}
          customInput={Input}
          allowLeadingZeros
          allowNegative={false}
          thousandSeparator=","
          placeholder="0"
          prefix={prefix}
          onChange={onChangeHandler}
          disabled={disabled}
          decimalScale={6}
          {...inputProps}
        />
      </div>
      <div className="flex flex-col items-end pr-4">
        <p className="flex items-center justify-end gap-1 w-max">
          <span className="w-[30px] h-[30px] rounded-full overflow-hidden flex justify-center items-center">
            {coinData.icon}
          </span>
          <span className="font-semibold">{coinData.title}</span>
        </p>
        <p
          onClick={() => onChange(coinData.balance)}
          className="flex items-center gap-x-1 text-3xs font-semibold"
        >
          <span className="text-gray-light">Balance: </span>{' '}
          <span className="text-success-strong">
            <BalanceDisplay balance={Number(coinData.balance)} big />
          </span>
        </p>
      </div>
    </div>
  );
};
