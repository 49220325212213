import { FC } from 'react';

interface Properties {
  progress: number;
  height: number;
}

export const Progress: FC<Properties> = ({ progress, height }) => {
  return (
    <div className={'bg-faint-weak rounded-2xl w-full'} style={{ height: `${height}px` }}>
      <div
        className={'rounded-2xl bg-complement-strong'}
        style={{ width: `${progress}%`, height: `${height}px` }}
      />
    </div>
  );
};
