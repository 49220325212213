import { FC } from 'react';

interface Properties {
  width?: number;
  height?: number;
}

const DEFAULT_SIZE = 50;

export const ETH: FC<Properties> = ({ width = DEFAULT_SIZE, height = DEFAULT_SIZE }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.9986 50.0011C38.806 50.0011 49.9991 38.808 49.9991 25.0006C49.9991 11.1931 38.806 0 24.9986 0C11.1912 0 -0.00195312 11.1931 -0.00195312 25.0006C-0.00195312 38.808 11.1912 50.0011 24.9986 50.0011Z"
      fill="#627EEA"
    />
    <path
      d="M25.7734 6.25V20.1097L37.4878 25.3442L25.7734 6.25Z"
      fill="white"
      fillOpacity="0.602"
    />
    <path d="M25.7784 6.25L14.0625 25.3442L25.7784 20.1097V6.25Z" fill="white" />
    <path
      d="M25.7734 34.3255V43.7429L37.4956 27.5254L25.7734 34.3255Z"
      fill="white"
      fillOpacity="0.602"
    />
    <path d="M25.7784 43.7429V34.324L14.0625 27.5254L25.7784 43.7429Z" fill="white" />
    <path
      d="M25.7734 32.1503L37.4878 25.3486L25.7734 20.1172V32.1503Z"
      fill="white"
      fillOpacity="0.2"
    />
    <path
      d="M14.0625 25.3486L25.7784 32.1503V20.1172L14.0625 25.3486Z"
      fill="white"
      fillOpacity="0.602"
    />
  </svg>
);
