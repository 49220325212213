import { FC } from 'react';
import { MeAPI } from '@entities/me/api';
import { Avatar } from '@shared/ui';
import { TonButton } from '@shared/ui/TonButton';
import { useQuery } from '@tanstack/react-query';

import { AllLevels, LevelProgress, MyStats, SkinsCarusel } from './ui';

export const ProfilePage: FC = () => {
  const profile = useQuery(MeAPI.getMeQueryOptions());
  const url = `${import.meta.env.VITE_API_URL}/photo/${profile.data.telegramId}`;

  return (
    <div>
      <div className="mb-2 max-w-container mx-auto flex flex-col items-center gap-y-3 ">
        <div className="flex flex-col items-center gap-y-3">
          <div className="w-[64px] h-[64px] border-2 border-purple rounded-full">
            <Avatar url={url} />
          </div>
          <p className="text-[28px] font-semibold text-center">{profile.data.name}</p>
        </div>

        <TonButton>
          <p className="flex gap-x-0.5">
            Connect TON Wallet
            {/* +20,000 <Gem size={20} /> */}
          </p>
        </TonButton>
      </div>
      <LevelProgress />
      <SkinsCarusel />
      <MyStats />
      <AllLevels />
    </div>
  );
};
