import React from 'react';

import boost from '/images/icons/boost_nav.svg';
import boostGray from '/images/icons/boostGray.svg';

interface Properties {
  isActive?: boolean;
}

export const MiningIcon: React.FC<Properties> = ({ isActive }) => {
  return (
    <div>{isActive ? <img src={boost} alt="" /> : <img src={boostGray} alt="" />}</div>
  );
};
