import { clientAPI } from '@shared/api/client';
import { queryOptions } from '@tanstack/react-query';

import { Levels } from '../type';

export const LevelsAPI = {
  getLevels: () => clientAPI('game/getUserLevels').json<Levels>(),

  getLevelsQueryOptions: () =>
    queryOptions({
      queryKey: ['levels'],
      queryFn: () => LevelsAPI.getLevels(),
    }),
} as const;
