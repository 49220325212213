import { Navigate } from 'react-router-dom';
import { LaunchMemeCoin } from '@widgets/Dex/pages/LaunchMemeCoin/LaunchMemeCoin.page';
import { MemeCoin } from '@widgets/Dex/pages/MemeCoin/MemeCoin.page';
// import { MemeCoins } from '@widgets/Dex/pages/MemeCoins/MemeCoins.page';
import { NewbiesPage } from '@widgets/Dex/pages/Newbies/Newbies.page';
import { PortfolioPage } from '@widgets/Dex/pages/Portfolio/Portfolio.page';
import { SearchMeme } from '@widgets/Dex/pages/SearchMeme/SearchMeme.page';
import { SwapGamePoints } from '@widgets/Dex/pages/SwapGamePoints/SwapGamePoints.page';

export const dexRouter = [
  // {
  //   path: '',
  //   index: true,
  //   element: <MemeCoins />,
  // },
  {
    path: 'launch',
    element: <LaunchMemeCoin />,
  },
  {
    path: 'swap',
    element: <SwapGamePoints />,
  },
  {
    path: 'newbies',
    element: <NewbiesPage />,
  },
  {
    path: 'portfolio',
    element: <PortfolioPage />,
  },
  {
    path: 'pay/:address',
    element: <MemeCoin />,
  },
  {
    path: 'search',
    element: <SearchMeme />,
  },
  {
    path: '*',
    element: <Navigate to="/dex/newbies" />,
  },
];
