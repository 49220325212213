import { FC } from 'react';
import { MeAPI } from '@entities/me/api';
import BalanceDisplay from '@shared/ui/BalanceDisplay';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const MyStats: FC = () => {
  const { data: profile } = useQuery(MeAPI.getMeQueryOptions());

  const experience = profile.experience;
  const remainingNextLevel = profile.level.nextUserLevel
    ? profile.level.nextUserLevel.from - Number(experience)
    : 0;

  const invites = profile.amountFriends;
  const improves = profile.boosts.length;
  const daysRegister = dayjs
    .duration(dayjs().diff(profile.createdAt))
    .asDays()
    .toFixed(0);

  const skin = profile.level.currentUserSkin.id;
  return (
    <div className="px-4">
      <div className="flex flex-col gap-y-3 mt-5 rounded-[20px] bg-gradient-to-r from-container-light to-base-container text-2xs leading-[15.6px] font-semibold py-4 px-3 border-solid border-1 border-faint-weak">
        <div className="flex justify-between">
          <p className="">XP to next level:</p>
          <p>
            {remainingNextLevel > 0 ? (
              <BalanceDisplay balance={remainingNextLevel} big />
            ) : (
              'Max'
            )}
          </p>
        </div>

        <div className="flex justify-between">
          <p>Total XP:</p>
          <p>
            <BalanceDisplay balance={Number(experience)} big />
          </p>
        </div>

        <div className="flex justify-between">
          <p>Invite frens:</p>
          <p>{invites}</p>
        </div>

        <div className="flex justify-between">
          <p>Improves:</p>
          <p>{improves}</p>
        </div>

        <div className="flex justify-between">
          <p>Days in Game:</p>
          <p>{daysRegister}</p>
        </div>

        <div className="flex justify-between">
          <p>My skin:</p>
          <p>#{skin}</p>
        </div>
      </div>
    </div>
  );
};
