import { FC } from 'react';
import { Button } from '@components/index';
import { Pitchdeck, Roadmap } from '@shared/ui';
import WebApp from '@twa-dev/sdk';

export const Links: FC = () => (
  <div className="flex max-w-container mx-4 gap-2 mb-4">
    <div className="w-1/2">
      <Button variant="base-container" className="py-2">
        <Roadmap /> Roadmap
      </Button>
    </div>
    <div
      className="w-1/2"
      onClick={() =>
        WebApp.openLink('https://app.ray.sx/assets/documets/RaysX_PitchDeck.pdf')
      }
    >
      <Button variant="base-container" className="py-2">
        <Pitchdeck /> Pitchdeck
      </Button>
    </div>
  </div>
);
