import gift2Img from '/images/gifts/gift-2.png';
import gift3Img from '/images/gifts/Gift-3.png';

export const percentCells = [
  { id: 0, percent: 12, text: ['For', 'direct', 'referrals'] },
  { id: 1, percent: 5, text: ['For', '2nd', 'line referrals'] },
  { id: 2, percent: 2, text: ['For', '3nd', 'line referrals'] },
];

export const gifts = [
  {
    id: 0,
    title: <p className="font-semibold text-white text-prelg">Invite Friends</p>,
    bonus: '+5000',
    img: gift3Img,
  },
  {
    id: 1,
    title: (
      <p className="font-semibold text-white text-prelg">
        Invite Friends with <span className="text-blue-3">TG Premium</span>
      </p>
    ),
    bonus: '+25000',
    img: gift2Img,
  },
];
