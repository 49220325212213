import { useRoutes } from 'react-router-dom';

import { AllFrens } from './AllFrens/AllFrens.page';
import { FrensAndLeadersPage } from './index/FrensAndLeaders.page';

export const FriendsRoute = () =>
  useRoutes([
    {
      path: 'all',
      element: <AllFrens />,
    },
    {
      path: '/',
      element: <FrensAndLeadersPage />,
    },
  ]);
