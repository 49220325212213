import { FC } from 'react';
import { formatWithSeparators } from '@shared/lib/number/format-number';
import { Gem } from '@shared/ui/Gem';

interface gift {
  img: string;
  title: JSX.Element;
  bonus: number;
  bg: 'default' | 'premium';
}

export const Gift: FC<gift> = ({ img, title, bonus, bg }) => {
  const bgs = {
    default: './images/bg/coin-boost-maning.png',
    premium: './images/bg/invite_friends_premium.png',
  };

  return (
    <div
      className={`flex py-4 relative bg-no-repeat
    border-1 border-solid border-faint-weak rounded-2-5xl mb-[16px]
    bg-center bg-cover w-full pl-4
    `}
      style={{
        background: `url(${bgs[bg]})`,
      }}
    >
      <div>
        <img src={img} />
      </div>
      <div className="ml-[10px]">
        {title}
        <div className="flex gap-1 text-xs font-medium">
          <p className="text-success-strong">+{formatWithSeparators(bonus, 0)}</p>
          <p className="text-semibold text-white">
            <Gem></Gem>
          </p>
          <p className="text-gray font-medium">for you and your friend. </p>
        </div>
      </div>
    </div>
  );
};
