import { clientAPI } from '@shared/api/client';
import { queryOptions } from '@tanstack/react-query';

import { AppConfig, DexConfig } from '../type';

export const ConfigsAPI = {
  getDexConfig: () => clientAPI.get('v2/dex/config').json<DexConfig>(),

  getAppConfig: () => clientAPI.get('game/config').json<AppConfig>(),

  getDexConfigOptions: () =>
    queryOptions({
      queryKey: ['dex_config'],
      queryFn: () => ConfigsAPI.getDexConfig(),
    }),

  getAppConfigOptions: () =>
    queryOptions({
      queryKey: ['app_config'],
      queryFn: () => ConfigsAPI.getAppConfig(),
    }),
};
