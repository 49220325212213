const styles = `
.b-1:before {
  content: "";
  position: absolute;
  width: 44px;
  height: 20px;
  left: -7px;
  z-index: -1;
  background: linear-gradient(124deg, #ff8425 4.02%, #c33fe6 34.64%, #7000c5 74.08%);
  box-shadow: 0 0.5px 5px 0.914px rgba(158, 48, 255, 0.83);
  border-radius: 8px;
}

.b-1:after {
  content: "";
  position: absolute;
  width: 44px;
  height: 20px;
  left: -7px;
  z-index: 1;
  border: 1px solid rgba(255, 255, 255, 0.23);
  border-radius: 8px;
}

.b-2:before {
  content: "";
  position: absolute;
  width: 44px;
  height: 20px;
  left: -5px;
  z-index: -1;
  background: linear-gradient(124deg, #9fffb4 4.02%, #26be00 34.64%, #008849 74.08%);
  box-shadow: 0 0.5px 5px 0.914px rgba(38, 190, 0, 0.83);
  border-radius: 8px;
}

.b-2:after {
  content: "";
  position: absolute;
  width: 44px;
  height: 20px;
  left: -5px;
  z-index: 1;
  border: 1px solid rgba(255, 255, 255, 0.23);
  border-radius: 8px;
}

.b-3:before {
  content: "";
  position: absolute;
  width: 44px;
  height: 20px;
  left: -5px;
  z-index: -1;
  background: linear-gradient(124deg, #6893ff 4.02%, #3f47e6 34.64%, #0700c5 74.08%);
  box-shadow: 0 0.5px 5px 0.914px rgba(63, 71, 230, 0.83);
  border-radius: 8px;
}

.b-3:after {
  content: "";
  position: absolute;
  width: 44px;
  height: 20px;
  left: -5px;
  z-index: 1;
  border: 1px solid rgba(255, 255, 255, 0.23);
  border-radius: 8px;
}

.b-4:before {
  content: "";
  position: absolute;
  width: 44px;
  height: 20px;
  left: -5px;
  z-index: -1;
  background: linear-gradient(124deg, #ffcc92 4.02%, #fd7600 34.64%, #a90000 107.96%);
  box-shadow: 0 0.5px 5px 0.914px rgba(253, 118, 0, 0.83);
  border-radius: 8px;
}

.b-4:after {
  content: "";
  position: absolute;
  width: 44px;
  height: 20px;
  left: -5px;
  z-index: 1;
  border: 1px solid rgba(255, 255, 255, 0.23);
  border-radius: 8px;
}

.b-5:before {
  content: "";
  position: absolute;
  width: 44px;
  height: 20px;
  left: -5px;
  z-index: -1;
  background: linear-gradient(124deg, #fff6c0 4.02%, #fffefd 18.99%, #ffb83d 29.64%, #87541a 49.59%, #885223 76.7%, #cfa156 96.22%);
  box-shadow: 0 0.5px 5px 0.914px rgba(255, 184, 61, 0.83);
  border-radius: 8px;
}

.b-5:after {
  content: "";
  position: absolute;
  width: 44px;
  height: 20px;
  left: -5px;
  z-index: 1;
  border: 1px solid rgba(255, 255, 255, 0.23);
  border-radius: 8px;
}
`;

export const BadgeHeader = ({ boost = 1 }: { boost: number }) => {
  const boostClass = `b-${Math.trunc(boost)}`;

  return (
    <>
      <style>{styles}</style>
      <div
        className={`flex items-center font-medium absolute py-[1px]  ${[1.1, 1.2, 1.5, 1.7, 1.8].includes(boost) ? 'right-[-24px]' : 'right-[-24px] pl-[5.5px]'}  text-white lh ${boostClass}`}
      >
        x{boost}{' '}
        <div>
          <svg
            width="10"
            height="13"
            viewBox="0 0 10 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.33464 12.9997L5.0013 10.5573L0.667969 12.9997L5.0013 6.06641L9.33464 12.9997Z"
              fill="white"
              fillOpacity="0.63"
            />
            <path
              d="M9.33464 6.93333L5.0013 4.49091L0.667969 6.93333L5.0013 0L9.33464 6.93333Z"
              fill="white"
              fillOpacity="0.88"
            />
          </svg>
        </div>
      </div>
    </>
  );
};
