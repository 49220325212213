import infoImg from '/images/icons/info.svg';

export const Info = () => {
  return (
    <div className="p-3 flex max-w-container mx-4 gap-x-2 rounded-2xl bg-gradient-to-tr from-[#587BFF33] to-[#B518FF33]">
      <img src={infoImg} alt="Info" />
      <p className="text-3xs font-semibold text-white">
        Dollar* is a Dollar Simulator and is the game currency of MoneyMaker, not a means
        of payment.
      </p>
    </div>
  );
};
