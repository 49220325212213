import { useEffect } from 'react';
import { UseBackButtonType } from '@shared/types/webApp/index.ts';
import WebApp from '@twa-dev/sdk';

export const useBackButtonTelegram: UseBackButtonType = (function_: () => void) => {
  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(function_);

    return () => {
      WebApp.BackButton.offClick(function_);
      WebApp.BackButton.hide();
    };
  });
};
