import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@components/index';
import { useQuery } from '@tanstack/react-query';
import WebApp from '@twa-dev/sdk';
import { MemeAPI } from '@widgets/Dex/entities/meme/api';

import telegramImg from '/images/icons/telegram.svg';

export const MemeInfo: FC = () => {
  const { address } = useParams<{ address: string }>();
  const token = useQuery(MemeAPI.getTokenByAddressQueryOptions(address));

  return (
    <div className="w-full flex flex-col gap-y-[11px] max-w-container mx-4 mt-5">
      <div className="flex flex-col gap-y-3">
        <p className="text-1-5xl font-semibold text-white leading-[26.4px]">
          About {token.data.name}{' '}
          <span className="text-base font-bold text-gray">{token.data.symbol}</span>
        </p>
      </div>
      <p className=" text-xs text-gray-light break-words leading-[16.8px]">
        {token.data.description}
      </p>

      {token.data.tgChannel && (
        <div className="h-[46px]">
          <Button
            color="container-light"
            onClick={() => WebApp.openTelegramLink(token.data.tgChannel)}
          >
            Go to Channel
            <img className="w-[24px] h-[24px]" src={telegramImg} alt="Icon" />
          </Button>
        </div>
      )}
    </div>
  );
};
