import React from 'react';
import { useLocation } from 'react-router-dom';
import { useVirtualKeyBoard } from '@shared/hooks/useVirtualKeyBoard.ts';

import { EarnIcon } from './ui/EarnIcon';
import { FrensIcon } from './ui/FrensIcon';
import { Item } from './ui/Item';
import { MemeDexIcon } from './ui/MemeDex';
import { MiningIcon } from './ui/MiningIcon';
import { HomeIcon } from './ui';

export const NavBar: React.FC = () => {
  const location = useLocation();
  const keyBoard = useVirtualKeyBoard();

  const isActive = (linkTitle: string): boolean => {
    const currentPathName = location.pathname;
    return linkTitle === currentPathName;
  };

  if (keyBoard.isOpen) {
    return null;
  }
  return (
    <div className="w-full bg-base-background fixed bottom-0 left-0 pb-5">
      <div className="flex bg-base-background w-full justify-center">
        <Item title="Home" linkTo="/" isActive={isActive('/')}>
          <HomeIcon isActive={isActive('/')} />
        </Item>
        <Item title="Boosts" linkTo="/mining" isActive={isActive('/mining')}>
          <MiningIcon isActive={isActive('/mining')} />
        </Item>
        <Item title="MemsDex" linkTo="/dex" isActive={isActive('/dex')}>
          <MemeDexIcon />
        </Item>
        <Item title="Earn" linkTo="/earn" isActive={isActive('/earn')}>
          <EarnIcon isActive={isActive('/earn')} />
        </Item>
        <Item title="Frens" linkTo="/frens" isActive={isActive('/frens')}>
          <FrensIcon isActive={isActive('/frens')} />
        </Item>
        {/* <Item title="Listing" linkTo="/listing" isActive={isActive("/listing")}>
          <ListingIcon />
        </Item> */}
      </div>
    </div>
  );
};
