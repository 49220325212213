import { BNB, ETH, Sol, Ton } from '@shared/ui';

export const Step1Coins = [
  { id: 0, title: 'Ton', icon: <Ton width={16} height={16} /> },
  { id: 1, title: 'Sol', icon: <Sol width={16} height={16} /> },
  { id: 2, title: 'ETH', icon: <ETH width={16} height={16} /> },
  { id: 3, title: 'BNB', icon: <BNB width={16} height={16} /> },
];

export const Step2Sums = [50, 100, 250, 500, 1000, 2000, 4000];
