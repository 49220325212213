import { createEvent, createStore, sample } from 'effector';
import { persist } from 'effector-storage/local';

export const updateToken = createEvent<string>();

export const $token = createStore<string>('');

sample({
  clock: updateToken,
  target: $token,
});

persist({
  store: $token,
  key: 'auth_token',
});
