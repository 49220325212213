import skin1 from '/images/skins_avatar/1.png';
import skin2 from '/images/skins_avatar/2.png';
import skin3 from '/images/skins_avatar/3.png';
import skin4 from '/images/skins_avatar/4.png';
import skin5 from '/images/skins_avatar/5.png';
import skin6 from '/images/skins_avatar/6.png';
import skin7 from '/images/skins_avatar/7.png';
import skin8 from '/images/skins_avatar/8.png';
import skin9 from '/images/skins_avatar/9.png';
import skin10 from '/images/skins_avatar/10.png';

export const SKINS = {
  1: {
    boost: 1,
    lvl: 1,
    skin: skin1,
  },
  2: {
    boost: 1.1,
    lvl: 2,
    skin: skin2,
  },
  3: {
    boost: 1.2,
    lvl: 3,
    skin: skin3,
  },
  4: {
    boost: 1.5,
    lvl: 4,
    skin: skin4,
  },
  5: {
    boost: 1.7,
    lvl: 5,
    skin: skin5,
  },
  6: {
    boost: 1.8,
    lvl: 6,
    skin: skin6,
  },
  7: {
    boost: 2,
    lvl: 7,
    skin: skin7,
  },
  8: {
    boost: 3,
    lvl: 15,
    skin: skin8,
  },
  9: {
    boost: 4,
    lvl: 17,
    skin: skin9,
  },
  10: {
    boost: 5,
    lvl: 20,
    skin: skin10,
  },
};
