import { FC } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@components/index';
import { ConfigsAPI } from '@entities/config/api';
import { MeAPI } from '@entities/me/api';
import { useBackButtonTelegram } from '@shared/hooks/useBackButtonTelegram';
import { DollarIcon } from '@shared/icons/Dollar';
import { etherToWei, weiToEther } from '@shared/lib/number/format-number.ts';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MemeAPI } from '@widgets/Dex/entities/meme/api';
import { FormBuySell } from '@widgets/FormBuySell';
import { Coin } from '@widgets/FormBuySell/type';

import { BoostModal } from './ui/BoostModal';
import { Chart } from './ui/Chart';
import { Heading } from './ui/Heading';
import { MemeInfo } from './ui/MemeInfo';

import electroImg from '/images/icons/electro-icon.svg';

export const MemeCoin: FC = () => {
  const { address } = useParams<{ address: string }>();
  const qc = useQueryClient();
  const navigate = useNavigate();
  const dexConfig = useQuery(ConfigsAPI.getDexConfigOptions());

  useBackButtonTelegram(() => navigate('/dex/newbies'));

  const balance = useQuery(MemeAPI.getEthBalanceQueryOptions());
  const token = useQuery(MemeAPI.getTokenByAddressQueryOptions(address));
  const balanceToken = useQuery(MemeAPI.getTokenBalanceByAddressQueryOptions(address));

  const payment = useMutation({
    mutationFn: MemeAPI.swapErc20ForErc20,
    onMutate: async () => {
      await qc.cancelQueries({
        queryKey: MemeAPI.getTokenBalanceByAddressQueryOptions(address).queryKey,
      });
      await qc.cancelQueries({
        queryKey: MemeAPI.getEthBalanceQueryOptions().queryKey,
      });

      const previousTokenBalance = qc.getQueryData(
        MemeAPI.getTokenBalanceByAddressQueryOptions(address).queryKey,
      );

      await qc.cancelQueries({
        queryKey: [...MemeAPI.getEthBalanceQueryOptions().queryKey],
      });

      const previousEthBalance = qc.getQueryData(
        MemeAPI.getEthBalanceQueryOptions().queryKey,
      );

      return { previousTokenBalance, previousEthBalance };
    },
    onError: (_, __, context) => {
      qc.setQueryData(MemeAPI.getTokenBalanceByAddressQueryOptions(address).queryKey, {
        value: context.previousTokenBalance.toString(),
      });
      qc.setQueryData(
        MemeAPI.getEthBalanceQueryOptions().queryKey,
        context.previousEthBalance,
      );
    },
    onSettled: () => {
      for (const key of [
        MemeAPI.getTokenBalanceByAddressQueryOptions(address).queryKey,
        MemeAPI.getTokenByAddressQueryOptions(address).queryKey,
        MeAPI.getUserPortfolioQueryOptions().queryKey,
        MemeAPI.getEthBalanceQueryOptions().queryKey,
      ]) {
        qc.invalidateQueries({
          queryKey: key,
        });
      }
    },
  });

  const coins: [Coin, Coin] = [
    {
      title: 'Dollar*',
      icon: <DollarIcon width={26} height={26} />,
      balance: weiToEther(balance.data?.weiBalance?.value),
    },
    {
      title: token.data?.symbol,
      icon: (
        <img className="w-7 h-7 object-cover rounded-full" src={token.data?.iconUrl} />
      ),
      balance: weiToEther(balanceToken.data?.value),
    },
  ];

  if (token.isFetched && !token.data) {
    return <div className="pt-4 text-sm text-gray justify-center flex">Not found</div>;
  }
  if (token.isPending) {
    return <div className="pt-4 text-sm text-gray justify-center flex">Loading...</div>;
  }
  return (
    <div className="pb-3">
      <Heading />

      <Chart address={address} />

      <div className="my-4 px-2">
        <BoostModal triggerProps={{ asChild: true }}>
          <div className="mx-2">
            <Button variant="dark-gold" rounded="full" className="w-full ">
              <p className="bg-gradient-to-b from-[#FFFBE3]  to-[#D29A00] bg-clip-text text-transparent">
                Boost {token.data.symbol}
              </p>
              <img src={electroImg} alt="Electro icon" />
            </Button>
          </div>
        </BoostModal>
      </div>

      <FormBuySell
        inputs={coins}
        initTab="buy"
        mode="modal"
        isSearch
        price={Number(token.data?.exchangeRate)}
        onSubmit={async ([valueA, valueB], type) => {
          const promise = payment.mutateAsync(
            type === 'buy'
              ? {
                  amountIn: etherToWei(valueA),
                  amountOut: etherToWei(valueB),
                  tokenAddressFrom: dexConfig.data.contractAddressWeth,
                  tokenAddressTo: token.data.address,
                }
              : {
                  amountIn: etherToWei(valueA),
                  amountOut: etherToWei(valueB),
                  tokenAddressFrom: token.data.address,
                  tokenAddressTo: dexConfig.data.contractAddressWeth,
                },
          );

          await toast.promise(promise, {
            loading: 'Await blockchain...',
            success: 'Successfully!',
            error: ({ message }) => message || 'Error when fetching',
          });
        }}
        disabled={payment.isPending}
      />

      {token.data.description !== '' && <MemeInfo />}
    </div>
  );
};
