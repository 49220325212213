import React, { MouseEventHandler } from 'react';
import { NavLink } from 'react-router-dom';

interface Properties {
  title: string;
  linkTo?: string;
  isActive?: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export const Item: React.FC<React.PropsWithChildren<Properties>> = ({
  children,
  title,
  linkTo,
  isActive,
  className = '',
  onClick,
}) => {
  return (
    <NavLink to={linkTo} onClick={onClick}>
      <div
        className={`w-[75px]
        flex flex-col items-center gap-1 p-3 cursor-pointer
        text-gray [&>*]:fill-gray
        ${isActive && '[&>*]:!text-white [&>*]:!fill-white'}

        ${className}
      `}
      >
        {children}
        <span className="text-xs font-medium">{title}</span>
      </div>
    </NavLink>
  );
};
