/* eslint-disable security/detect-object-injection */
import { FC, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Button } from '@components/index';
import { Dialog, DialogContent } from '@components/ui/dialog.tsx';
import { weiToEther } from '@shared/lib/number/format-number';
import BalanceDisplay from '@shared/ui/BalanceDisplay';
import { useQuery } from '@tanstack/react-query';
import BigNumber from 'bignumber.js';

import { MemeAPI } from '../Dex/entities/meme/api';

import { BuyInput } from './ui/BuyInput';
import { ToggleButtons } from './ui/ToggleButtons';
import { FormBuySellProperties, FormBuySellType, FormBuySellValues } from './type';

export const FormBuySell: FC<FormBuySellProperties> = ({
  inputs,
  initTab,
  price,
  disabled,
  isSearch,
  onSubmit,
  mode,
  autoFocus,
}) => {
  const [inputA, setInputA] = useState<string>('');
  const [inputB, setInputB] = useState<string>('');
  const [currentButtonIndex, setCurrentButtonIndex] = useState<number>(
    initTab === 'buy' ? 0 : 1,
  );
  const [focus, setFocus] = useState<null | number>(null);

  const inputABig = new BigNumber(inputA);
  const inputBBig = new BigNumber(inputB);

  const balance = useQuery(MemeAPI.getEthBalanceQueryOptions());

  const isBuyMode = currentButtonIndex === 0;
  const rendersInputs = isBuyMode ? inputs : Array.from(inputs).reverse();

  const handleSubmit = async () => {
    const values: FormBuySellValues = [inputA, inputB];
    const type: FormBuySellType = isBuyMode ? 'buy' : 'sell';
    try {
      await onSubmit(values, type);
      setInputA('');
      setInputB('');
    } catch (error) {
      console.error('Error Promise', error);
    }
  };

  const handleTabChange = (index: number) => {
    setCurrentButtonIndex(index);
    setInputA('');
    setInputB('');
  };

  const location = useLocation();
  const isOnSwapPage = location.pathname === '/dex/swap';

  const insufficientBalance = new BigNumber(
    inputs[currentButtonIndex].balance,
  ).isLessThan(inputABig);

  const isInsufficientEth = Number(weiToEther(balance.data.weiBalance.value)) < 0.1;

  const isSubmitDisabled =
    disabled || (isBuyMode ? inputBBig.toNumber() <= 0 : inputABig.toNumber() <= 0);

  return (
    <>
      <div className="max-w-container h-max mx-4 bg-container-light p-3 mt-5 rounded-[20px]">
        <ToggleButtons
          buttonIndex={currentButtonIndex}
          setButtonIndex={handleTabChange}
          isSearch={isSearch}
        />

        {rendersInputs.map((data, index) => {
          return (
            <BuyInput
              key={data.title}
              coinData={data}
              value={index ? inputB : inputA}
              onChange={(value) => {
                if (index) {
                  setInputB(value);
                  const calculatedA = isBuyMode
                    ? new BigNumber(value).multipliedBy(price)
                    : new BigNumber(value).dividedBy(price);
                  setInputA(calculatedA.toString());
                } else {
                  setInputA(value);
                  const calculatedB = isBuyMode
                    ? new BigNumber(value).dividedBy(price)
                    : new BigNumber(value).multipliedBy(price);
                  setInputB(calculatedB.toString());
                }
              }}
              disabled={disabled}
              critic={index ? undefined : insufficientBalance}
              inputProps={{
                onFocus: () => {
                  if (mode === 'modal') {
                    setFocus(index);
                  }
                },
                autoFocus: autoFocus === index && focus === null && mode === 'form',
              }}
              prefix={index === 0 ? '- ' : '+ '}
            />
          );
        })}

        {isInsufficientEth && !disabled && !isOnSwapPage && isBuyMode ? (
          <NavLink to="/dex/swap">
            <div className="mt-5">
              <Button rounded="2xl">Convert Dollar*</Button>
            </div>
          </NavLink>
        ) : insufficientBalance ? (
          <Button className="mt-5 h-[50px]" variant="critic-strong-down">
            Influence balance
          </Button>
        ) : (
          <Button
            className="mt-5 h-[50px] "
            rounded="2xl"
            onClick={handleSubmit}
            loading={disabled}
            disabled={isSubmitDisabled || insufficientBalance}
            variant={isBuyMode ? 'success-strong-down' : 'critic-strong-down'}
          >
            {(() => {
              return (
                <>
                  {isBuyMode ? 'Buy' : 'Sell'}{' '}
                  {isBuyMode ? (
                    <BalanceDisplay balance={Number(inputB)} big />
                  ) : (
                    <BalanceDisplay balance={Number(inputA)} big />
                  )}{' '}
                  {inputs[1].title}
                </>
              );
            })()}
          </Button>
        )}
      </div>

      {mode === 'modal' && (
        <Dialog
          modal={false}
          open={focus === 0 || focus === 1}
          onOpenChange={(state) => !state && setFocus(null)}
        >
          <DialogContent className="inset-0 left-0 top-0 translate-x-0 translate-y-0 p-0 m-0 pt-6">
            <FormBuySell
              mode="form"
              inputs={inputs}
              disabled={disabled}
              price={price}
              onSubmit={onSubmit}
              initTab={currentButtonIndex === 0 ? 'buy' : undefined}
              autoFocus={focus}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
