import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBackButtonTelegram } from '@shared/hooks/useBackButtonTelegram';
import { useQuery } from '@tanstack/react-query';

import { SkinsAPI } from '../entities/skins/api';
import { ISkin } from '../entities/skins/type';

import { BottomSheetSkin, Header, Skin } from './ui';

export const VipSkinsPage: React.FC = () => {
  const navigate = useNavigate();
  useBackButtonTelegram(() => navigate('/'));

  const [openSkinSheet, setOpenSkinSheet] = useState(false);
  const [vipSkin, setVipSkin] = useState<ISkin | null>(null);

  const skins = useQuery(SkinsAPI.getSkinsQueryOptions());

  const selectVipSkinHandler = (id: number): void => {
    const skin = skins.data.find((sk) => sk.id === id) || false;
    if (!skin) return;
    setOpenSkinSheet(true);
    setVipSkin(skin);
  };

  const filteredVipSkins = skins?.data?.filter(
    (skin) => skin.price !== undefined && skin.price !== null,
  );

  return (
    <div className="flex max-w-container mx-4 flex-col gap-3 min-h-full">
      <Header />
      {filteredVipSkins?.map((skin) => (
        <Skin
          key={skin.id}
          id={skin.id}
          img={skin.image}
          multiplicator={skin.id - 5}
          title={skin.name}
          lvl={skin.id.toString()}
          boost={skin.from.toString()}
          stars={skin.price}
          multiplication={skin.id - 5}
          expandSkin={() => selectVipSkinHandler(skin.id)}
        />
      ))}

      <BottomSheetSkin
        open={openSkinSheet}
        skin={vipSkin}
        onClose={() => setOpenSkinSheet(false)}
      />
    </div>
  );
};
