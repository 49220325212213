import { FC } from 'react';
import { DoubleGreenUp } from '@shared/ui';
import BalanceDisplay from '@shared/ui/BalanceDisplay';
import { Gem } from '@shared/ui/Gem';

interface item {
  img: string;
  title: string;
  trand: number;
  lvl: number;
  balance: number;
  openAtLevel: number;
  isAvailable: boolean;
  onClick: () => void;
}

export const Item: FC<item> = ({
  img,
  title,
  trand,
  lvl,
  balance,
  openAtLevel,
  isAvailable,
  onClick,
}) => {
  return (
    <div
      className={`flex flex-col justify-between border-1 border-solid border-faint-weak rounded-2-5xl overflow-hidden w-full
        ${isAvailable ? '' : 'opacity-60 grayscale'}
    `}
      onClick={isAvailable ? onClick : undefined}
    >
      <img src={img} className="block w-full h-[calc(50vw-32px)] object-cover -z-10 " />
      <div className="py-4 h-max  w-full px-3 inline-block mt-[-36px] bg-gradient-to-b from-[#14151b05] from-10% via-[#14151b] via-30% to-[#14151b]">
        <p className="font-bold">{title}</p>
        <div className="flex gap-1 items-center">
          <p className="font-semibold text-white text-sm relative flex items-center gap-1">
            <Gem></Gem>
            <div className="absolute bottom-0 left-[10px]">
              <DoubleGreenUp />
            </div>
            <div>
              +
              <BalanceDisplay balance={trand || 0} big />
            </div>
          </p>
          <p className="text-gray text-sm font-semibold min-w-max">1h Mining</p>
        </div>

        {isAvailable ? (
          <div className="flex justify-between mt-2">
            <div className=" bg-accent-strong py-1 px-2 text-xs flex items-center rounded-full">
              <BalanceDisplay balance={balance || 0} big />
              <Gem></Gem>
            </div>

            <div className="bg-faint-weak py-1 px-2 rounded-full text-xs font-semibold">
              Lvl {lvl}
            </div>
          </div>
        ) : (
          <div className="flex flex-row-reverse mt-2 filter">
            <div className="bg-faint-weak py-1 px-2 rounded-full text-xs font-semibold">
              Need {openAtLevel} Lvl
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
