import { useEffect, useState } from 'react';
import { Button } from '@components/index';
import { Drawer, DrawerContent } from '@components/ui/drawer';
import { MeAPI } from '@entities/me/api';
import { BoostsAPI } from '@pages/MiningBoosts/entities/api';
import { IBoost } from '@pages/MiningBoosts/entities/type';
import { useQuery } from '@tanstack/react-query';

import * as levelsAvatar from '../../../public/images/skins_avatar';

const LVL = 'current_lvl';

export const LevelUp = () => {
  const [openLvl, setOpenLvl] = useState<number | null>(null);
  const [avatar, setAvatar] = useState<string | null>(null);
  const [boosts, setBoosts] = useState<IBoost[]>([]);

  const { data: profile, isLoading: isProfileLoading } = useQuery(
    MeAPI.getMeQueryOptions(),
  );
  const { data: boostsList, isLoading: isBoostsLoading } = useQuery(
    BoostsAPI.getBoostsQueryOptions(),
  );

  const currentLvl = profile?.level.currentUserLevel.id;

  useEffect(() => {
    const lvlStorage = localStorage.getItem(LVL);
    if (lvlStorage && Number(lvlStorage) !== currentLvl) {
      setOpenLvl(currentLvl);
    } else if (!lvlStorage) {
      localStorage.setItem(LVL, String(currentLvl));
    }
  }, [currentLvl]);

  useEffect(() => {
    if (openLvl !== null) {
      setAvatar(`lvl${openLvl}`);
      if (boostsList) {
        const availableBoosts = boostsList.boosts.filter(
          ({ opensAtLevel, available, categoryId }) =>
            opensAtLevel === openLvl &&
            available === true &&
            (categoryId === 0 || categoryId === 1),
        );
        setBoosts(availableBoosts);
      }
    }
  }, [openLvl, boostsList]);

  const handleClose = () => {
    if (openLvl !== null) {
      localStorage.setItem(LVL, String(openLvl));
    }
    setBoosts([]);
    setOpenLvl(null);
  };

  if (isProfileLoading || isBoostsLoading) return null;

  if (openLvl !== null) {
    return (
      <Drawer open={openLvl !== null} onOpenChange={handleClose}>
        <DrawerContent className="bottom-sheet-white z-[9999] bg-container-dark rounded-t-[32px] gap-2 pb-5  px-4">
          <div className="w-[200px] h-[200px]">
            <img
              src={levelsAvatar[avatar as keyof typeof levelsAvatar]}
              className="w-[200px] h-[200px] rounded-[30px]"
              alt=""
            />
          </div>
          <h3 className="mt-2 text-[22px] font-semibold">Level Up!</h3>
          <div className="text-sm text-gray-light mt-2">
            Congratulations! You got Lvl. {openLvl} —{' '}
            {profile?.level.currentUserLevel.name}.
          </div>
          {boosts.length > 0 && (
            <>
              <h4 className="text-sm font-semibold text-white mt-2">
                New Mining Boosts Available:
              </h4>
              <div className="max-w-[99vw] flex items-center overflow-x-auto space-x-1 mt-3 scrollbar-none">
                {boosts.map((boost) => (
                  <div
                    key={boost.id}
                    className="flex flex-col items-center gap-y-2 flex-shrink-0"
                  >
                    <img
                      className="w-[70px] h-[70px] rounded-2xl border border-solid border-faint-weak"
                      alt="Boost"
                      src={boost.url}
                    />
                    <p className="text-xs text-gray font-semibold">{boost.name}</p>
                  </div>
                ))}
              </div>
            </>
          )}
          <Button className="mt-8" onClick={handleClose}>
            Cool
          </Button>
        </DrawerContent>
      </Drawer>
    );
  }

  return null;
};
