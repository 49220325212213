import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components/index';
import { Drawer, DrawerContent } from '@components/ui/drawer';
import { MeAPI } from '@entities/me/api';
import { TasksAPI } from '@pages/Earn/entities/api';
import { TaskWrap } from '@pages/Earn/entities/type';
import { useBackButtonTelegram } from '@shared/hooks/useBackButtonTelegram';
import { formatNumber } from '@shared/lib/number/format-number';
import { Bonus } from '@shared/ui';
import { TonButton } from '@shared/ui/TonButton';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTonConnectUI } from '@tonconnect/ui-react';
import WebApp from '@twa-dev/sdk';

import 'react-spring-bottom-sheet/dist/style.css';

import buyImg from '/images/icons/buy-task.svg';
import closeImg from '/images/icons/close.svg';
import convertImg from '/images/icons/convert-task.svg';
import createImg from '/images/icons/create-task.svg';
import friendsImg from '/images/icons/friends_white.svg';
import sellImg from '/images/icons/sell-task.svg';
import storyImg from '/images/icons/story.svg';
import tgImg from '/images/icons/tg.svg';
import tonImg from '/images/icons/ton-task.svg';
import _1Img from '/images/story/1.png';
import _2Img from '/images/story/2.png';
import _3Img from '/images/story/3.png';
import _4Img from '/images/story/4.png';
import _5Img from '/images/story/5.png';
import _6Img from '/images/story/6.png';
import _7Img from '/images/story/7.png';
import _8Img from '/images/story/8.png';
import _9Img from '/images/story/9.png';
import _10Img from '/images/story/10.png';
import _11Img from '/images/story/11.png';
import _12Img from '/images/story/12.png';
import _13Img from '/images/story/13.png';
import _14Img from '/images/story/14.png';
import _15Img from '/images/story/15.png';
import _16Img from '/images/story/16.png';
import _17Img from '/images/story/17.png';
import _18Img from '/images/story/18.png';
import _19Img from '/images/story/19.png';
import _20Img from '/images/story/20.png';

const STORY = 'isPublishedStory';

const storyImgs = {
  1: _1Img,
  2: _2Img,
  3: _3Img,
  4: _4Img,
  5: _5Img,
  6: _6Img,
  7: _7Img,
  8: _8Img,
  9: _9Img,
  10: _10Img,
  11: _11Img,
  12: _12Img,
  13: _13Img,
  14: _14Img,
  15: _15Img,
  16: _16Img,
  17: _17Img,
  18: _18Img,
  19: _19Img,
  20: _20Img,
};

interface Properties {
  data: TaskWrap;
  open: boolean;
  onClose(): void;
}

export const BottomSheetTgSubScribe: FC<Properties> = ({ data, open, onClose }) => {
  const [isPublishedStory, setIsPublishedStory] = useState<boolean>(
    !localStorage.getItem(STORY),
  );

  const qc = useQueryClient();
  const navigate = useNavigate();
  const { data: profile } = useQuery(MeAPI.getMeQueryOptions());
  const [tonConnectUI] = useTonConnectUI();
  const disTon = data?.task?.linkUrl === 'connectTon' ? !tonConnectUI.connected : false;
  const disStory = data?.task?.linkUrl === ':share' ? isPublishedStory : false;

  useBackButtonTelegram(onClose);

  useEffect(() => {
    if (data.complate === false) {
      setIsPublishedStory(true);
    }
  }, [data]);

  const icons: Record<string, string> = {
    tg: tgImg,
    ':invite': friendsImg,
    sellToken: sellImg,
    buyToken: buyImg,
    createToken: createImg,
    convertCrystals: convertImg,
    connectTon: tonImg,
    ':share': storyImg,
  };

  const links: Record<string, string> = {
    tg: tgImg,
    ':invite': friendsImg,
    sellToken: '/dex/newbies',
    buyToken: '/dex/newbies',
    createToken: '/dex/launch',
    convertCrystals: '/dex/swap',
  };

  const iconSource = icons[data?.task.linkUrl];

  const handleStartTask = () => {
    const linkUrl = links[data?.task.linkUrl];

    if (data?.task.linkUrl?.includes(':invite')) {
      WebApp.openTelegramLink(`https://t.me/share/url?url=${profile.inviteLink}`);
    } else if (data?.task.linkUrl?.includes(':share')) {
      WebApp.shareToStory(storyImgs[profile?.level.currentUserLevel.id], {
        text: 'Come join me!',
        // eslint-disable-next-line camelcase
        widget_link: {
          url: profile?.inviteLink,
          name: 'Start Journey',
        },
      });
      localStorage.setItem(STORY, 'false');
      setIsPublishedStory(false);
    } else {
      navigate(linkUrl);
    }
  };

  const checkTask = useMutation({
    mutationFn: (id: string) => TasksAPI.makeClaim(id),
    onSuccess: () => {
      toast.success('Task successfully completed!');
      onClose();
    },
    onSettled: () => {
      [
        MeAPI.getMeQueryOptions().queryKey,
        TasksAPI.getTasksListQueryOptions().queryKey,
      ].map((key) =>
        qc.invalidateQueries({
          queryKey: key,
        }),
      );
    },
    onError: () => {
      toast.error('Task not completed :(');
      onClose();
    },
  });

  return (
    <Drawer open={open} onOpenChange={onClose}>
      <DrawerContent className="flex flex-col items-center gap-y-5 px-4 bottom-sheet-white bg-container-dark rounded-t-[32px]  pb-12">
        <button
          className="text-black bg-base-container w-[30px] h-[30px] rounded-full flex justify-center items-center
            absolute top-[23px] right-[23px] z-[51]"
          onClick={onClose}
        >
          <img src={closeImg} />
        </button>

        <div
          className="w-[78px] h-[78px] rounded-2xl flex items-center justify-center border-1 border-1"
          style={{
            boxShadow: `0px 0px 20px 0px ${data?.task.color}99`,
            background: `linear-gradient(150deg, #1A1B22 0%, #1A1B22 40%, ${data?.task.color}66 100%)`,
            border: `1px solid ${data?.task.color}`,
          }}
        >
          <img className="w-[50px] h-[50px]" src={iconSource} alt={data?.task.linkUrl} />
        </div>
        <h3 className="text-center text-xl font-bold">{data?.task.title}</h3>

        <div className="w-full flex flex-col gap-y-6">
          <div className=" h-[48px]">
            {data?.task.linkUrl === 'connectTon' ? (
              <TonButton>Connect Wallet</TonButton>
            ) : (
              <Button variant="blue-purple-gradient" onClick={handleStartTask}>
                {data?.task.linkText}
              </Button>
            )}
          </div>

          <p className="text-gray text-center text-xl font-medium">
            and then claim reward
          </p>

          {data?.complate ? (
            <div className="h-[48px]">
              <Button variant="container-light" className="h-full">
                Claimed
              </Button>
            </div>
          ) : (
            <div className="h-[48px]">
              <Button
                className="h-full"
                disabled={disTon || disStory}
                loading={checkTask.isPending}
                onClick={() => checkTask.mutate(data?.task.id)}
              >
                Claim{' '}
                <Bonus color="blue">
                  +{formatNumber(Number(data?.task.reward), true)}
                </Bonus>
              </Button>
            </div>
          )}
        </div>
      </DrawerContent>
    </Drawer>
  );
};
