import { FC } from 'react';

interface Properties {
  size?: number;
}

const DEFAULT_GEM_SIZE = 14;

export const Gem: FC<Properties> = ({ size = DEFAULT_GEM_SIZE }) => {
  return (
    <i
      className="bg-[url(/images/gif/gem.gif)] text-transparent bg-center bg-no-repeat bg-transparent bg-contain"
      style={{
        display: size ? 'inline-block' : 'inline',
        width: size ? `${size}px` : 'auto',
        height: size ? `${size}px` : 'auto',
      }}
    />
  );
};
