import { FC } from 'react';

export const Pitchdeck: FC = () => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.61784 13.6673C2.15951 13.6673 1.76714 13.5041 1.44076 13.1777C1.11437 12.8513 0.951172 12.459 0.951172 12.0007V2.00065C0.951172 1.54232 1.11437 1.14996 1.44076 0.823568C1.76714 0.497179 2.15951 0.333984 2.61784 0.333984H15.9512C16.4095 0.333984 16.8019 0.497179 17.1283 0.823568C17.4546 1.14996 17.6178 1.54232 17.6178 2.00065V12.0007C17.6178 12.459 17.4546 12.8513 17.1283 13.1777C16.8019 13.5041 16.4095 13.6673 15.9512 13.6673H2.61784ZM4.597 12.0007H15.9512V2.00065H12.347L14.2845 9.50065L4.597 12.0007Z"
        fill="url(#paint0_linear_8899_17687)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8899_17687"
          x1="4.98963"
          y1="0.333986"
          x2="18.3585"
          y2="9.46474"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#587BFF" />
          <stop offset="1" stopColor="#B518FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
