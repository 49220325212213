import { FC, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import { Button } from '@components/index';
import { MeAPI } from '@entities/me/api';
import { SkinsAPI } from '@pages/Profile/entities/skins/api';
import { useQuery } from '@tanstack/react-query';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './index.scss';

import { Boost } from '../BoostBlock';
import { SKINS } from '../consts';

import arrowSliderImg from '/images/icons/arrow_slider.svg';
import arrowPreviousSliderImg from '/images/icons/arrow_slider_prev.svg';
import starImg from '/images/icons/star.svg';

function SampleNextArrow(properties) {
  const { className, style, onClick } = properties;
  return (
    <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
      <img src={arrowSliderImg} alt="Next Arrow" />
    </div>
  );
}

function SamplePreviousArrow(properties) {
  const { className, style, onClick } = properties;
  return (
    <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
      <img src={arrowPreviousSliderImg} alt="Previous Arrow" />
    </div>
  );
}

export const SkinsCarusel: FC = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const {
    data: skinsData,
    isLoading: isSkinsLoading,
    isError: isSkinsError,
  } = useQuery(SkinsAPI.getSkinsQueryOptions());
  const {
    data: profileData,
    isLoading: isProfileLoading,
    isError: isProfileError,
  } = useQuery(MeAPI.getMeQueryOptions());

  const currentSkinIndex = profileData?.level?.currentUserSkinIndex ?? 0;
  const currentSkin = Math.min(currentSkinIndex, 10);

  useEffect(() => {
    setActiveIndex(currentSkin);
  }, [currentSkin]);

  if (isSkinsLoading || isProfileLoading) {
    return <div>Loading...</div>;
  }

  if (isSkinsError || isProfileError) {
    return <div>Error loading data.</div>;
  }

  return (
    <div className="px-4 mt-5">
      <div className="relative -mx-4 overflow-hidden">
        <Slider
          dots={false}
          infinite={false}
          nextArrow={<SampleNextArrow />}
          prevArrow={<SamplePreviousArrow />}
          slidesToScroll={1}
          slidesToShow={1}
          initialSlide={currentSkin > 9 ? 9 : currentSkin}
          speed={200}
          afterChange={(index) => setActiveIndex(index)}
        >
          {skinsData?.map((skin) => (
            <div key={skin.id} className="flex justify-center">
              <img src={skin.image} alt={skin.name} className="h-[40vh]" />
            </div>
          ))}
        </Slider>

        <div className="w-40 h-14 m-auto rounded-2-5xl bg-gradient-to-r from-container-light to-base-container font-bold flex items-center justify-center gap-2 relative mt-[-60px]">
          <Boost boost={SKINS[activeIndex + 1 > 10 ? 10 : activeIndex + 1]?.boost || 1} />
          <div>
            <p>{activeIndex + 1 > 10 ? 10 : activeIndex + 1} Skin</p>
            <p className="text-gray-light text-xs">Mining boost</p>
          </div>
        </div>
      </div>

      <div className="w-full h-[48px] mt-5">
        <NavLink to="vip">
          <Button>
            <img src={starImg} alt="Upgrade Icon" />
            Upgrade my skin
          </Button>
        </NavLink>
      </div>
    </div>
  );
};
