import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
export function safeParse<T = Record<string, unknown>>(
  startParameter: string,
): Partial<T> {
  try {
    const decoded = atob(startParameter);
    return JSON.parse(decoded) as T;
  } catch (error) {
    console.error('Failed to parse start_param:', error);
    return {};
  }
}

export function removeLoader() {
  document.querySelector('#loader')?.remove();
  return true;
}
