import { useState } from 'react';
import { useRouteError } from 'react-router-dom';
import { Button } from '@components/index';

interface RouteError {
  statusText?: string;
  message?: string;
  stack?: string;
}

export function ErrorBoundary() {
  const error = useRouteError() as RouteError;
  const [more, setMore] = useState<boolean>(false);

  if (error) {
    console.info('error', error);
  }

  return (
    <div className="max-w-container mx-4 p-4 rounded-2xl bg-container-light">
      <h1 className="text-xl font-semibold text-critic mb-3">Something went wrong</h1>
      <p className="mb-3">
        {error?.statusText} {error?.message}
      </p>
      <Button onClick={() => setMore(!more)} className="mb-3">
        View {more ? 'less' : 'more'}
      </Button>
      <p className={`${more ? 'block' : 'hidden'} break-words`}>{error?.stack}</p>
    </div>
  );
}
