import { FC } from 'react';
import { DollarIcon } from '@shared/icons/Dollar';
import { DollarWhiteIcon } from '@shared/icons/DollarWhite';
import { Gem } from '@shared/ui';

export const Rate: FC<{ isMini?: boolean }> = ({ isMini }) => {
  return (
    <>
      {isMini ? (
        <div className="flex items-center justify-center gap-1 font-semibold text-2xs text-gray-light bg-faint-weak w-32 rounded-2-5xl">
          1 <DollarWhiteIcon width={11} /> = 10,000 <Gem size={13} />
        </div>
      ) : (
        <div className="flex items-center justify-center gap-1 font-semibold text-[17px] bg-faint-weak w-40 h-9 mx-auto rounded-2-5xl mt-5">
          1 <DollarIcon width={18} /> = 10,000 <Gem size={15} />
        </div>
      )}
    </>
  );
};
