import { ChangeEvent, FC } from 'react';

interface Properties {
  value: string;
  placeholder?: string;
  textCenter?: string;
  color?: 'faint-weak' | 'critic' | 'container';
  pl?: '3' | '10';
  rows?: number;
  onChange(value: string): void;
}

export const Textarea: FC<Properties> = ({
  value,
  onChange,
  placeholder = '',
  pl = '3',
  rows = 3,
  textCenter = 'false',
  color = 'faint-weak',
}) => {
  const onChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>): void =>
    onChange(event.target.value);

  const styleHash = {
    'faint-weak':
      'bg-faint-weak text-gray focus:border-1 focus:border-solid focus:border-accent-strong-up',
    critic:
      'bg-critic-dark text-critic-strong focus:border-1 focus:border-solid focus:border-critic',
    container:
      'bg-base-container text-white focus:border-1 focus:border-solid focus:border-accent-strong-up',
  };

  const paddingHash = {
    '3': 'pl-3',
    '10': 'pl-10',
  };

  const textCenterHash = {
    true: 'text-center',
    false: 'text-start',
  };

  return (
    <textarea
      value={value}
      placeholder={placeholder}
      rows={rows}
      className={`w-full h-full ${paddingHash[pl]} ${styleHash[color]}
        ${textCenterHash[textCenter]} rounded-xl outline-none box-border pt-2`}
      onChange={onChangeHandler}
    />
  );
};
