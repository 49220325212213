import { useEffect, useState } from 'react';

export const useVirtualKeyBoard = () => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const callback = (event) => {
      const VIEWPORT_VS_CLIENT_HEIGHT_RATIO = 0.75;
      if (
        (event.target.height * event.target.scale) / window.screen.height <
        VIEWPORT_VS_CLIENT_HEIGHT_RATIO
      ) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    };
    if ('visualViewport' in window && window.ontouchstart !== undefined) {
      window.visualViewport.addEventListener('resize', callback);
    }
    return () => window.visualViewport.removeEventListener('resize', callback);
  }, []);

  return {
    isOpen,
  };
};
