import { FC } from 'react';
import { Gem } from '@shared/ui/Gem';
import { GradientPatch } from '@widgets/GradientPatch';

import { Avatar } from '../Avatar';
import BalanceDisplay from '../BalanceDisplay';

import friendsImg from '/images/icons/friends.svg';

interface Properties {
  telegramID: number;
  name: string;
  lvl: number;
  friends: number;
  balance: string;
}

export const Friend: FC<Properties> = ({ telegramID, name, lvl, friends, balance }) => {
  const url = `${import.meta.env.VITE_API_URL}/photo/${telegramID}`;

  return (
    <div className="flex items-center gap-4 relative border-b-[1px] border-b-solid border-b-base-container py-4 w-full">
      <div className="w-10 h-10">
        <Avatar url={url} />
      </div>
      <div>
        <p className="text-base font-semibold">{name}</p>
        <div className="flex items-center gap-2">
          <GradientPatch>
            {lvl}
            lvl
          </GradientPatch>
          <div className="flex items-center gap-1 font-medium">
            <img src={friendsImg} />
            <BalanceDisplay balance={friends} f={0} big />
          </div>
        </div>
      </div>

      <div className="absolute right-0 flex items-center gap-1 font-semibold">
        <Gem></Gem>
        <BalanceDisplay balance={Number(balance)} f={0} big />
      </div>
    </div>
  );
};
