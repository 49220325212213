import { FC, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { NavLink } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';

import { MemeAPI } from '../../entities/meme/api';
import { MemeCard } from '../../ui/MemeCard';

import { ToggleButtons } from './ui/ToggleButton';
import { Heading } from './ui';

const categories = ['New', 'Top 100'];

export const NewbiesPage: FC = () => {
  const [category, setCategory] = useState<number>(0);

  const top = category === 1 ? 'true' : 'false';

  const meme = useInfiniteQuery(MemeAPI.getTokensInfiniteQueryOptions(top));

  return (
    <div className="px-4 flex flex-col gap-3">
      <Heading />

      <ToggleButtons
        buttons={categories}
        currentButtonIndex={category}
        setCurrentButtonIndex={setCategory}
      />

      {meme.isSuccess && meme.data.length === 0 && (
        <div className="text-sm text-gray flex justify-center">Memes empty</div>
      )}

      {meme.isPending && (
        <div className="text-sm text-gray flex justify-center">Memes loading...</div>
      )}

      <div className="flex flex-col">
        {meme.data?.map((coin, index) => (
          <NavLink key={coin.address} to={`/dex/pay/${coin.address}`}>
            {category === 1 ? (
              <MemeCard token={coin} topPlace={index + 1} />
            ) : (
              <MemeCard token={coin} />
            )}
          </NavLink>
        ))}
      </div>

      {meme.hasNextPage && (
        <InView
          key={meme.data.length}
          rootMargin="200px"
          triggerOnce
          onChange={() => meme.fetchNextPage()}
        />
      )}
    </div>
  );
};
