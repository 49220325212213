import React from 'react';

import swapImg from '/images/icons/swap_nav.svg';
import swapGray from '/images/icons/swapGray.svg';

interface Properties {
  isActive?: boolean;
}

export const ConvertIcon: React.FC<Properties> = ({ isActive }) => {
  return (
    <div>{isActive ? <img src={swapImg} alt="" /> : <img src={swapGray} alt="" />}</div>
  );
};
