import React from 'react';

import frens from '/images/icons/frens_nav.svg';
import frensGray from '/images/icons/frensGray.svg';

interface Properties {
  isActive?: boolean;
}

export const FrensIcon: React.FC<Properties> = ({ isActive }) => {
  return (
    <div>{isActive ? <img src={frens} alt="" /> : <img src={frensGray} alt="" />}</div>
  );
};
