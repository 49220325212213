import { FC } from 'react';

export const Telegram: FC = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.25" width="20" height="20" rx="10" fill="url(#paint0_linear_8264_22508)" />
    <rect
      x="0.666667"
      y="0.416667"
      width="19.1667"
      height="19.1667"
      rx="9.58333"
      stroke="white"
      strokeOpacity="0.15"
      strokeWidth="0.833333"
    />
    <g clipPath="url(#clip0_8264_22508)">
      <path
        d="M14.4324 5.84442C14.4324 5.84442 15.5214 5.41979 15.4307 6.45104C15.4004 6.87568 15.1282 8.36189 14.9164 9.96944L14.1904 14.7314C14.1904 14.7314 14.13 15.429 13.5854 15.5503C13.0409 15.6717 12.2242 15.1257 12.0729 15.0044C11.9519 14.9134 9.80422 13.5485 9.04796 12.8812C8.8362 12.6992 8.5942 12.3352 9.0782 11.9106L12.2544 8.87753C12.6174 8.51355 12.9804 7.66429 11.4679 8.69554L7.23297 11.577C7.23297 11.577 6.74897 11.8803 5.84149 11.6073L3.87524 11.0007C3.87524 11.0007 3.14924 10.5457 4.38948 10.0907C7.41448 8.66519 11.1352 7.2093 14.4324 5.84442Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_8264_22508"
        x1="10.25"
        y1="0"
        x2="10.25"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0098EA" />
        <stop offset="1" stopColor="#1493D7" />
      </linearGradient>
      <clipPath id="clip0_8264_22508">
        <rect
          width="12.7292"
          height="12.7292"
          fill="white"
          transform="translate(3.18359 4.16602)"
        />
      </clipPath>
    </defs>
  </svg>
);
