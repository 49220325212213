import { FC } from 'react';
import { Input } from '@components/ui/input';
import { Wallet } from '@shared/ui';

interface Properties {
  type: 'default' | 'error';
  value: string;
  setValue(value: string): void;
  autoFocus?: boolean;
  onFocus?(): void;
}

export const WalletInput: FC<Properties> = ({ type, value, setValue, onFocus }) => (
  <div className="relative h-[56px]">
    <div className="absolute left-3 h-full flex items-center">
      <Wallet color={type === 'error' ? '#FF9CA2' : '#878892'} />
    </div>
    <Input
      value={value}
      variant={type === 'error' ? 'critic' : 'faint-weak'}
      padding="10"
      placeholder="Your Wallet"
      onChange={(event_) => setValue(event_.target.value)}
      onFocus={onFocus} // Добавлено
    />
  </div>
);
