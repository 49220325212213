import { useNavigate } from 'react-router-dom';
import { Button } from '@components/index';

import { Badge } from './ui/Badge';

import bgImg from '/images/bg/mems-simulator.png';
import fireGif from '/images/gif/fire.gif';

export const MemsSimulator = () => {
  const navigate = useNavigate();

  return (
    <div
      className="relative px-4 py-3 mx-auto rounded-[20px] mb-4 overflow-hidden"
      style={{
        backgroundImage: 'linear-gradient(180deg, #B3FF86 0%, #27BF00 100%)',
      }}
      onClick={() => navigate('/dex/newbies')}
    >
      <img
        src={bgImg}
        alt="Bg"
        className="absolute top-4 right-[-13px] object-cover w-[153px] h-[129px] z-[1]"
      />

      <div className="relative z-[2]">
        <p className="text-faint-weak text-xl font-semibold mb-1">Mems Simulator</p>
        <p className="text-faint-weak text-sm leading-[14.4px] font-semibold mb-3">
          No Loss of Money <br /> It&apos;s like DEX Demo Account
        </p>
        <div className="flex gap-x-1 mb-[11px]">
          <Badge name="MemsChain" />
          <Badge name="DEX" />
          <Badge name="Wallet" />
          <Badge name="Launchpad" />
        </div>
        <Button variant="white" rounded="2xl" className="h-10">
          Explore
          <img className="w-5 h-5" src={fireGif} alt="fire" />
        </Button>
      </div>
    </div>
  );
};
