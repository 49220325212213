import { FC } from 'react';
import { DoubleGreenUp } from '@shared/ui';
import BalanceDisplay from '@shared/ui/BalanceDisplay';
import { Gem } from '@shared/ui/Gem';

interface Properties {
  id: number;
  coin: string;
  title: string;
  trand: number;
  lvl: number;
  balance: number;
  openAtLevel: number;
  isAvailable: boolean;
  onClick: () => void;
}

export const Coin: FC<Properties> = ({
  id,
  coin,
  title,
  trand,
  lvl,
  balance,
  openAtLevel,
  isAvailable,
  onClick,
}) => {
  const bgs = {
    78: './images/bg/coin-boost-maning.png',
    79: './images/bg/coin-boost-eth.png',
    80: './images/bg/coin-boost-usdt.png',
    81: './images/bg/coin-boost-ton.png',
    82: './images/bg/coin-boost-bnb.png',
    83: './images/bg/coin-boost-sol.png',
    84: './images/bg/coin-boost-pepe.png',
    85: './images/bg/coin-boost-shib.png',
    86: './images/bg/coin-boost-not.png',
    87: './images/bg/coin-boost-trx.png',
    88: './images/bg/coin-boost-doge.png',
  };

  return (
    <div
      className={`flex justify-between py-3 px-[14px] relative bg-no-repeat
    border-1 border-solid border-faint-weak rounded-2-5xl mb-[16px]
      ${isAvailable ? '' : 'opacity-60 grayscale'} ${lvl === 0 ? 'opacity-60' : ''}
    `}
      onClick={isAvailable ? onClick : undefined}
      style={{
        backgroundImage: `url(${bgs[id]}), linear-gradient(90deg, #24252D 0%, #1A1B22 78%)`,
      }}
    >
      <div className="flex items-center gap-x-2">
        <div>
          <img className="w-[50px] h-[50px]" src={coin} />
        </div>
        <div className="ml-[10px]">
          <p className="font-semibold text-white text-[17px]">{title}</p>
          <div className="flex gap-1">
            <p className="font-semibold text-white text-sm relative flex items-center gap-2">
              <Gem></Gem>
              <div className="absolute bottom-0 left-3">
                <DoubleGreenUp />
              </div>
              <div>
                +
                <BalanceDisplay balance={trand || 0} big />
              </div>
            </p>
            <p className="text-gray text-sm font-semibold">1h Mining</p>
          </div>
        </div>
      </div>
      {isAvailable ? (
        <div className="flex flex-col items-end gap-y-2">
          <div className=" bg-accent-strong rounded-xl py-[3px] px-[7px] font-medium text-xs flex items-center ">
            <BalanceDisplay balance={balance || 0} big />
            <Gem></Gem>
          </div>
          <div className=" text-xs font-semibold">Lvl {lvl}</div>
        </div>
      ) : (
        <div className="flex flex-row-reverse mt-2 filter">
          <div className="bg-faint-weak py-1 px-2 rounded-full text-xs font-semibold">
            Need {openAtLevel} Lvl
          </div>
        </div>
      )}
    </div>
  );
};
