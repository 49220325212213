import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '@components/index';
import { convertToSubscript, formatNumber } from '@shared/lib/number/format-number';
import { useQuery } from '@tanstack/react-query';
import { MemeAPI } from '@widgets/Dex/entities/meme/api';
import { CandleDto } from '@widgets/Dex/entities/meme/type';
import { createChart, CrosshairMode, IChartApi, UTCTimestamp } from 'lightweight-charts';

const TIMEFRAMES: CandleDto['time'][] = ['5m', '15m', '1h', '4h', '1d', '1w'];
const ACTIVE_BUTTON_COLOR = 'container-light';
const DEFAULT_BUTTON_COLOR = 'background';

export const Chart: React.FC<{ address: string }> = ({ address }) => {
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<CandleDto['time']>('1h');
  const candles = useQuery(
    MemeAPI.getCandleHistoryQueryOptions({ time: selectedTimeFrame, address }),
  );

  const lastCandle = useQuery(
    MemeAPI.getLastCandleQueryOptions({ time: selectedTimeFrame, address }),
  );

  const chartContainerReference = useRef<HTMLDivElement>(null);
  const chart = useRef<IChartApi | null>(null);
  const resizeObserver = useRef<ResizeObserver | null>(null);
  const candleSeries = useRef(null);

  const chartOptions = useMemo(
    () => ({
      width: chartContainerReference.current?.clientWidth || 0,
      height: chartContainerReference.current?.clientHeight || 0,
      localization: {
        locale: 'en-US',
        timeFormatter: (timestamp) => {
          const date = new Date(timestamp * 1000);
          const formattedDate = date.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          });
          const formattedTime = date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          });
          return `${formattedDate}, ${formattedTime}`.replace(',', '.');
        },
      },
      layout: {
        background: { color: '#090A0E' },
        textColor: 'rgba(255, 255, 255, 0.9)',
      },
      grid: {
        vertLines: {
          color: 'rgba(31, 35, 41, 0.3)',
        },
        horzLines: {
          color: 'rgba(31, 35, 41, 0.3)',
        },
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      timeScale: {
        borderColor: '#485c7b',
      },
      rightPriceScale: {
        autoScale: true,
        borderColor: '#485c7b',
        scaleMargins: {
          top: 0.8,
          bottom: 0.05,
        },
      },
    }),
    [],
  );

  useEffect(() => {
    if (candles.isLoading || candles.isError) return;

    if (!chart.current) {
      const minmove = candles.data.at(-1).close;

      chart.current = createChart(chartContainerReference.current, chartOptions);

      candleSeries.current = chart.current.addCandlestickSeries({
        upColor: '#33C95A',
        downColor: '#FF3833',
        borderDownColor: '#FF3833',
        borderUpColor: '#33C95A',
        wickDownColor: '#FF3833',
        wickUpColor: '#33C95A',
        priceFormat: {
          type: 'custom',
          formatter: (price: number) =>
            convertToSubscript(formatNumber(price, true, undefined, true)),
          minMove: minmove.length,
        },
      });
    }

    const newData = candles.data.map((c) => {
      return {
        time: (c.time / 1000) as UTCTimestamp,
        open: Number(c.open),
        high: Number(c.high),
        low: Number(c.low),
        close: Number(c.close),
      };
    });

    candleSeries.current.setData(newData);

    resizeObserver.current = new ResizeObserver((entries) => {
      const entry = entries[0];

      if (chart.current && entry) {
        const { width, height } = entry.contentRect;
        chart.current.applyOptions({
          width,
          height,
        });
      }
    });

    if (chartContainerReference.current) {
      resizeObserver.current.observe(chartContainerReference.current);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
    };
  }, [candles.data]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (lastCandle.data && candleSeries.current) {
        const { time, open, high, low, close } = lastCandle.data;

        candleSeries.current.update({
          time: (time / 1000) as UTCTimestamp,
          open: Number(open),
          high: Number(high),
          low: Number(low),
          close: Number(close),
        });
      }
    }, 2000);

    return () => clearInterval(timer);
  }, [lastCandle.data]);

  return (
    <div className="px-4">
      <div className="flex h-8 items-center gap-1 mb-3 border-solid border-1 border-container-light rounded-full">
        {TIMEFRAMES.map((tf, index) => (
          <Button
            key={index}
            variant={
              selectedTimeFrame === tf ? ACTIVE_BUTTON_COLOR : DEFAULT_BUTTON_COLOR
            }
            className={`rounded-full h-8 text-xs ${selectedTimeFrame === tf ? '' : 'text-gray-light bg-inherit'}`}
            onClick={() => setSelectedTimeFrame(tf)}
          >
            {tf}
          </Button>
        ))}
      </div>
      <div ref={chartContainerReference} className="w-full h-[40vh]" />
    </div>
  );
};
