import { FC, useState } from 'react';

import placeholder0 from '/images/avatar_placeholder/0.png';
import placeholder1 from '/images/avatar_placeholder/1.png';
import placeholder2 from '/images/avatar_placeholder/2.png';
import placeholder3 from '/images/avatar_placeholder/3.png';
import placeholder4 from '/images/avatar_placeholder/4.png';
import placeholder5 from '/images/avatar_placeholder/5.png';
import placeholder6 from '/images/avatar_placeholder/6.png';
import placeholder7 from '/images/avatar_placeholder/7.png';
import placeholder8 from '/images/avatar_placeholder/8.png';
import placeholder9 from '/images/avatar_placeholder/9.png';

const placeholders = [
  placeholder0,
  placeholder1,
  placeholder2,
  placeholder3,
  placeholder4,
  placeholder5,
  placeholder6,
  placeholder7,
  placeholder8,
  placeholder9,
];

interface Properties {
  url: string;
}

export const Avatar: FC<Properties> = ({ url }) => {
  const [imgSource, setImgSource] = useState<string | undefined>(url);

  return imgSource ? (
    <img
      src={imgSource}
      className="w-full h-full rounded-full"
      onError={() => setImgSource(undefined)}
    />
  ) : (
    <img src={placeholders[url.at(-1)]} className="w-max h-full rounded-full" />
  );
};
