import { FC } from 'react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const DEFAULT_WIDTH = 17;
const DEFAULT_HEIGHT = 16;
const DEFAULT_COLOR = '#878892';

export const Wallet: FC<Properties> = ({
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  color = DEFAULT_COLOR,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.16667 15.5C1.70833 15.5 1.31597 15.3368 0.989583 15.0104C0.663194 14.684 0.5 14.2917 0.5 13.8333V2.16667C0.5 1.70833 0.663194 1.31597 0.989583 0.989583C1.31597 0.663194 1.70833 0.5 2.16667 0.5H13.8333C14.2917 0.5 14.684 0.663194 15.0104 0.989583C15.3368 1.31597 15.5 1.70833 15.5 2.16667H8.83333C7.84722 2.16667 7.04514 2.47569 6.42708 3.09375C5.80903 3.71181 5.5 4.51389 5.5 5.5V10.5C5.5 11.4861 5.80903 12.2882 6.42708 12.9062C7.04514 13.5243 7.84722 13.8333 8.83333 13.8333H15.5C15.5 14.2917 15.3368 14.684 15.0104 15.0104C14.684 15.3368 14.2917 15.5 13.8333 15.5H2.16667ZM8.83333 12.1667C8.375 12.1667 7.98264 12.0035 7.65625 11.6771C7.32986 11.3507 7.16667 10.9583 7.16667 10.5V5.5C7.16667 5.04167 7.32986 4.64931 7.65625 4.32292C7.98264 3.99653 8.375 3.83333 8.83333 3.83333H14.6667C15.125 3.83333 15.5174 3.99653 15.8438 4.32292C16.1701 4.64931 16.3333 5.04167 16.3333 5.5V10.5C16.3333 10.9583 16.1701 11.3507 15.8438 11.6771C15.5174 12.0035 15.125 12.1667 14.6667 12.1667H8.83333ZM11.3333 9.25C11.6944 9.25 11.9931 9.13194 12.2292 8.89583C12.4653 8.65972 12.5833 8.36111 12.5833 8C12.5833 7.63889 12.4653 7.34028 12.2292 7.10417C11.9931 6.86806 11.6944 6.75 11.3333 6.75C10.9722 6.75 10.6736 6.86806 10.4375 7.10417C10.2014 7.34028 10.0833 7.63889 10.0833 8C10.0833 8.36111 10.2014 8.65972 10.4375 8.89583C10.6736 9.13194 10.9722 9.25 11.3333 9.25Z"
      fill={color}
    />
  </svg>
);
