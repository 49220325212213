import { FC, useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import { Button } from '@components/index';
import { MeAPI } from '@entities/me/api';
import BalanceDisplay from '@shared/ui/BalanceDisplay';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import JSConfetti from 'js-confetti';

import { convertMillisecondsToTime } from '../../../../utils/time';
import { MiningAPI } from '../../entities/mining/api';

import confetiGif from '/images/gif/confeti.gif';
import gearImg from '/images/gif/gear.gif';
import money from '/images/gif/money.gif';

const jsConfetti = new JSConfetti();

export const Mining: FC = () => {
  const qc = useQueryClient();

  const profile = useQuery(MeAPI.getMeQueryOptions());
  const { data, dataUpdatedAt, ...mining } = useQuery({
    ...MiningAPI.getMiningQueryOptions(),
  });

  const claim = useMutation({
    mutationFn: MiningAPI.makeClaim,
    onSuccess: () => {
      jsConfetti.addConfetti({
        emojis: ['🌟', '⚡️', '💥', '✨', '🔶', '⭐️'],
      });
    },
    onMutate: async () => {
      await qc.cancelQueries({
        queryKey: MiningAPI.getMiningQueryOptions().queryKey,
      });

      const previousTodos = qc.getQueryData(MiningAPI.getMiningQueryOptions().queryKey);

      qc.setQueryData(MiningAPI.getMiningQueryOptions().queryKey, {
        time: Date.now(),
        current: null,
      });

      return { previousTodos };
    },
    onSettled: () => {
      [
        MiningAPI.getMiningQueryOptions().queryKey,
        MeAPI.getMeQueryOptions().queryKey,
      ].map((keys) =>
        qc.invalidateQueries({
          queryKey: keys,
        }),
      );
    },
  });

  const start = useMutation({
    mutationFn: MiningAPI.startMining,
    onMutate: async () => {
      await qc.cancelQueries({
        queryKey: MiningAPI.getMiningQueryOptions().queryKey,
      });

      const previousTodos = qc.getQueryData(MiningAPI.getMiningQueryOptions().queryKey);

      qc.setQueryData(MiningAPI.getMiningQueryOptions().queryKey, (old) => ({
        time: Date.now(),
        current: {
          ...old.current,
          claimed: false,
          expires: +new Date(Date.now() + profile.data.mining.holdTime),
          created: Date.now().toString(),
          reward: 0,
        },
      }));

      return { previousTodos };
    },
    onError: (error, _, context) => {
      qc.setQueryData(MiningAPI.getMiningQueryOptions().queryKey, context.previousTodos);
      console.error(error);
    },
    onSettled: () => {
      qc.invalidateQueries({
        queryKey: MiningAPI.getMiningQueryOptions().queryKey,
      });
    },
  });

  const local = Date.now();
  const deltaTime = dataUpdatedAt - data.time;
  const status = data.current;
  const startTime = +(status?.created ?? 0) + deltaTime;
  const expires = +(status?.expires ?? 0) + deltaTime;
  const fullReward = Number(status?.reward) ?? 0;
  const persent = status ? (local - startTime) / (expires - startTime) : 0;
  const reward = expires > local ? Number((fullReward * persent).toFixed(0)) : fullReward;

  const { totalSeconds, restart } = useTimer({
    expiryTimestamp: new Date(expires),
    onExpire: mining.refetch,
    autoStart: true,
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    expires > 0 && restart(new Date(expires));
  }, [data.current?.created, expires, restart]);

  if (!data.current) {
    return (
      <div className="  h-[48px] mb-4">
        <Button
          variant="accent-strong"
          rounded="2xl"
          className="h-full"
          onClick={() => start.mutate()}
        >
          <img src={money} alt="" className="w-7 h-7" />
          Start mining RXP
        </Button>
      </div>
    );
  } else if (expires < local) {
    return (
      <div className=" h-[48px] mb-4">
        <Button
          variant="accent-strong"
          rounded="2xl"
          className="h-full"
          onClick={() => claim.mutate()}
        >
          <img className="w-[28px] h-[28px]" src={confetiGif} />
          Claim{' '}
          <span className="px-3 py-1 rounded-full bg-[#467AFF]">
            +<BalanceDisplay balance={reward} big /> RXP
          </span>
        </Button>
      </div>
    );
  }
  return (
    <div
      className={`h-[48px] border-1 border-solid border-gray-dark relative flex items-center
      mb-4  rounded-2xl py-[14px] px-4 overflow-hidden bg-transparent gap-3
    `}
    >
      <div
        className="absolute h-full bg-faint-weak left-0 top-0 -z-10 transition-all duration-500"
        style={{ width: `${persent * 100}%` }}
      ></div>
      <div className="flex items-center gap-1 font-semibold text-[15px]">
        <img src={gearImg} className="w-5 h-5" alt="" />
        Mining...
      </div>
      <div className="text-xs text-white/35 font-bold flex-grow">
        {convertMillisecondsToTime(totalSeconds * 1000)}
      </div>

      <div className="text-xs w-[80px] h-6">
        <Button variant="faint-weak" className="h-full">
          <div>
            +<BalanceDisplay balance={reward} big />
          </div>
        </Button>
      </div>
    </div>
  );
};
