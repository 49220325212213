import { clientAPI } from '@shared/api/client.ts';

export const SwapAPI = {
  swapCrystalToEth: async (crystalValue: number) => {
    return await clientAPI.post('v2/bridge/deposit', {
      json: { crystalAmount: crystalValue },
      timeout: false,
    });
  },

  swapEthToCrystal: async (weiValue: string) => {
    return await clientAPI.post('v2/bridge/withdraw', {
      json: { weiAmount: weiValue },
      timeout: false,
    });
  },
};
