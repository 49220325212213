import { clientAPI } from '@shared/api/client';
import { queryOptions } from '@tanstack/react-query';

import { Skins } from '../type';

export const SkinsAPI = {
  getSkinsAPI: () => clientAPI('game/getUserSkins').json<Skins>(),

  getSkinInvoice: (id: string) =>
    clientAPI(`game/getSkinsInvoice?id=${id}`).json<{ invoiceLink: string }>(),

  getSkinsQueryOptions: () =>
    queryOptions({
      queryKey: ['skins'],
      queryFn: () => SkinsAPI.getSkinsAPI(),
    }),
} as const;
