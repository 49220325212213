import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Decimal } from 'decimal.js';
import { z } from 'zod';

import { UploadImage } from './UploadImage';

import magicImg from '/images/gif/magic.gif';

const LIQUIDITY_PERCENTAGES = [100, 95, 90, 85, 80];

const ACTIVE_BUTTON_COLOR = 'accent-strong';
const DEFAULT_BUTTON_COLOR = 'faint-weak';

import toast from 'react-hot-toast';
import { NumericFormat } from 'react-number-format';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button } from '@components/index';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@components/ui/form';
import { Input } from '@components/ui/input';
import { DollarGreenIcon } from '@shared/icons/DollarGreen';
import {
  etherToWei,
  formatDecimal,
  formatNumber,
  weiToEther,
} from '@shared/lib/number/format-number';
import { Textarea } from '@shared/ui';
import { MemeAPI } from '@widgets/Dex/entities/meme/api';
import { BuyForTonResponse, TokenData } from '@widgets/Dex/entities/meme/type';

import { BuyModal } from '../BuyModal';
import { CalculatedBadge } from '../CalculatedBadge';

import { TelegramInput } from './TelegramInput';

export const FormLaunch: FC = () => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageError, setImageError] = useState<boolean>(false);
  const [indexError, setIndexError] = useState<boolean>(false);

  const [isBuyModalOpen, setBuyModalOpen] = useState(false);

  const [resetPreview] = useState<boolean>(false);
  const [selectedLiquidityIndex, setSelectedLiquidityIndex] = useState<number>(100);

  const [calculatedDexLiquidity, setCalculatedDexLiquidity] =
    useState<string>('1000000000');

  const [dexLiquidityInWei, setDexLiquidityInWei] = useState<string>(
    etherToWei(calculatedDexLiquidity),
  );

  useEffect(() => {
    setDexLiquidityInWei(etherToWei(calculatedDexLiquidity));
  }, [calculatedDexLiquidity]);

  const [liquidityRatio, setLiquidityRatio] = useState<string>('0.00001');
  const navigate = useNavigate();
  const balance = useQuery(MemeAPI.getEthBalanceQueryOptions());

  const qc = useQueryClient();

  const formSchema = z.object({
    tokenName: z
      .string()
      .min(4)
      .max(12)
      .refine((value) => /^[\dA-Za-z]+$/.test(value), {
        message: 'Token Name must contain only Latin letters and numbers',
      }),
    tokenSymbol: z
      .string()
      .min(2)
      .max(6)
      .refine((value) => /^[\dA-Za-z]+$/.test(value), {
        message: 'Token Symbol must contain only Latin letters and numbers',
      }),
    tokenDescription: z
      .string()
      .min(50)
      .max(500)
      .refine((value) => /^[\s\w!"#$%&'()*+,-./:;<=>?@[\\\]^`{|}~]*$/.test(value), {
        message:
          'Token Description must contain only Latin letters, numbers, spaces, and special characters',
      })
      .optional()
      .or(z.literal('')),
    raysNetworkTokenForDex: z
      .string()
      .refine(
        (value) =>
          !Number.isNaN(Number.parseFloat(value)) &&
          Number.parseFloat(value) > 0 &&
          value !== '',
        { message: 'Token Liquidity must be greater than 0' },
      )
      .refine(
        (value) => {
          const balanceNumber = Number(weiToEther(balance.data?.weiBalance?.value));
          return Number(value) < balanceNumber;
        },
        { message: 'Insufficient balance' },
      ),
    totalSupply: z
      .string()
      .refine(
        (value) =>
          !Number.isNaN(Number.parseFloat(value)) &&
          Number.parseFloat(value) > 0 &&
          value !== '',
        { message: 'Token Supply must be greater than 0' },
      ),
    telegram: z
      .string()
      .url()
      .refine((value) => value.startsWith('https://t.me/'), {
        message: 'Link should start with https://t.me/',
      })
      .optional()
      .or(z.literal('')),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      tokenName: '',
      tokenSymbol: '',
      tokenDescription: '',
      telegram: '',
      totalSupply: '1000000000',
      raysNetworkTokenForDex: '1000',
    },
  });

  const totalSupplyValue = form.watch('totalSupply');
  const raysNetworkTokenForDexValue = Number(form.watch('raysNetworkTokenForDex'));

  useEffect(() => {
    if (raysNetworkTokenForDexValue === 0 || Number(calculatedDexLiquidity) === 0) {
      setLiquidityRatio('0');
      return;
    }

    const result =
      raysNetworkTokenForDexValue && calculatedDexLiquidity
        ? Number(raysNetworkTokenForDexValue) / Number(calculatedDexLiquidity)
        : 0;

    setLiquidityRatio(formatDecimal(new Decimal(result)));
  }, [raysNetworkTokenForDexValue, totalSupplyValue, calculatedDexLiquidity]);

  const mutation = useMutation({
    mutationFn: (tokenData: TokenData) =>
      MemeAPI.createToken(tokenData, imageFile, dexLiquidityInWei),
    onMutate: async ({ raysNetworkTokenForDex }) => {
      await qc.cancelQueries({
        queryKey: MemeAPI.getEthBalanceQueryOptions().queryKey,
      });
      const previousData = qc.getQueryData(MemeAPI.getEthBalanceQueryOptions().queryKey);

      const optimisticPointsBalance =
        Number(balance.data?.weiBalance?.value) - Number(raysNetworkTokenForDex);

      qc.setQueryData(MemeAPI.getEthBalanceQueryOptions().queryKey, (previous) => ({
        ...previous,
        weiBalance: { value: optimisticPointsBalance },
      }));
      return { previousData };
    },
    onSuccess: () => {
      navigate('/dex/portfolio');
    },
    onSettled: () => {
      [
        MemeAPI.getEthBalanceQueryOptions().queryKey,
        MemeAPI.getUserTokensQueryOptions().queryKey,
      ].map((keys) =>
        qc.invalidateQueries({
          queryKey: keys,
        }),
      );
    },
    onError: (error, _, context) => {
      qc.setQueryData(MemeAPI.getEthBalanceQueryOptions().queryKey, context.previousData);

      console.error('Error creating token:', error);
    },
  });

  const handleLiquidityButtonClick = (percentage: number) => {
    setSelectedLiquidityIndex(percentage);

    const liquidityValue = Number(totalSupplyValue);

    if (!Number.isNaN(liquidityValue)) {
      const percentageValue = percentage / 100;
      const calculatedValue = liquidityValue * percentageValue;

      setCalculatedDexLiquidity(calculatedValue.toString());
    }
  };

  const validateForm = (): boolean => {
    let isValid = true;

    if (imageFile) {
      setImageError(false);
    } else {
      setImageError(true);
      isValid = false;
    }

    if (selectedLiquidityIndex < 0) {
      setIndexError(true);
      isValid = false;
    } else {
      setIndexError(false);
    }

    return isValid;
  };

  const handleValidateForm = () => {
    if (!validateForm()) {
      window.scrollTo(0, 0);
      return;
    }

    setBuyModalOpen(true);
  };

  const onSubmit = (data: TokenData, hash: string) => {
    if (!validateForm()) {
      window.scrollTo(0, 0);
      return;
    }

    const submitData = {
      ...data,
      totalSupply: etherToWei(data.totalSupply),
      raysNetworkTokenForDex: etherToWei(data.raysNetworkTokenForDex),
      imageFile,
      calculatedDexLiquidity: etherToWei(calculatedDexLiquidity),
      hash,
    };

    toast.promise(mutation.mutateAsync(submitData), {
      loading: 'Save in blockchain...',
      success: (
        <span>
          <div>Success create token!</div>
          <NavLink to="/dex/profile" className="underline">
            Go to view
          </NavLink>
        </span>
      ),
      error: (error) => `Error: ${error.name}`,
    });
  };

  useEffect(() => {
    if (Object.keys(form.formState.errors).length > 0) {
      window.scrollTo(0, 0);
    }
  }, [form.formState.errors]);

  return (
    <div className="max-w-container mx-4">
      {mutation.isPending && (
        <div className="absolute z-10 inset-0 bg-base-background/70 backdrop-blur-sm">
          <div className="sticky inset-1/2 top-[calc(35%-74px)] flex flex-col gap-y-4 items-center justify-center">
            <img src={magicImg} alt="Magic" className="w-32 h-32" />
            <p className="text-[32px] font-bold">Meme Creating...</p>
            <p className="text-center text-sm text-gray-light w-72">
              Please wait <span className="text-white">10s</span> while your Meme token is
              generated.
            </p>
          </div>
        </div>
      )}
      <UploadImage resetPreview={resetPreview} onFileSelect={setImageFile} />
      {imageError && (
        <p className="font-medium text-center text-xs text-red">Upload an image!</p>
      )}

      <div className="mt-4">
        <p className="font-semibold text-md mb-5">Basic Token Info</p>

        <Form {...form}>
          <div className="flex flex-col gap-y-4">
            <FormField
              control={form.control}
              name="tokenName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input variant="container" placeholder="Token Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="tokenSymbol"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      variant="container"
                      placeholder="Token Symbol"
                      value={field.value.toUpperCase().replace(/\s/g, '')}
                      onChange={(event_) =>
                        field.onChange(
                          event_.target.value.toUpperCase().replace(/\s/g, ''),
                        )
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="tokenDescription"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Textarea
                      color="container"
                      rows={3}
                      placeholder="Token Description"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="telegram"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <TelegramInput
                      {...field}
                      type={field.value ? 'success' : 'default'}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex flex-col gap-y-2">
              <p className="font-semibold flex items-center gap-x-1 text-md">
                Token Supply
              </p>
              <p className="text-gray text-xs">
                Enter the amount of tokens you want to mint
              </p>
              <FormField
                control={form.control}
                name="totalSupply"
                render={({ field: { onChange, ...field } }) => (
                  <FormItem>
                    <FormControl>
                      <NumericFormat
                        variant="container"
                        placeholder="Max 10,000,000,000,000,000"
                        thousandSeparator=","
                        allowNegative={false}
                        customInput={Input}
                        onValueChange={(target) => {
                          if (target.floatValue === undefined) {
                            onChange(0);
                          }

                          const liquidityValue = target.floatValue;

                          const percentageValue = selectedLiquidityIndex / 100;

                          const calculatedValue = liquidityValue * percentageValue;

                          setCalculatedDexLiquidity(calculatedValue.toString());

                          if (liquidityValue < 10_000_000_000_000_000) {
                            onChange(target.floatValue.toString());
                          }
                        }}
                        isAllowed={(values) => {
                          const { formattedValue, floatValue } = values;
                          return (
                            formattedValue === '' || floatValue <= 10_000_000_000_000_000
                          );
                        }}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex flex-col gap-y-2">
              <p className="font-semibold text-md flex items-center gap-x-1 leading-[19.2px]">
                Liquidity for DEX <DollarGreenIcon width={16} />
              </p>
              <p className="text-gray text-xs">
                Specify the share of token liquidity that will be sent on the DEX.
              </p>

              <div className="flex items-center flex-grow-1 gap-2">
                {LIQUIDITY_PERCENTAGES.map((percentage, index) => (
                  <Button
                    type="button"
                    key={index}
                    variant={
                      selectedLiquidityIndex === Number(percentage)
                        ? ACTIVE_BUTTON_COLOR
                        : DEFAULT_BUTTON_COLOR
                    }
                    onClick={() => handleLiquidityButtonClick(percentage)}
                  >
                    {percentage}%
                  </Button>
                ))}
              </div>
              {indexError && (
                <p className="font-medium text-xs text-critic">Choose a value!</p>
              )}

              <p className="text-gray text-xs">
                The remaining share of tokens will be on Your balance.
              </p>
            </div>
            <div className="flex flex-col gap-y-2">
              <p className="font-semibold flex items-center gap-x-1 text-md leading-[19.2px]">
                Token Liquidity
                <DollarGreenIcon width={16} />
              </p>
              <p className="text-gray text-xs">
                Add your Dollar* to provide Liquidity for your Token. Dollar* will be
                debited from your Balance.
              </p>
              <FormField
                control={form.control}
                name="raysNetworkTokenForDex"
                render={({ field: { onChange, ...field } }) => (
                  <FormItem>
                    <FormControl>
                      <NumericFormat
                        variant="container"
                        placeholder="Max 5,000,000"
                        thousandSeparator=","
                        allowNegative={false}
                        customInput={Input}
                        isAllowed={(values) => {
                          const { formattedValue, floatValue } = values;
                          return formattedValue === '' || floatValue <= 5_000_000;
                        }}
                        onValueChange={(target) => {
                          onChange(target.floatValue.toString());
                        }}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <CalculatedBadge value={formatNumber(Number(liquidityRatio))} />
            <div className="h-[56px] mt-4 mb-4">
              <Button
                className="h-full"
                type="submit"
                loading={mutation.isPending}
                onClick={form.handleSubmit(handleValidateForm)}
              >
                Create Token
              </Button>
            </div>
            <BuyModal
              modalOpen={isBuyModalOpen}
              setModalOpen={setBuyModalOpen}
              onPaymentSuccess={(response: BuyForTonResponse) => {
                setBuyModalOpen(false);
                onSubmit(form.getValues() as TokenData, response.hash);
              }}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};
