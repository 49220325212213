import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useBackButtonTelegram } from '@shared/hooks/useBackButtonTelegram';
import { useQuery } from '@tanstack/react-query';
import { MemeAPI } from '@widgets/Dex/entities/meme/api';
import { MemeItem } from '@widgets/Dex/ui/MemeItem';

import closeImg from '/images/icons/close.svg';
import searchImg from '/images/icons/search.svg';

export const SearchMeme = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const inputReference = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [parameters] = useSearchParams();

  const back = parameters.get('back');

  useEffect(() => {
    window.scrollBy(0, 0);
  }, []);

  useBackButtonTelegram(() => {
    navigate(back);
  });

  const {
    data: memeData,
    refetch,
    isFetched,
    isPending,
  } = useQuery({
    queryKey: ['find_meme_tokens', searchTerm],
    queryFn: () => MemeAPI.getFindTokens(searchTerm),
  });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const handleNavigate = (addr: string) => {
    navigate(`/dex/pay/${addr}`);
  };

  useEffect(() => {
    if (searchTerm) {
      refetch();
    }
  }, [searchTerm, refetch]);

  useEffect(() => {
    inputReference.current?.focus();
  }, []);

  return (
    <div className="max-w-container mx-4">
      <div className="flex items-center sticky top-[60px] bg-base-background py-1 gap-x-2 mb-5">
        <div
          className={
            'flex w-full items-center gap-x-2 rounded-full border-purple border border-1 py-[10px] px-3 '
          }
        >
          <img src={searchImg} alt="Search" />
          <input
            ref={inputReference}
            className="bg-transparent placeholder:text-gray-dark outline-none w-full"
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <div className="pr-2" onClick={() => navigate(back)}>
            <img className="w-4 h-4" src={closeImg} alt="" />
          </div>
        </div>
      </div>

      <div className="left-0 w-full bg-base-background h-sceen overflow-y-auto z-50">
        {isFetched && memeData.length === 0 && (
          <div className="text-sm text-gray justify-center flex">Not found</div>
        )}
        {isPending && (
          <div className="text-sm text-gray justify-center flex">Loading...</div>
        )}
        {memeData &&
          memeData.map((coin) => (
            <MemeItem
              key={coin.address}
              name={coin.name}
              symbol={coin.symbol}
              iconUrl={coin.iconUrl}
              percent={coin?.percents?.value || 0}
              exchangeRate={coin?.exchangeRate}
              upDown={coin?.upDown}
              onClick={() => handleNavigate(coin.address)}
            />
          ))}
      </div>
    </div>
  );
};
