import { FC } from 'react';
import toast from 'react-hot-toast';
import { MeAPI } from '@entities/me/api';
import { DollarIcon } from '@shared/icons/Dollar';
import { etherToWei, weiToEther } from '@shared/lib/number/format-number';
import { Gem } from '@shared/ui';
import { Rate } from '@shared/ui/Rate';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MemeAPI } from '@widgets/Dex/entities/meme/api';
import { SwapAPI } from '@widgets/Dex/entities/swap/api';
import { FormBuySell } from '@widgets/FormBuySell';
import { Coin, FormBuySellType, FormBuySellValues } from '@widgets/FormBuySell/type';

import { Info } from './ui/Info';
import { Heading } from './ui';

const RATE = 10_000;

export const SwapGamePoints: FC = () => {
  const queryClient = useQueryClient();
  const balance = useQuery(MemeAPI.getEthBalanceQueryOptions());

  const mutationBuy = useMutation({
    mutationFn: SwapAPI.swapCrystalToEth,
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: MemeAPI.getEthBalanceQueryOptions().queryKey,
      });

      const previousData = queryClient.getQueryData(
        MemeAPI.getEthBalanceQueryOptions().queryKey,
      );

      return { previousData };
    },
    onSettled: () => {
      for (const key of [
        MemeAPI.getEthBalanceQueryOptions().queryKey,
        MeAPI.getMeQueryOptions().queryKey,
      ]) {
        queryClient.invalidateQueries({
          queryKey: key,
        });
      }
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(
        MemeAPI.getEthBalanceQueryOptions().queryKey,
        context.previousData,
      );

      console.error('Error creating swap:', error);
    },
  });

  const mutationSell = useMutation({
    mutationFn: SwapAPI.swapEthToCrystal,
    onMutate: async () => {
      const previousData = queryClient.getQueryData(
        MemeAPI.getEthBalanceQueryOptions().queryKey,
      );

      return { previousData };
    },
    onSettled: () => {
      for (const key of [
        MemeAPI.getEthBalanceQueryOptions().queryKey,
        MeAPI.getMeQueryOptions().queryKey,
      ]) {
        queryClient.invalidateQueries({
          queryKey: key,
        });
      }
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(
        MemeAPI.getEthBalanceQueryOptions().queryKey,
        context.previousData,
      );
      console.error('Error creating swap:', error);
    },
  });

  const coins: [Coin, Coin] = [
    {
      title: 'Crystals',
      icon: <Gem size={22} />,
      balance: String(balance.data?.cristalBalance?.value),
    },
    {
      title: 'Dollar*',
      icon: <DollarIcon width={24} height={24} />,
      balance: weiToEther(balance.data?.weiBalance?.value),
    },
  ];

  const onSubmit = async (values: FormBuySellValues, type: FormBuySellType) => {
    const value = values[0];
    let promise;

    if (!value) {
      return;
    }

    if (type === 'buy') {
      promise = mutationBuy.mutateAsync(Number(value));
    } else if (type === 'sell') {
      promise = mutationSell.mutateAsync(etherToWei(value));
    }

    await toast.promise(promise, {
      loading: 'Awaiting blockchain...',
      success: 'Successfully!',
      error: 'Error during transaction',
    });
  };

  return (
    <div className="pb-4">
      <Heading />
      <Info />
      <Rate />
      <FormBuySell
        mode="modal"
        inputs={coins}
        initTab="buy"
        price={RATE}
        onSubmit={onSubmit}
        disabled={mutationBuy.isPending}
      />
    </div>
  );
};
