import { FC } from 'react';

interface Properties {
  width?: number;
  height?: number;
}

const DEFAULT_SIZE = 15;

export const DollarWhiteIcon: FC<Properties> = ({ width = DEFAULT_SIZE, height }) => {
  return (
    <svg
      width={width}
      height={height || width + 5}
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.09035 16.3587C5.31157 16.3587 4.5918 16.2643 3.93102 16.0755C3.27024 15.8867 2.68026 15.6271 2.16108 15.2967C1.65369 14.9663 1.22891 14.5828 0.886719 14.1462L2.23187 12.5533C2.49147 12.9545 2.82775 13.3026 3.24074 13.5976C3.65373 13.8807 4.11981 14.1049 4.63899 14.2701C5.15818 14.4235 5.68916 14.5002 6.23194 14.5002C6.95172 14.5002 7.5181 14.3822 7.93108 14.1462C8.34407 13.9102 8.55056 13.5622 8.55056 13.102C8.55056 12.807 8.43257 12.5533 8.19658 12.3409C7.97238 12.1285 7.5771 11.9574 7.01072 11.8276L4.5328 11.2612C3.87202 11.1078 3.30564 10.8954 2.83365 10.6241C2.36167 10.3527 2.00178 10.0223 1.75399 9.63289C1.518 9.2317 1.4 8.77152 1.4 8.25233C1.4 7.56796 1.5829 6.96028 1.94868 6.42929C2.32627 5.89831 2.85725 5.47353 3.54163 5.15494C4.23781 4.83635 5.07558 4.67705 6.05495 4.67705C7.08151 4.67705 7.98418 4.85405 8.76296 5.20803C9.54173 5.56202 10.2025 6.08711 10.7453 6.78328L9.47093 8.25233C8.99895 7.65055 8.47387 7.21397 7.89569 6.94258C7.3293 6.65939 6.66263 6.51779 5.89565 6.51779C5.37647 6.51779 4.95168 6.58269 4.62129 6.71249C4.29091 6.83048 4.04311 6.99568 3.87792 7.20807C3.72452 7.42046 3.64783 7.66235 3.64783 7.93374C3.64783 8.14614 3.69503 8.34083 3.78942 8.51783C3.88382 8.68302 4.06081 8.83051 4.3204 8.96031C4.5918 9.09011 4.97528 9.214 5.47087 9.332L7.5948 9.80988C8.70396 10.0577 9.50043 10.4235 9.98422 10.9072C10.4798 11.3792 10.7276 12.0105 10.7276 12.8011C10.7276 13.5563 10.5329 14.1993 10.1435 14.7303C9.75412 15.2613 9.20544 15.6684 8.49747 15.9516C7.80129 16.223 6.99892 16.3587 6.09035 16.3587ZM5.25847 18.0047V3.06641H6.90452V18.0047H5.25847Z"
        fill="white"
      />
      <path
        d="M12.7074 5.27686L13.3684 5.91404L13.9897 6.75034L13.3445 7.22026L12.7392 6.36803L12.341 5.54767L12.5242 5.41226L12.7074 5.27686ZM12.341 5.27686L12.5242 5.41226L12.7074 5.54767L12.3091 6.36803L11.7038 7.22026L11.0587 6.75034L11.6879 5.91404L12.341 5.27686ZM10.7958 4.4326L11.7914 4.76712L12.5959 5.18925L12.5242 5.41226L12.4525 5.63528L11.5525 5.49988L10.5569 5.18925L10.7958 4.4326ZM12.9224 3.46887L12.9065 4.51225L12.7552 5.41226H12.5242H12.2932L12.1419 4.51225L12.118 3.46887H12.9224ZM14.2525 4.4326L14.4915 5.18925L13.4959 5.49988L12.5959 5.63528L12.5242 5.41226L12.4525 5.18925L13.2569 4.76712L14.2525 4.4326Z"
        fill="white"
      />
    </svg>
  );
};
