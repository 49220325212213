import { FC, useEffect, useState } from 'react';
import { Button } from '@components/index';
import { MeAPI } from '@entities/me/api';
import { Friend, InviteButtons } from '@shared/ui';
import BalanceDisplay from '@shared/ui/BalanceDisplay';
import { Gem } from '@shared/ui/Gem';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { FriendsAPI } from '../entities/friends/api';

export const AllFrens: FC = () => {
  const [claim, setClaim] = useState<number>(0);
  const qc = useQueryClient();

  const { data: profile } = useQuery(MeAPI.getMeQueryOptions());
  const { data: frensPages, fetchNextPage: loadMoreFrens } = useInfiniteQuery(
    FriendsAPI.getFriendsInfinityQueryOptions(),
  );

  useEffect(() => {
    setClaim(profile.amountFriends);
  }, [profile]);

  const frens = frensPages?.pages.flatMap((frensPage) => frensPage.users) ?? [];
  const total = frensPages?.pages[0].totalCount ?? 0;

  const claimMutatin = useMutation({
    mutationKey: ['frens_claim'],
    mutationFn: () => FriendsAPI.claimFriens(),
    onSettled() {
      qc.invalidateQueries({
        queryKey: MeAPI.getMeQueryOptions().queryKey,
      });
    },
  });

  return (
    <div>
      <p className="text-1-5xl text-white font-semibold text-center mt-4">
        My Frens: {total}
      </p>

      {claim ? (
        <div className="h-[44px] px-4 relative">
          {claim ? (
            <Button onClick={() => claimMutatin.mutate()}>
              Claim <Gem></Gem>
              <BalanceDisplay balance={claim} big />
              <div className="absolute top-[11px] right-[32%] w-[6px] h-[6px] rounded-full bg-gradient-to-r from-pink to-red"></div>
            </Button>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}

      <div className="max-w-container m-auto mt-4">
        {frens.map((fren) => (
          <Friend
            key={fren.id}
            telegramID={fren.telegramId}
            name={fren.name}
            lvl={fren.level.currentUserLevelIndex + 1}
            friends={fren.inviteCount}
            balance={fren.balance.toString()}
          />
        ))}
        {frens.length < total ? (
          <div className="flex justify-center mt-4">
            <button
              className="bg-transparent text-blue text-sm font-semibold"
              onClick={() => loadMoreFrens()}
            >
              Load more &rsaquo;
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="w-full px-4 mt-4 fixed bottom-24">
        <InviteButtons />
      </div>
    </div>
  );
};
