import { FC } from 'react';

export const PaperPlaneGradient: FC = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2949 0.141253C18.2949 0.141253 20.1451 -0.551318 19.9909 1.13064C19.9395 1.82322 19.477 4.24722 19.1172 6.86912L17.8838 14.6358C17.8838 14.6358 17.781 15.7736 16.8559 15.9715C15.9308 16.1694 14.5432 15.2789 14.2862 15.081C14.0807 14.9327 10.4318 12.7065 9.14696 11.6182C8.7872 11.3214 8.37605 10.7277 9.19834 10.0351L14.5946 5.08823C15.2113 4.49458 15.8281 3.10944 13.2584 4.7914L6.06339 9.49101C6.06339 9.49101 5.2411 9.98569 3.69934 9.54047L0.358772 8.55108C0.358772 8.55108 -0.874662 7.80905 1.23245 7.06697C6.37177 4.74189 12.6931 2.36736 18.2949 0.141253Z"
        fill="url(#paint0_linear_11084_16718)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11084_16718"
          x1="4.84615"
          y1="2.43477e-06"
          x2="20.8888"
          y2="10.9569"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#587BFF" />
          <stop offset="1" stopColor="#B518FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
