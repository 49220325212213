import { FC } from 'react';

interface Properties {
  width?: number;
  height?: number;
}

const DEFAULT_SIZE = 50;

export const Sol: FC<Properties> = ({ width = DEFAULT_SIZE, height = DEFAULT_SIZE }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
      fill="#0F0338"
    />
    <path
      d="M40.6927 12.7344H14.8303C14.7759 12.7344 14.7261 12.757 14.6899 12.7955L9.15977 18.7127C9.04432 18.835 9.1326 19.0364 9.30011 19.0364H35.1648C35.2192 19.0364 35.269 19.0138 35.3052 18.9753L40.8353 13.0581C40.9508 12.9358 40.8625 12.7344 40.695 12.7344H40.6927Z"
      fill="url(#paint0_linear_8035_17246)"
    />
    <path
      d="M40.9469 30.9785H15.0822C15.0279 30.9785 14.9781 31.0011 14.9419 31.0396L9.41172 36.9569C9.29627 37.0791 9.38456 37.2806 9.55207 37.2806H35.4168C35.4711 37.2806 35.5209 37.2579 35.5571 37.2195L41.0873 31.3022C41.2027 31.18 41.1145 30.9785 40.9469 30.9785Z"
      fill="url(#paint1_linear_8035_17246)"
    />
    <path
      d="M40.6927 28.1595H14.8303C14.7759 28.1595 14.7261 28.1368 14.6899 28.0984L9.15977 22.1811C9.04432 22.0589 9.1326 21.8574 9.30011 21.8574H35.1648C35.2192 21.8574 35.269 21.8801 35.3052 21.9185L40.8353 27.8358C40.9508 27.958 40.8625 28.1595 40.695 28.1595H40.6927Z"
      fill="url(#paint2_linear_8035_17246)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8035_17246"
        x1="31.2374"
        y1="6.24442"
        x2="10.647"
        y2="38.0558"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#50EEA9" />
        <stop offset="0.1" stopColor="#54E6AC" />
        <stop offset="0.26" stopColor="#61D2B5" />
        <stop offset="0.45" stopColor="#77B0C4" />
        <stop offset="0.67" stopColor="#9481D9" />
        <stop offset="0.9" stopColor="#BA46F3" />
        <stop offset="1" stopColor="#CB2CFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8035_17246"
        x1="39.7404"
        y1="11.7418"
        x2="19.15"
        y2="43.5532"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#50EEA9" />
        <stop offset="0.1" stopColor="#54E6AC" />
        <stop offset="0.26" stopColor="#61D2B5" />
        <stop offset="0.45" stopColor="#77B0C4" />
        <stop offset="0.67" stopColor="#9481D9" />
        <stop offset="0.9" stopColor="#BA46F3" />
        <stop offset="1" stopColor="#CB2CFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_8035_17246"
        x1="35.398"
        y1="8.93638"
        x2="14.8099"
        y2="40.7478"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#50EEA9" />
        <stop offset="0.1" stopColor="#54E6AC" />
        <stop offset="0.26" stopColor="#61D2B5" />
        <stop offset="0.45" stopColor="#77B0C4" />
        <stop offset="0.67" stopColor="#9481D9" />
        <stop offset="0.9" stopColor="#BA46F3" />
        <stop offset="1" stopColor="#CB2CFF" />
      </linearGradient>
    </defs>
  </svg>
);
