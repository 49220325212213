import { clientAPI } from '@shared/api/client.ts';
import { queryOptions } from '@tanstack/react-query';

import { Mining } from '../type';

export const MiningAPI = {
  getMiningAPI: () => clientAPI.get('game/mining').json<Mining>(),

  getMiningQueryOptions: () =>
    queryOptions({
      queryKey: ['mining'],
      queryFn: () => MiningAPI.getMiningAPI(),
    }),

  makeClaim: () => clientAPI.get('game/mining/claim'),
  startMining: () => clientAPI.get('game/mining/start'),
};
